




































import { Vue, Component } from 'vue-property-decorator';

import requestStoreModule from '@/modules/requests/store';

import theme from '@/services/theme';

@Component
export default class Widgets extends Vue {
    private windowClickListener: undefined | ((this: Window, ev: MouseEvent) => void);
    public path = '';
    public currentTime = new Date();

    public srcInfo = '/img/light-theme/main/infopanel_off.png';
    public srcDiagram = '/img/light-theme/main/diagram_off.png';
    public srcMail = '/img/light-theme/main/mail_off.png';
    public srcVideo = '/img/light-theme/main/video_off.png';
    public srcMap = '/img/light-theme/main/map_off.png';
    public srcNewsMonitor = '/img/light-theme/main/diagram_off.png';
    // public srcBudget = '/img/light-theme/main/budget_off.png';

    public srcSocEco = '/img/main/soc_eco_on.svg';
    public srcProgDev = '/img/main/prog_razv_on.svg';
    public srcBudget = '/img/main/budget_on.svg';
    public srcMedia = '/img/main/media_on.svg';
    public srcOrders = '/img/main/poruchenia_on.svg';
    public srcUseful = '/img/main/poleznoу_on.svg';

    public created() {
        this.windowClickListener = () => {
            this.path = '';
        };
        window.addEventListener('click', this.windowClickListener);

        /** this.$watch('themeDark', () => {
            if (theme.dark) {
                this.srcSocEco = '/img/main/soc_eco_on.svg';
                this.srcProgDev = '/img/main/prog_razv_on.svg';
                this.srcBudget = '/img/main/budget_on.svg';
                this.srcMedia = '/img/main/media_on.svg';
                this.srcOrders = '/img/main/poruchenia_on.svg';
                this.srcUseful = '/img/main/poleznoу_on.svg';
            } else {
                this.srcSocEco = '/img/main/soc_eco_off.svg';
                this.srcProgDev = '/img/main/prog_razv_off.svg';
                this.srcBudget = '/img/main/budget_off.svg';
                this.srcMedia = '/img/main/media_off.svg';
                this.srcOrders = '/img/main/poruchenia_off.svg';
                this.srcUseful = '/img/main/poleznoу_off.svg';
            }
        }); */

        /** this.$watch('path', () => {
            switch (this.path) {
                case '/plashki':
                    this.srcInfo = (this.themeDark ? '/img/dark-theme/main/infopanel_off.png' : '/img/light-theme/main/infopanel_off_light.png');
                    break;
                case '/datas':
                    this.srcDiagram = (this.themeDark ? '/img/dark-theme/main/diagram_on.png' : '/img/light-theme/main/diagram_on_light.png');
                    break;
                case '/requests':
                    this.srcMail = (this.themeDark ? '/img/dark-theme/main/mail_on.png' : '/img/light-theme/main/message_on_light.png');
                    break;
                case '/media':
                    this.srcVideo = (this.themeDark ? '/img/dark-theme/main/video_on.png' : '/img/light-theme/main/video_on_light.png');
                    break;
                case '/astanamap':
                    this.srcMap = (this.themeDark ? '/img/dark-theme/main/map_on.png' : '/img/light-theme/main/map_on_light.png');
                    break;
                case '/news-monitor':
                    this.srcNewsMonitor = (this.themeDark ? '/img/dark-theme/main/diagram_on.png' : '/img/light-theme/main/diagram_on_light.png');
                    break;
                case '/budget':
                    this.srcBudget = (this.themeDark ? '/img/dark-theme/main/budget_off.png' : '/img/light-theme/main/budget_off.png');
                    break;
                default:
                    if (this.themeDark) {
                        this.srcInfo = '/img/dark-theme/main/infopanel_off.png';
                        this.srcDiagram = '/img/dark-theme/main/diagram_off.png';
                        this.srcMail = '/img/dark-theme/main/mail_off.png';
                        this.srcVideo = '/img/dark-theme/main/video_off.png';
                        this.srcMap = '/img/dark-theme/main/map_off.png';
                        this.srcNewsMonitor = '/img/dark-theme/main/diagram_off.png';
                        this.srcBudget = '/img/dark-theme/main/budget_off.png';
                    } else {
                        this.srcInfo = '/img/light-theme/main/infopanel_off.png';
                        this.srcDiagram = '/img/light-theme/main/diagram_off.png';
                        this.srcMail = '/img/light-theme/main/message_off.png';
                        this.srcVideo = '/img/light-theme/main/video_off.png';
                        this.srcMap = '/img/light-theme/main/map_off.png';
                        this.srcNewsMonitor = '/img/light-theme/main/diagram_off.png';
                        this.srcBudget = '/img/light-theme/main/budget_off.png';
                    }
            }
        });  */
    }

    public mounted() {
        const currentTimeUpdate = () => {
            this.currentTime = new Date();
        };

        setInterval(currentTimeUpdate, 1000);
    }

    public destroyed() {
        if (this.windowClickListener !== undefined) {
            window.removeEventListener('click', this.windowClickListener);
        }
    }

    // время
    public get dateTimeFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            weekday: 'long',
            hour: 'numeric',
            minute: 'numeric'
        });
    }

    // Обращения
    public get wipRequestCount(): number {
        return requestStoreModule.wipCount;
    }

    // переключение темы
    private get themeDark(): boolean {
        return theme.dark;
    }
}
