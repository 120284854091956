

















import Class from './c-actual-tab';
export default Class;
