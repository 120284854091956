<template>
    <b-container fluid="true">
        <div class="section-title budget">Отчеты о кассовом исполнении</div>
        <b-card class="ml-3 mr-3">
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="7"
                track-by="name"
                label="АБП"
            >
                <b-input-group>
                    <b-input-group-addon>
                        <b-form-input v-model="curAbp"></b-form-input>
                    </b-input-group-addon>
                    <b-form-select :options="abpBase" v-model="curAbp">
                    </b-form-select>
                </b-input-group>
            </b-form-group>
        </b-card>
        <b-tabs nav-class="ml-3 mr-3">
            <b-tab title="Форма 5-42">
                <budget542></budget542>
            </b-tab>
            <b-tab title="Форма 4-09">
                <budget409></budget409>
            </b-tab>
            <b-tab title="Форма 4-20">
                <budget420></budget420>
            </b-tab>
        </b-tabs>
    </b-container>
</template>

<script>
import Budget542 from './Budget_5_42'
import Budget409 from './Budget_4_09'
import Budget420 from './Budget_4_20'

export default {
    name: 'BudgetForms',
    components: {
        Budget542, Budget409, Budget420
    },
    data () {
        return {
            curAbp: 271,
            abpBase: [
                {
                    value: 271,
                    text: 'УС'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>