import { render, staticRenderFns } from "./BipRoads.vue?vue&type=template&id=65a1d269&scoped=true&"
import script from "./BipRoads.vue?vue&type=script&lang=js&"
export * from "./BipRoads.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65a1d269",
  null
  
)

export default component.exports