










































































































import Class from './soc-mod';
export default Class;
