<template>
    <b-container fluid="true">
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Целевой индикатор"
                        label="Целевой индикатор"
                    >
                        <b-form-select v-model="selected1" :options="options1"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontalq"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Город/район"
                        label="Город/район"
                    >
                        <b-form-select v-model="selected2" :options="options2"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="text-right">
<!--                <b-button variant="primary" href="#/demo-budget-execution/schools/schools-map/">Открыть на карте</b-button>-->
            </div>
        </b-card>
        <b-table :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" :items="tableData" :filter="selected2">
            <template #cell(f1)="data">
                <div class="text-center">
                    {{ data.item.f1 }}
                </div>
            </template>
            <template #cell(f4)="data">
                <div class="text-right">
                    {{ data.item.f4 }}
                </div>
            </template>
            <template #cell(f5)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f6)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f7)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f8)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
        </b-table>
    </b-container>
</template>

<script>
export default {
    name: 'Roads',
    data() {
        return {
            options1: [
                {value: null, text: 'Все'},
                {value: 'УС', text: 'УС'},
                {value: 'УПТиАД', text: 'УПТиАД'},
            ],
            selected1: 0,
            options2: [
                {value: null, text: 'Все'},
                {value: 'Актогайский р-н', text: 'Актогайский р-н'},
                {value: 'Баянаульский р-н', text: 'Баянаульский р-н'},
                {value: 'Железинский р-н', text: 'Железинский р-н'},
                {value: 'Иртышский р-н', text: 'Иртышский р-н'},
                {value: 'Теренкольский р-н', text: 'Теренкольский р-н'},
                {value: 'Аккулинский р-н', text: 'Аккулинский р-н'},
                {value: 'Майский р-н', text: 'Майский р-н'},
                {value: 'Павлодарский р-н', text: 'Павлодарский р-н'},
                {value: 'Успенский р-н', text: 'Успенский р-н'},
                {value: 'Щербактинский р-н', text: 'Щербактинский р-н'},
                {value: 'г. Аксу', text: 'г. Аксу'},
                {value: 'г. Павлодар', text: 'г. Павлодар'},
                {value: 'г. Экибастуз', text: 'г. Экибастуз'},
            ],
            selected2: 0,
            tableFields: [
                {
                    key: 'f1',
                    label: '№'
                },
                {
                    key: 'f2',
                    label: 'Проекты'
                },
                {
                    key: 'f3',
                    label: 'АБП'
                },
                {
                    key: 'f4',
                    label: 'Нас. пункт'
                },
                {
                    key: 'f5',
                    label: 'Статус'
                },
                {
                    key: 'f6',
                    label: 'Срок действия госэкспертизы'
                },
                {
                    key: 'f7',
                    label: 'Стоимость, млн тенге'
                },
                {
                    key: 'f8',
                    label: 'Срок завершения'
                }
            ],
            tableData: [
                {
                    "f1": 1,
                    "f2": "Строительство школы на 650 мест в микрорайоне \"Сарыарка\" г. Павлодар",
                    "f3": "УС",
                    "f4": "г. Павлодар",
                    "f5": "Реализуется",
                    "f6": "Реализуется",
                    "f7": "1 688",
                    "f8": 2021
                },
                {
                    "f1": 2,
                    "f2": "Проектирование жилого микрорайона \"Достык\" в г. Павлодар. Строительство СОШ на 1200 мест",
                    "f3": "УС",
                    "f4": "г. Павлодар",
                    "f5": "Реализуется",
                    "f6": "Реализуется",
                    "f7": "3 227",
                    "f8": 2022
                },
                {
                    "f1": 3,
                    "f2": "«Строительство малокомплектной общеобразовательной школы в пос. Железнодорожников с.з. г. Павлодар»\"",
                    "f3": "УС",
                    "f4": "г. Павлодар",
                    "f5": "Реализуется",
                    "f6": "Реализуется",
                    "f7": "918",
                    "f8": 2021
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>