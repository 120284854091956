import { Vue, Component, Prop } from 'vue-property-decorator';

import { TWidget } from '@/modules/widget-yard/store/types';
import CWidget from '@/modules/widget-yard/c-widget';

export interface IPlashkaData {
    checked: boolean | null; // выбрана ли плашка для добавления
    widget: TWidget;
}

@Component({
    components: {
        'c-widget': CWidget
    }
})
export default class CPlashka extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private data!: IPlashkaData[];

    @Prop({
        required: false,
        default: false
    })
    private flEditTab!: boolean;

    @Prop({
        required: true,
        default: 3
    })
    private colSum!: number;

    @Prop({
        required: true,
        default: false
    })
    private isTablet!: boolean;

    private get plashkaData(): IPlashkaData[] {
        return this.data.slice();
    }

    private set plashkaData(val: IPlashkaData[]) {
        this.$emit('plashUpdate', val);
    }
}