<template>
    <b-container>
        <b-table :fields="tableFields" :items="tableData" bordered no-border-collapse striped thead-class="bold_header" caption-top>
            <template #table-caption ><div class="text-right">млн. тенге</div></template>
            <template #cell(f6)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f7)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f8)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
        </b-table>
        <div class="text-right mt-3">
            <b-button class="m-1">Скачать в xls</b-button>
        </div>
    </b-container>
</template>

<script>
export default {
    name: 'Budget_5_42',
    data() {
        return {
            options: [
                2022, 2023, 2024
            ],
            select: 2022,
            options1: [
                {
                    value: 'обязательства',
                    text: 'Обязательства'
                },
                {
                    value: 'платежи',
                    text: 'Платежи'
                }
            ],
            select1: 'обязательства',
            tableFields: [
                {
                    key: 'f1',
                    label: 'АБП'
                },
                {
                    key: 'f2',
                    label: 'БП'
                },
                {
                    key: 'f3',
                    label: 'ПП'
                },
                {
                    key: 'f4',
                    label: 'Специфика'
                },
                {
                    key: 'f5',
                    label: 'Наименование'
                },
                {
                    key: 'f6',
                    label: 'Сумма обязательств'
                },
                {
                    key: 'f7',
                    label: 'Оплата по выполненным обязательствам'
                },
                {
                    key: 'f8',
                    label: 'Остаток обязательства'
                },
            ],
            tableData: [
                {
                    'f1': 271,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Управление строительства области',
                    'f6': 14207,
                    'f7': 2291,
                    'f8': 11916
                },
                {
                    'f1': null,
                    'f2': 86,
                    'f3': null,
                    'f4': null,
                    'f5': 'Строительство и реконструкция объектов начального, основного среднего и общего среднего образования',
                    'f6': 2502,
                    'f7': 300,
                    'f8': 2202
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': 15,
                    'f4': null,
                    'f5': 'За счет средств местного бюджета',
                    'f6': 1942,
                    'f7': 300,
                    'f8': 1642
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': 431,
                    'f5': 'Строительство новых объектов и реконструкция имеющихся объектов',
                    'f6': 1942,
                    'f7': 300,
                    'f8': 1642
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Проектирование жилого микрорайона "Достык" в г. Павлодар. Строительство СОШ на 1200 мест',
                    'f6': 1120,
                    'f7': 164,
                    'f8': 956
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар',
                    'f6': 618,
                    'f7': 102,
                    'f8': 516
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Строительство пристройки на 348 мест в СОШГ №9 г. Павлодар',
                    'f6': 203,
                    'f7': 34,
                    'f8': 169
                },
            ]
        };
    }
};
</script>

<style scoped>

</style>