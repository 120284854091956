<template>
<b-container>
    <div class="section-title budget">Бюджетные инвестиционные проекты</div>
    <b-tabs>
        <b-tab title="Реализуемые">
            <bip-schools></bip-schools>
        </b-tab>
        <b-tab title="На рассмотрении">
            <bip-schools1></bip-schools1>
        </b-tab>
        <b-tab title="Не поддержанные">
            <bip-schools2></bip-schools2>
        </b-tab>
    </b-tabs>
</b-container>
</template>

<script>

import BipSchools from './BipSchools'
import BipSchools1 from './BipSchools1'
import BipSchools2 from './BipSchools2'

export default {
    name: 'Bip',
    components: {
        BipSchools, BipSchools1,  BipSchools2
    }
}
</script>

<style scoped>

</style>