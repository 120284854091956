<template>
<div class="smi--column">
  <b-col other style="overflow-y: scroll;">
    <!-- список тем вне разделов // !tsList.has(tema.id)-->
    <b-list-group scrollbar class="out-list">
      <template v-for="tema in temaList">
        <b-list-group-item
          v-if="!tsList.has(tema.id) && ((tema.access.indexOf(curUser.id) > -1) || (curUser.roles && curUser.roles.has(1)))"
          :key="tema.id"
          squared
          @click="selectTema(null, tema)"
          @dblclick="editTema(tema)"
          :variant="(tema.name === curTema.name)?'success':''"
        >
          {{tema.name}}
          <div class="tochki" :class="{'clicked': tema.id === clTema}" @click="clTema = tema.id"></div>
          <b-button-group v-if="tema.id === clTema"
            :class="{'pos-abs': tema.id === clTema}">
            <b-button
              v-b-modal.modal-edit-tema
              class="edit-section"
            ></b-button>
            <b-button
              @click="confirmDeleteTema(tema)"
              class="del-section"></b-button>
          </b-button-group>
        </b-list-group-item>
      </template>
    </b-list-group>
    <!-- список разделов -->
    <template v-for="section in sectionList">
      <b-card no-body :key="'section_'+section.id"
        draggable="true"
        @dragover="dragover(section)"
        @dragend="dragend(section)"
        @dragstart="move = section.id"
        v-if="(section.access.indexOf(curUser.id) > -1) || (curUser.roles && curUser.roles.has(1))"
      >
        <b-card-header header-tag="header" @click="selectSection(section)">
          <b-button block v-b-toggle="'accordion-'+section.id">{{ section.name }}</b-button>
          <div class="move" v-if="section.id === move"></div>
          <div class="tochki" :class="{'clicked': section.id === clSection}" @click="clSection = section.id"></div>
          <b-button-group v-if="section.id === clSection"
            :class="{'pos-abs': section.id === clSection}"
            v-b-toggle="'accordion-'+section.id">
            <b-button v-b-tooltip.hover title="Новая тема"
              @click="createTema()"
              class="add-section"></b-button>
            <b-button v-b-tooltip.hover title="Редактировать раздел"
              v-b-modal.modal-edit-section
              @click="editSection(section)"
              class="edit-section"></b-button>
            <b-button v-b-tooltip.hover title="Удалить раздел"
              @click="confirmDeleteSection(section)"
              class="del-section"></b-button>
          </b-button-group>
        </b-card-header>
        <b-collapse :id="'accordion-'+section.id" accordion="my-accordion" role="tabpanel"> <!--  @click="selectSection(section)" -->
        <!-- список тем данного раздела -->
          <b-list-group>
              <template v-for="(t, ind) in section.topics">
                <template v-for="(item) in temaList">
                <b-list-group-item
                  v-if="(item.id === t) && (ind < more*3)"
                  :key="ind + '=' + section.id + '_' + item.id"
                  @click="selectTema(section, item)"
                  squared
                  :variant="(item.name === curTema.name) ? 'success' : ''"
                >
                  {{item.name}}
                  <div class="tochki" :class="{'clicked': item.id === clTema}" @click="clTema = item.id"></div>
                  <b-button-group v-if="item.id === clTema"
                    :class="{'pos-abs': section.id === clSection}">
                    <b-button
                      v-b-modal.modal-edit-tema
                      class="edit-section"
                    ></b-button>
                    <b-button
                      @click="confirmDeleteTema(item)"
                      class="del-section"></b-button>
                  </b-button-group>
                </b-list-group-item>
              </template>
            </template>
          </b-list-group>
          <div class="show-more" v-if="(Object.keys(curSection).length>0) && (curSection.topics.length>more*3)">
            <a href="#" @click="showMore()">показать еще</a>
          </div>
        </b-collapse>
      </b-card>
    </template>
    <div class="smi--column-first">
      <a href="#" @click="createSection()">добавить новый раздел</a>
    </div>
  </b-col>

  <!-- Modals for work with Sections -->
  <template>
    <!-- modal for add new section -->
    <b-modal ref="modalNewSection" id="modal-new-section" title="Создать новый раздел" hide-footer>
      <b-form @reset="onResetSection" class="w-100">
        <span class="label">Раздел</span>
        <input type="text" v-model="curSection.name" placeholder="название раздела">
        <span class="label">Доступ пользователям
          <b-form-checkbox switch v-model="sectionAccess"></b-form-checkbox>
        </span>
        <div class="access-form">
          <b-form-checkbox-group
            v-model="curSectionAccess"
            :options="users"
            value-field="id"
            text-field="username"
            stacked
          ></b-form-checkbox-group>
        </div>
        <b-button-group>
          <b-button @click="onSubmitSection" variant="primary">Создать раздел</b-button>
          <b-button type="reset" variant="danger">Отменить</b-button>
        </b-button-group>
      </b-form>
    </b-modal>
    <!-- modal for edit section -->
    <b-modal ref="modalEditSection" id="modal-edit-section" title="Редактировать раздел" hide-footer>
      <b-form @reset="onResetEditSection" class="w-100">
        <span class="label">Раздел</span>
        <input type="text" v-model="curSection.name" placeholder="название раздела">
        <span class="label">Доступ пользователям
          <b-form-checkbox switch v-model="sectionAccess"></b-form-checkbox>
        </span>
        <div class="access-form">
          <b-form-checkbox-group
            v-model="curSectionAccess"
            :options="users"
            value-field="id"
            text-field="username"
            stacked
          ></b-form-checkbox-group>
        </div>
        <b-button-group>
          <b-button @click="onSubmitEditSection" variant="primary">Сохранить</b-button>
          <b-button type="reset" variant="danger">Отменить</b-button>
        </b-button-group>
      </b-form>
    </b-modal>
    <!-- modal for add new tema -->
    <b-modal ref="modalNewTema" id="modal-new-tema" title="Создать новую тему" hide-footer>
      <b-form @reset="onResetTema" class="w-100">
        <b-form-select v-model="selSectionId">
          <b-form-select-option :value="0"></b-form-select-option>
          <b-form-select-option
            v-for="sect in sectionList"
            :value="sect.id" :key="sect.id">{{sect.name}}
          </b-form-select-option>
        </b-form-select>
        <span class="label">Наименование темы:</span>
        <input type="text" v-model="curTema.name" placeholder="название темы">
        <span class="label">Ключевые слова:</span>
        <textarea
          v-model="curTema.keywords"
          rows="4"
          placeholder="((э'ким мангистауской области'~8) + -'заместитель акима'))  | ('Трумов Серикбай'~8) | ('Трұмов Серікбай'~10) | ('Маңғыстау облысының әкімі'~8)"
          wrap="soft"
          style="width: 100%"
          v-b-tooltip.hover title="((э'ким мангистауской области'~8) + -'заместитель акима'))  | ('Трумов Серикбай'~8) | ('Трұмов Серікбай'~10) | ('Маңғыстау облысының әкімі'~8)"
        ></textarea>
        <span class="label">Поиск по атрибутам
          <b-form-checkbox switch v-model="curTema.attrSearch"></b-form-checkbox>
        </span>
        <span class="label">Доступ пользователям
          <b-form-checkbox switch v-model="temaAccess" :disabled="selSectionId>0"></b-form-checkbox>
        </span>
        <div class="access-form">
          <b-form-checkbox-group
            v-model="curTemaAccess"
            :options="users"
            value-field="id"
            text-field="username"
            stacked
            :disabled="selSectionId>0"
          ></b-form-checkbox-group>
        </div>
        <b-button-group>
          <b-button @click="onSubmitTema" variant="primary">Создать тему</b-button>
          <b-button type="reset" variant="danger">Отменить</b-button>
        </b-button-group>
      </b-form>
    </b-modal>
    <!-- modal for edit tema -->
    <b-modal ref="modalEditTema" id="modal-edit-tema" title="Редактировать тему" hide-footer>
      <b-form @reset="onResetEditTema" class="w-100">
        <span class="label">Раздел:</span>
        <b-form-select v-model="selSectionId">
          <b-form-select-option :value="0"></b-form-select-option>
          <b-form-select-option
            v-for="sect in sectionList"
            :value="sect.id" :key="sect.id">{{sect.name}}
          </b-form-select-option>
        </b-form-select>
        <span class="label">Наименование темы:</span>
        <input type="text" v-model="curTema.name" placeholder="название темы">
        <span class="label">Ключевые слова:</span>
        <textarea
          style="width: 100%"
          v-model="curTema.keywords"
          rows="4" wrap="soft"
          placeholder="((э'ким мангистауской области'~8) + -'заместитель акима'))  | ('Трумов Серикбай'~8) | ('Трұмов Серікбай'~10) | ('Маңғыстау облысының әкімі'~8)"
        ></textarea>
        <!--v-b-tooltip.hover title="((э'ким мангистауской области'~8) + -'заместитель акима'))  | ('Трумов Серикбай'~8) | ('Трұмов Серікбай'~10) | ('Маңғыстау облысының әкімі'~8)"-->
        <span class="label">Поиск по атрибутам
          <b-form-checkbox switch v-model="curTema.attrSearch"></b-form-checkbox>
        </span>
        <span class="label">Доступ пользователям
          <b-form-checkbox switch v-model="temaAccess" :disabled="selSectionId>0"></b-form-checkbox>
        </span>
        <div class="access-form">
          <b-form-checkbox-group
            v-model="curTemaAccess"
            :options="users"
            value-field="id"
            text-field="username"
            stacked
            :disabled="selSectionId>0"
          ></b-form-checkbox-group>
        </div>
        <b-button-group>
          <b-button @click="onSubmitEditTema" variant="primary">Сохранить</b-button>
          <b-button type="reset" variant="danger">Отменить</b-button>
        </b-button-group>
      </b-form>
    </b-modal>
  </template>
</div>
</template>

<script lang="js">
import { Vue, Component } from 'vue-property-decorator';
import axios from 'axios';
import bootstrap, {
    BIcon,
    BIconFilePlus,
    BIconPen,
    BIconTrash,
    BIconDownload,
    BIconThreeDots,
    BIconXCircle
} from 'bootstrap-vue';
import store from '@/services/store';

Vue.use(bootstrap);

@Component({
    components: {
        BIcon,
        BIconFilePlus,
        BIconPen,
        BIconTrash,
        BIconDownload,
        BIconThreeDots,
        BIconXCircle
    }
})
export default class CSection extends Vue {
    data() {
        return {
            currentTema: {},
            currentSection: {},
            hostname: window.location.hostname
        };
    }

    sectionList = [];
    curSection = {}; // currecnt section
    selSection = {};
    selSectionId = 0;

    temaList = [];
    curTema = {};

    tsList = new Set();

    moveDown = {};

    clSection = 0;
    move = 0;
    clTema = 0;
    more = 1;

    users = [];
    userRoles = [];
    curUser = {}; // current user

    sectionAccess = false; // доступ для всех пользователей для текущего раздела
    curSectionAccess = [];
    temaAccess = false; // доступ для всех пользователей для текущей темы
    curTemaAccess = [];

    created() {
        this.$watch('sectionAccess', (val) => {
            if (val) {
                for (const u of this.users) {
                    const ind = this.curSectionAccess.indexOf(u.id);
                    if (ind === -1) {
                        this.curSectionAccess.push(u.id);
                    }
                }
            } else {
                this.curSectionAccess = [this.curSection.autor.id];
            }
        });

        this.$watch('temaAccess', (val) => {
            if (val) {
                for (const u of this.users) {
                    const ind = this.curTemaAccess.indexOf(u.id);
                    if (ind === -1) {
                        this.curTemaAccess.push(u.id);
                    }
                }
            } else {
                this.curTemaAccess = [this.curTema.autor.id];
            }
        });

        this.$watch('clSection', (val) => {
            if (val > 0) {
                this.clTema = 0;
            }
        });

        this.$watch('clTema', (val) => {
            if (val > 0) {
                this.clSection = 0;
            }
        });

        this.$watch('curSection', (val) => {
            this.more = 1;
        });
    }

    mounted() {
        this.getDatas();
        // this.loadDatas()

        if (Object.keys(this.$route.query).length > 0) {
            this.curTema = this.$route.query.theme;
        }
    }

    getDatas() {
        const that = this;
        axios
            .get('api-py/mm-data/get-users')
            .then(res => {
                that.users = res.data.users;

                that.users.sort(that.sortByField('username'));
                const curUsr = store.state.user.preferred_username;
                for (const usr of that.users) {
                    if (usr.username.toLowerCase() === curUsr.toLowerCase()) {
                        usr.disabled = true;
                        that.curUser = usr;
                        that.curUser.roles = new Set();
                        break;
                    }
                }

                axios
                    .get('api-py/mm-data/get-users-roles')
                    .then(res => {
                        that.userRoles = res.data.user_roles;
                        for (const ur of that.userRoles) {
                            if (ur.user_id === that.curUser.id) {
                                that.curUser.roles.add(ur.role_id);
                            }
                        }

                        that.loadDatas();
                    })
                    .catch(error => {
                        // eslint-disable-next-line
              console.error(error);
                    });
            })
            .catch(error => {
                console.error(error);
            });
    }

    loadTemas() {
        const that = this;
        axios
            .get('api-py/mm-data/topics-list')
            .then(function (response) {
                that.temaList = response.data.topics_list;

                for (const s of that.sectionList) {
                    // console.log(s.name)
                    if (s.topics !== undefined) {
                        let ind = 0;
                        for (const t of s.topics) {
                            const tema = that.findById(that.temaList, t);
                            if (tema !== null) {
                                that.tsList.add(t);
                            } else {
                                s.topics.splice(ind, 1);
                                s.change = true;
                            }
                            ind++;
                        }
                    }
                    if (s.change) {
                        that.saveSection(s);
                    }
                }
            });
    }

    loadDatas() {
        const that = this;
        axios
            .get('api-py/mm-data/tsection-list')
            .then(function (response) {
                that.sectionList = response.data.tsection_list;
                // that.$root.$set(list, 'tsection_list', response.data.tsection_list)

                axios
                    .get('api-py/mm-data/topics-list')
                    .then(function (response) {
                        that.temaList = response.data.topics_list;

                        for (const s of that.sectionList) {
                            // console.log(s.name)
                            if (s.topics !== undefined) {
                                let ind = 0;
                                for (const t of s.topics) {
                                    const tema = that.findById(that.temaList, t);
                                    if (tema !== null) {
                                        that.tsList.add(t);
                                    } else {
                                        s.topics.splice(ind, 1);
                                        s.change = true;
                                    }
                                    ind++;
                                }
                            }
                            if (s.change) {
                                that.saveSection(s);
                            }
                        }
                    });
            });
    }

    // Methods for Sections
    createSection() {
        this.sectionAccess = false;
        const newItem = {
            'id': Math.round(new Date().getTime() / 1000),
            'name': '',
            'topics': [],
            'order': this.sectionList.length,
            'autor': this.curUser,
            'access': [this.curUser.id]
        };
        this.curSection = newItem;
        this.curSectionAccess = newItem.access;
        this.updateAccess(newItem.autor.id);
        this.$refs.modalNewSection.show();
    }

    onSubmitSection(evt) {
        let find = false;
        for (const s of this.sectionList) {
            if (s.name === this.curSection.name) {
                this.makeToast('error', 'Раздел с таким наименованием уже существует', 'Результат');
                find = true;
            }
        }
        if (!find) {
            evt.preventDefault();
            this.curSection.access = this.curSectionAccess;
            this.sectionList.push(this.curSection);
            this.saveSection(this.curSection);
            this.$refs.modalNewSection.hide();
        }
    }

    saveSection(item) {
        item.user = localStorage.getItem('username');
        axios
            .post('api-py/mm-post/save-tsection', { params: { 'tsection': item } })
            .then(response => (item.saved = true));
    }

    selectSection(item) {
        this.sectionAccess = false;
        this.curSection = item;
        this.curSectionAccess = item.access;

        this.updateAccess(item.autor.id);

        const temas = [];
        for (const tema of this.temaList) {
            for (const t of item.topics) {
                if (tema.id === t) {
                    temas.push(tema);
                }
            }
        }
        const objSection = { section: item, temas: temas };
        this.$emit('selectSection', {
            currentSection: objSection
        });
        if (item.id !== this.clSection) {
            this.clSection = 0;
        }
    }

    onResetSection(evt) {
    /* for (const t of this.temaList) {
      t.autor = {id: 3, username: 'marina'}
      this.saveTema(t)
    }
    for (const s of this.sectionList) {
      s.autor = {id: 3, username: 'marina'}
      this.saveSection(s)
    } */

        // evt.preventDefault()
        this.sectionAccess = false;
        this.$refs.modalNewSection.hide();
    }

    editSection(item) {
        this.selectSection(item);
    }

    onSubmitEditSection(evt) {
        let find = false;
        for (const s of this.sectionList) {
            if (s !== this.curSection) {
                if (s.name === this.curSection.name) {
                    this.makeToast('error', 'Раздел с таким наименованием уже существует', 'Результат');
                    find = true;
                }
            }
        }
        if (!find) {
            evt.preventDefault();
            this.curSection.access = this.curSectionAccess;
            this.saveSection(this.curSection);
            this.$refs.modalEditSection.hide();
        }
    }

    onResetEditSection(evt) {
    // evt.preventDefault()
        this.$refs.modalEditSection.hide();
    }

    confirmDeleteSection(section) {
        this.$bvModal.msgBoxConfirm('Хотите удалить раздел "' + section.name + '" ?')
            .then(value => {
                if (value) {
                    if (section.topics.length > 0) {
                        this.$bvModal.msgBoxConfirm('Данный раздел содержит список тем. Вы действительно хотите удалить "' + section.name + '" ?')
                            .then(value => {
                                if (value) { this.deleteSection(section); }
                            });
                    } else {
                        this.deleteSection(section);
                    }
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    deleteSection(section) {
    // section.changed = false
        axios
            .post('api-py/mm-post/delete-tsection', { params: { 'tsection': section } })
            .then(response => {
                if (response.data.status === 'success') {
                    for (const t of section.topics) {
                        this.tsList.delete(t);
                    }

                    const index = this.sectionList.indexOf(section);
                    if (index > -1) {
                        this.sectionList.splice(index, 1);
                    }
                }
            });
    }

    // Methods for Tema
    createTema() {
        this.temaAccess = false;
        const newItem = {
            'id': Math.round(new Date().getTime() / 1000),
            'name': '',
            'keywords': '',
            'attrSearch': false,
            'access': [this.curUser.id],
            'autor': this.curUser
        };

        if (this.curSection !== null) {
            this.selSectionId = this.curSection.id;
        }

        this.curTema = newItem;
        this.curTemaAccess = newItem.access;
        this.updateAccess(newItem.autor.id);
        this.$refs.modalNewTema.show();
    }

    onSubmitTema(evt) {
        let find = false;
        for (const s of this.temaList) {
            if (s.name === this.curTema.name) {
                this.makeToast('error', 'Тема с таким наименованием уже существует', 'Результат');
                find = true;
            }
        }
        if (!find) {
            evt.preventDefault();
            this.curTema.access = this.curTemaAccess;
            this.temaList.push(this.curTema);

            this.saveTema(this.curTema);

            if ((this.selSectionId > 0) && (this.curSection.topics.indexOf(this.curTema.id) === -1)) {
                this.curSection.topics.push(this.curTema.id);
                this.saveSection(this.curSection);
                this.tsList.add(this.curTema.id);
            }
            this.$refs.modalNewTema.hide();
        }
    }

    onResetTema(evt) {
        this.$refs.modalNewTema.hide();
    }

    saveTema(item) {
        item.user = localStorage.getItem('username');
        axios
            .post('api-py/mm-post/save-topic', { params: { 'topic': item } })
            .then(response => (item.saved = true));
    }

    selectTema(section, item) {
        this.temaAccess = false;
        this.curTema = item;
        this.curTemaAccess = item.access;

        this.updateAccess(item.autor.id);

        if (section !== null) {
            this.curSection = section;
            this.selSectionId = section.id;
            this.curTema.access = section.access;
            this.curTemaAccess = section.access;
        }

        this.$emit('selectTema', {
            currentTema: this.curTema
        });
        if (item.id !== this.clTema) {
            this.clSection = 0;
            this.clTema = 0;
        }
    }

    onSubmitEditTema(evt) {
        let find = false;
        for (const t of this.temaList) {
            if (t !== this.curTema) {
                if (t.name === this.curTema.name) {
                    this.makeToast('error', 'Тема с таким наименованием уже существует', 'Результат');
                    find = true;
                }
            }
        }

        if (!find) {
            evt.preventDefault();
            this.curTema.access = this.curTemaAccess;
            this.changeSectionOfTema(this.selSectionId);
            this.saveTema(this.curTema);
            this.$refs.modalEditTema.hide();
        }
    }

    onResetEditTema(evt) {
    // evt.preventDefault()
        this.$refs.modalEditTema.hide();
    }

    changeSectionOfTema(sectionID) {
        if (this.curSection.topics !== undefined) {
            const index = this.curSection.topics.indexOf(this.curTema.id);
            if (index > -1) {
                this.curSection.topics.splice(index, 1);
                this.saveSection(this.curSection);

                this.tsList.delete(this.curTema.id);
            }
        }

        if (sectionID > 0) {
            for (const s of this.sectionList) {
                if (s.id === sectionID) {
                    this.selSection = s;
                    break;
                }
            }
            this.selSection.topics.push(this.curTema.id);
            this.tsList.add(this.curTema.id);
            this.saveSection(this.selSection);
        }
    }

    editTema(item) {
        this.curTema = item;
        this.selSectionId = 0;
        this.curSection = {};
        this.$refs.modalEditTema.show();
    }

    confirmDeleteTema(tema) {
        this.$bvModal.msgBoxConfirm('Хотите удалить тему "' + tema.name + '" ?')
            .then(value => {
                if (value === true) {
                    this.deleteTema(tema);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    deleteTema(tema) {
    // tema.changed = false
        axios
            .post('api-py/mm-post/delete-topic', { params: { 'topic': tema } })
            .then(response => {
                if (response.data.status === 'success') {
                    const ind = this.temaList.indexOf(tema);
                    if (ind > -1) {
                        this.temaList.splice(ind, 1);
                    }

                    if ((this.curSection !== undefined) && (Object.keys(this.curSection).length !== 0)) {
                        const indCur = this.curSection.topics.indexOf(tema.id);
                        if (indCur > -1) {
                            this.curSection.topics.splice(indCur, 1);

                            this.tsList.delete(tema.id);
                        }
                        this.saveSection(this.curSection);

                        const index = this.temaList.indexOf(tema);
                        if (index > -1) {
                            this.temaList.splice(index, 1);
                        }
                    }
                }
            });
    }

    /** Сообщения */
    makeToast(variant, tostbody, title) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 2000,
            solid: true
        });
    }

    dragend(item) {
        const indItem = this.sectionList.indexOf(item);
        this.sectionList.splice(indItem, 1);
        const index = this.sectionList.indexOf(this.moveDown);
        this.sectionList.splice(index, 0, item);

        for (let i = 0; i < this.sectionList.length; i++) {
            this.sectionList[i].order = i;
            this.saveSection(this.sectionList[i]);
        }
        this.move = 0;
    }

    dragover(item) {
        this.moveDown = item;
    }

    /** Показать больше тем ... */
    showMore() {
        this.more = this.more * 100;
    }

    /** сортировка массива по заданному полю */
    sortByField(field) {
        return (a, b) => (a[field] > b[field] ? 1 : -1);
    }

    /** находит элемент в заданном массиве по заданному значению поля-id */
    findById(array, value) {
        for (const el of array) {
            if (el.id === value) {
                return el;
            }
        }
        return null;
    }

    /** обновить автора */
    updateAccess(autorId) {
        for (const u of this.users) {
            u.disabled = false;
            if (u.id === autorId) {
                u.disabled = true;
            }
        }
    }
}
</script>

