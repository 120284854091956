<template>
    <b-tabs>
        <b-tab title="Главная">
            <Main></Main>
        </b-tab>
        <b-tab title="Темы">
            <b-container fluid="True">
                <topic></topic>
            </b-container>
        </b-tab>
        <b-tab title="Соц Медиа">
            <b-container fluid="True">
                <social-media></social-media>
            </b-container>
        </b-tab>
    </b-tabs>
</template>

<script>
import Main from '@/components/Main'
import topic from '@/modules/topic/Index'
import SocialMedia from '@/modules/socialnet/Index'

export default {
    name: 'MmData',
    components: {'Main': Main, 'topic': topic, SocialMedia},
    data() {
        return {}
    }
}
</script>

<style scoped>

</style>