<template>
    <b-container>
        <b-table :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" :items="tableData" caption-top>
            <template #table-caption ><div class="text-right">млн. тенге</div></template>
            <template #thead-top="data">
                <b-tr class="text-center" >
                    <b-th colspan="4">Коды бюджетной классификации</b-th>
                    <b-th></b-th>
                    <b-th colspan="2">Скорректированный план</b-th>
                    <b-th></b-th>
                    <b-th colspan="2">Остаток</b-th>
                    <b-th colspan="2">% исполнения</b-th>
                </b-tr>
            </template>
            <template #cell(f6)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f7)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f8)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f9)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f10)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f11)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f12)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
        </b-table>
        <div class="text-right mt-3">
            <b-button class="m-1">Скачать в xls</b-button>
        </div>
    </b-container>
</template>

<script>
export default {
    name: 'Budget_5_42',
    data() {
        return {
            options: [
                2022, 2023, 2024
            ],
            select: 2022,
            options1: [
                {
                    value: 'обязательства',
                    text: 'Обязательства'
                },
                {
                    value: 'платежи',
                    text: 'Платежи'
                }
            ],
            select1: 'обязательства',
            tableFields: [
                {
                    key: 'f1',
                    label: 'АБП'
                },
                {
                    key: 'f2',
                    label: 'БП'
                },
                {
                    key: 'f3',
                    label: 'ПП'
                },
                {
                    key: 'f4',
                    label: 'Специфика'
                },
                {
                    key: 'f5',
                    label: 'Наименование'
                },
                {
                    key: 'f6',
                    label: 'На год'
                },
                {
                    key: 'f7',
                    label: 'На отчетный месяц'
                },
                {
                    key: 'f8',
                    label: 'Кассовое исполнение на отчетный месяц'
                },
                {
                    key: 'f9',
                    label: 'На год'
                },
                {
                    key: 'f10',
                    label: 'На текущий период'
                },
                {
                    key: 'f11',
                    label: 'На год'
                },
                {
                    key: 'f12',
                    label: 'На текущий период'
                }
            ],
            tableData: [
                {
                    'f1': 271,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Управление строительства области',
                    'f6': 27806,
                    'f7': 3370,
                    'f8': 2291,
                    'f9': 22800,
                    'f10': 1078,
                    'f11': 18,
                    'f12': 68
                },
                {
                    'f1': null,
                    'f2': 86,
                    'f3': null,
                    'f4': null,
                    'f5': 'Строительство и реконструкция объектов начального, основного среднего и общего среднего образования',
                    'f6': 4862,
                    'f7': 364,
                    'f8': 300,
                    'f9': 3668,
                    'f10': 64,
                    'f11': 24,
                    'f12': 82
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': 15,
                    'f4': null,
                    'f5': 'За счет средств местного бюджета',
                    'f6': 4302,
                    'f7': 322,
                    'f8': 300,
                    'f9': 3211,
                    'f10': 22,
                    'f11': 26,
                    'f12': 93
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': 431,
                    'f5': 'Строительство новых объектов и реконструкция имеющихся объектов',
                    'f6': 4302,
                    'f7': 322,
                    'f8': 300,
                    'f9': 3211,
                    'f10': 22,
                    'f11': 26,
                    'f12': 93
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Проектирование жилого микрорайона "Достык" в г. Павлодар. Строительство СОШ на 1200 мест',
                    'f6': 2479,
                    'f7': 186,
                    'f8': 164,
                    'f9': 1884,
                    'f10': 22,
                    'f11': 24,
                    'f12': 88
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар',
                    'f6': 1371,
                    'f7': 102,
                    'f8': 102,
                    'f9': 1020,
                    'f10': 0,
                    'f11': 25,
                    'f12': 100
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Строительство пристройки на 348 мест в СОШГ №9 г. Павлодар',
                    'f6': 451,
                    'f7': 34,
                    'f8': 34,
                    'f9': 335,
                    'f10': 0,
                    'f11': 25,
                    'f12': 100
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>