<template>
    <b-container fluid="true">
        <div class="section-title budget">Определение потребностей</div>
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Целевой индикатор"
                        label="Целевой индикатор"
                    >
                        <b-form-select v-model="selected1" :options="options1"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontalq"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Значение дорог"
                        label="Значение дорог"
                    >
                        <b-form-select v-model="selected2" :options="options2"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="text-right">
                <b-button variant="primary" href="#/demo-budget-execution/roads/roads-map/">Открыть на карте</b-button>
                <b-button variant="primary" class="ml-3" href="#/demo-budget-execution/roads/bip-roads/">БИП</b-button>
            </div>
        </b-card>
        <b-table :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" :items="tableData" :filter="selected2">
            <template #cell(f1)="data">
                <div class="text-center">
                    {{ data.item.f1 }}
                </div>
            </template>
            <template #cell(f4)="data">
                <div class="text-right">
                    {{ data.item.f4 }}
                </div>
            </template>
            <template #cell(f5)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f6)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
        </b-table>
    </b-container>
</template>

<script>
export default {
    name: 'Roads',
    data() {
        return {
            options1: [
                {value: 0, text: 'Все'},
                {
                    value: 1,
                    text: 'Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии, %'
                }
            ],
            selected1: 0,
            options2: [
                {value: null, text: 'Все'},
                {value: 'Областное', text: 'Областное'},
                {value: 'Районное', text: 'Районное'}
            ],
            selected2: null,
            filter: null,
            tableFields: [
                {
                    key: 'f1',
                    label: '№'
                },
                {
                    key: 'f2',
                    label: 'Наименование'
                },
                {
                    key: 'f3',
                    label: 'Значение дорог'
                },
                {
                    key: 'f4',
                    label: 'Протяженность, км'
                },
                {
                    key: 'f5',
                    label: 'В т.ч. в хорошем и удовл. состоянии, км'
                },
                {
                    key: 'f6',
                    label: 'Доля автодорог в хорошем и удовл. сост, %'
                }
            ],
            tableData: [
                {
                    'f1': '1',
                    'f2': 'Теренколь-Михайловка-граница РФ км 0-108',
                    'f3': 'Областное',
                    'f4': '108',
                    'f5': '107',
                    'f6': '99,1%'
                },
                {
                    'f1': '2',
                    'f2': 'Жана Жулдыз-Петропавловка км 0-35,004',
                    'f3': 'Областное',
                    'f4': '35',
                    'f5': '35',
                    'f6': '100,0%'
                },
                {
                    'f1': '3',
                    'f2': 'Подъезд к Окуневской переправе км 0-9',
                    'f3': 'Областное',
                    'f4': '9',
                    'f5': '9',
                    'f6': '100,0%'
                },
                {
                    'f1': '4',
                    'f2': 'Подъезд к Подстепкинской переправе км 2,728-9,141',
                    'f3': 'Областное',
                    'f4': '6,413',
                    'f5': '6,413',
                    'f6': '100,0%'
                },
                {
                    'f1': '5',
                    'f2': 'Беловка-Калкаман км 0-61',
                    'f3': 'Областное',
                    'f4': '61',
                    'f5': '23',
                    'f6': '37,7%'
                },
                {
                    'f1': '6',
                    'f2': 'Подъезд к Жанааульской переправе км 0-6,5',
                    'f3': 'Областное',
                    'f4': '6,5',
                    'f5': '6,5',
                    'f6': '100,0%'
                },
                {
                    'f1': '7',
                    'f2': 'Актогай-Шолаксор км 0-164',
                    'f3': 'Областное',
                    'f4': '164',
                    'f5': '118',
                    'f6': '72,0%'
                },
                {
                    'f1': '8',
                    'f2': 'Иртышск-Пятирыжск км 0-6,2',
                    'f3': 'Областное',
                    'f4': '6,2',
                    'f5': '6,2',
                    'f6': '100,0%'
                },
                {
                    'f1': '9',
                    'f2': 'Иртышск-станция Иртышская км 0-67,1',
                    'f3': 'Областное',
                    'f4': '67',
                    'f5': '60',
                    'f6': '89,4%'
                },
                {
                    'f1': '10',
                    'f2': 'Панфилово-Бестобе км 0-79,3',
                    'f3': 'Областное',
                    'f4': '79,3',
                    'f5': '49,3',
                    'f6': '62,2%'
                },
                {
                    'f1': '11',
                    'f2': 'Ивановка-Трофимовка-граница  РФ км 0-95',
                    'f3': 'Областное',
                    'f4': '95',
                    'f5': '41,7',
                    'f6': '43,9%'
                },
                {
                    'f1': '12',
                    'f2': 'Шакат-Восточное-Шалдай км 0-121,6',
                    'f3': 'Областное',
                    'f4': '121,6',
                    'f5': '84,9',
                    'f6': '69,8%'
                },
                {
                    'f1': '13',
                    'f2': 'Подъезд к Жанааульской переправе км 0-2,5',
                    'f3': 'Областное',
                    'f4': '2,5',
                    'f5': '2,5',
                    'f6': '100,0%'
                },
                {
                    'f1': '14',
                    'f2': 'Подъезд к Окуневской переправе км 0-3,5',
                    'f3': 'Областное',
                    'f4': '3,5',
                    'f5': '3,5',
                    'f6': '100,0%'
                },
                {
                    'f1': '15',
                    'f2': 'Чернорецк-Ольгино-Успенка-Шарбакты км 0-149',
                    'f3': 'Областное',
                    'f4': '149',
                    'f5': '141',
                    'f6': '94,6%'
                },
                {
                    'f1': '16',
                    'f2': 'Павловка-Галицкое км 0-40',
                    'f3': 'Областное',
                    'f4': '40',
                    'f5': '32,5',
                    'f6': '81,3%'
                },
                {
                    'f1': '17',
                    'f2': 'Экибастуз-ГРЭС-2 км 0-26,9',
                    'f3': 'Областное',
                    'f4': '26,9',
                    'f5': '4,9',
                    'f6': '18,2%'
                },
                {
                    'f1': '18',
                    'f2': 'Майкаин-Экибастуз км 0-29',
                    'f3': 'Областное',
                    'f4': '29',
                    'f5': '23',
                    'f6': '79,3%'
                },
                {
                    'f1': 'Итого',
                    'f2': '',
                    'f3': '',
                    'f4': '1010,0',
                    'f5': '754,4',
                    'f6': '74,7%'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>