<template>
    <b-container fluid="true">
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="АБП"
                        label="АБП"
                    >
                        <b-form-select v-model="selected1" :options="options1"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontalq"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Населенный пункт"
                        label="Населенный пункт"
                    >
                        <b-form-select v-model="selected2" :options="options2"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="text-right">
                <b-button variant="primary" href="#/demo-budget-execution/roads/roads-map/">Открыть на карте</b-button>
            </div>
        </b-card>
        <b-table :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" :items="tableData" :filter="selected2">
            <template #cell(f1)="data">
                <div class="text-center">
                    {{ data.item.f1 }}
                </div>
            </template>
            <template #cell(f4)="data">
                <div class="text-right">
                    {{ data.item.f4 }}
                </div>
            </template>
            <template #cell(f5)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f6)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f7)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f8)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
        </b-table>
    </b-container>
</template>

<script>
export default {
    name: 'Roads',
    data() {
        return {
            options1: [
                {value: null, text: 'Все'},
                {value: 'УС', text: 'УС'},
                {value: 'УПТиАД', text: 'УПТиАД'},
            ],
            selected1: 'УПТиАД',
            options2: [
                {value: null, text: 'Все'},
                {value: 'Актогайский р-н', text: 'Актогайский р-н'},
                {value: 'Баянаульский р-н', text: 'Баянаульский р-н'},
                {value: 'Железинский р-н', text: 'Железинский р-н'},
                {value: 'Иртышский р-н', text: 'Иртышский р-н'},
                {value: 'Теренкольский р-н', text: 'Теренкольский р-н'},
                {value: 'Аккулинский р-н', text: 'Аккулинский р-н'},
                {value: 'Майский р-н', text: 'Майский р-н'},
                {value: 'Павлодарский р-н', text: 'Павлодарский р-н'},
                {value: 'Успенский р-н', text: 'Успенский р-н'},
                {value: 'Щербактинский р-н', text: 'Щербактинский р-н'},
                {value: 'г. Аксу', text: 'г. Аксу'},
                {value: 'г. Павлодар', text: 'г. Павлодар'},
                {value: 'г. Экибастуз', text: 'г. Экибастуз'},
            ],
            selected2: null,
            tableFields: [
                {
                    key: 'f1',
                    label: '№'
                },
                {
                    key: 'f2',
                    label: 'Проекты'
                },
                {
                    key: 'f3',
                    label: 'АБП'
                },
                {
                    key: 'f4',
                    label: 'Нас. пункт'
                },
                {
                    key: 'f5',
                    label: 'Стоимость, млн тенге'
                },
                {
                    key: 'f6',
                    label: 'Срок завершения'
                },
                {
                    key: 'f7',
                    label: 'Прогресс'
                },
                {
                    key: 'f8',
                    label: 'Отставание, дней'
                }
            ],
            tableData: [
                {
                    'f1': 1,
                    'f2': 'Средний ремонт автомобильной дороги Ивановка-Трофимовка-граница РФ км 23-25 (2 км), 32-35 (3 км)',
                    'f3': 'УПТиАД',
                    'f4': 'Теренкольский р-н',
                    'f5': '204,6',
                    'f6': 2021,
                    'f7': '100%',
                    'f8': 0
                },
                {
                    'f1': 2,
                    'f2': 'Средний ремонт автомобильной дороги Актогай-Шолаксор км 60-63 (3 км), 66-70 (4 км)',
                    'f3': 'УПТиАД',
                    'f4': 'Актогайский р-н',
                    'f5': '251,8',
                    'f6': 2021,
                    'f7': '38%',
                    'f8': 24
                },
                {
                    'f1': 3,
                    'f2': 'Средний ремонт автомобильной дороги Актогай-Шолаксор км 91-111 (20 км)',
                    'f3': 'УПТиАД',
                    'f4': 'Актогайский р-н',
                    'f5': '907,5',
                    'f6': 2021,
                    'f7': '59%',
                    'f8': 5
                },
                {
                    'f1': 4,
                    'f2': 'Средний ремонт автомобильной дороги Актогай-Шолаксор км 88-91 (3 км)',
                    'f3': 'УПТиАД',
                    'f4': 'Актогайский р-н',
                    'f5': '127,2',
                    'f6': 2021,
                    'f7': '100%',
                    'f8': 0
                },
                {
                    'f1': 5,
                    'f2': 'Средний ремонт автомобильной дороги Чернорецк-Ольгино-Успенка-Шарбакты км 82-87 (5 км), 88-90 (2 км), 91-92 (1 км)',
                    'f3': 'УПТиАД',
                    'f4': 'Успенский р-н',
                    'f5': '293,4',
                    'f6': 2021,
                    'f7': '38%',
                    'f8': 24
                },
                {
                    'f1': 6,
                    'f2': 'Средний ремонт автомобильной дороги Беловка-Калкаман км 36-40 (4 км), 59-61 (2км)',
                    'f3': 'УПТиАД',
                    'f4': 'г. а. Аксу',
                    'f5': '211,7',
                    'f6': 2021,
                    'f7': '59%',
                    'f8': 5
                },
                {
                    'f1': 7,
                    'f2': 'Средний ремонт автомобильной дороги Шакат-Восточное Шалдай км 0-27 (27 км)',
                    'f3': 'УПТиАД',
                    'f4': 'Павлодарский р-н',
                    'f5': '872,4',
                    'f6': 2021,
                    'f7': '100%',
                    'f8': 0
                },
                {
                    'f1': 8,
                    'f2': 'Средний ремонт автомобильной дороги Павловка Галицкое км 0-7,5 (7,5 км)',
                    'f3': 'УПТиАД',
                    'f4': 'Успенский р-н',
                    'f5': '297,4',
                    'f6': 2021,
                    'f7': '38%',
                    'f8': 24
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>