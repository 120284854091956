import '@/modules/widget-yard/initialization';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import ChartGrouped from 'highcharts-grouped-categories';
import MultiColor from 'highcharts-multicolor-series';
import { accessor } from '@/modules/widget-yard/store';

HighchartsMore(Highcharts);
ChartGrouped(Highcharts);
MultiColor(Highcharts);

export const exporting = {
    getIndicator(key: string) {
        const list = accessor.indicators;
        const indicator = list.find(x => x.newKey === key);
        return indicator;
    },
    result(itemes: any): any[] | null {
        const year = 0;
        const lastresult: any[] = [];
        if (itemes.indicatorConfigs.length === 0) {
            return null;
        }
        let len = 0;
        // eslint-disable-next-line consistent-return
        itemes.indicatorConfigs.forEach((config: any) => {
            const indicatorKey = config.indicator;
            let indicator = accessor.getIndicator(indicatorKey);
            if (indicator === undefined) {
                indicator = this.getIndicator(indicatorKey);
            }

            if (indicator === undefined) {
                return null;
            }
            if (indicator.items.length > len) {
                len = indicator.items.length;
            }
            let data: any = null;
            const items: any[] = [];
            let growIndicProc = null;
            let growIndic = null;
            let fact = 0;
            const periodicity = indicator.periodicity;
            const confUnit: any = config.unit;
            let unit: any;
            if ((Object.keys(confUnit).length === 0)) {
                unit = indicator.unit;
            } else if ((confUnit.name_ru === '')) {
                unit = indicator.unit;
            } else {
                if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                    unit = confUnit.name_ru;
                } else {
                    unit = confUnit.nationalSymbol;
                }
            }
            let unitLowerCased: string | null = null;
            if (unit !== null || unit !== undefined) {
                unitLowerCased = unit.toLowerCase();
                if (unitLowerCased === 'процент') {
                    unitLowerCased = '%';
                }
            }
            const type = config.type;
            let datas = indicator.items.slice(-7 + year, indicator.items.length + year);

            if (indicator.periodicity === 'квартал' || indicator.periodicity === 'квартал с накоплением') {
                datas = indicator.items.slice(-8 + year, indicator.items.length + year);
            }

            if (indicator.periodicity === 'месяц' || indicator.periodicity === 'месяц с накоплением') {
                datas = indicator.items.slice(-12 + year, indicator.items.length + year);
            }
            const selectedItems = accessor.selectedMap.get(indicator.key);
            let measurement = 1;
            if (config.measurement !== undefined) {
                measurement = config.measurement;
            }
            if (selectedItems !== undefined) {
                datas.forEach((selectedItem) => {
                    const item: any = {
                        date: selectedItem.date,
                        value: Math.round((selectedItem.fact * measurement) * 10) / 10
                    };
                    items.push(item);
                });
                const current = selectedItems.item;
                if (current !== undefined) {
                    fact = current.fact;
                    const typeIndic = selectedItems.character;
                    const previous = selectedItems.previousItem;
                    if (previous !== undefined) {
                        growIndicProc = Math.round(((current.fact / previous.fact) * 100 - 100) * 100) / 100;
                        if (unitLowerCased === '%') {
                            growIndicProc = Math.round((current.fact - previous.fact) * 100) / 100;
                        }
                        if (typeIndic === 0) {
                            growIndic = typeIndic;
                        } else {
                            if (growIndicProc === 0) {
                                growIndic = typeIndic;
                            } else {
                                growIndic = typeIndic * growIndicProc;
                            }
                        }
                    }
                    data = {
                        name: indicator.shortIndicator,
                        items,
                        unit: unitLowerCased,
                        growIndic,
                        growIndicProc,
                        fact,
                        type,
                        periodicity,
                        diffAxis: config.diffAxis
                    };
                }
            }
            const categ: any = itemes.dictCategoryEntity;
            let category = null;
            if (Object.keys(categ).length > 0) {
                category = categ.code;
            }
            lastresult.push({ title: config.title, data, indicatorKey, widgetTitle: itemes.title, category, id: itemes.id });
        });
        return lastresult;
    },

    setOptions(data: any) {
        if (data[0] === undefined) {
            return null;
        }
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const newColors = ['#ccebff', '#ffc8c7', '#ffd3b0', '#c7e1ff'];
        const test: Highcharts.SeriesOptionsType[] = [];
        const label: any[] = [];
        const widgetTitle = data[0].widgetTitle;
        let xLine: any = {};
        const showLegend = true;
        const periodicity = data[0].data?.periodicity;
        const id = data[0].id;
        const code = data[0].category;
        for (let i = 0; i < data.length; i++) {
            const elem = data[i];
            const datas = elem.data;
            if (datas === null) {
                return null;
            }
            // описание грaфика по yAxis
            const labels: object = {
                format: '{value}',
                style: {
                    color: colors[i],
                    fontSize: 0
                }
            };
            const title: object = {
                text: datas.unit,
                style: {
                    color: colors[i],
                    fontSize: 0
                }
            };
            let opposite = false;
            if ((i % 2 === 0)) {
                opposite = true;
            }
            const maxPadding = 0.1;
            label.push({ labels, title, opposite, maxPadding });
            // создание графика(series)
            const datab: any = [];
            const type = 'coloredline';
            const tooltip: object = {
                valueSuffix: ' '
            };
            const color = colors[i];
            const dataLabels = {
                y: 0
            };
            const legendIndex = i;
            let names = elem.title + ', ' + datas.unit;
            if (datas.unit === null) {
                names = datas.name;
            }
            if (datas.periodicity === 'год') {
                const categories: any = [];
                const date = new Date();
                const year = date.getFullYear();
                datas.items.forEach((item: any) => {
                    const itemDate = item.date;
                    const itemDateText = itemDate.getFullYear();
                    const count = Number(itemDateText);
                    categories.push(count);
                    let lineColor = colors[i];
                    if (year - 1 < count) {
                        lineColor = newColors[i];
                    }
                    datab.push({
                        'x': count,
                        'y': item.value,
                        'segmentColor': lineColor,
                        'color': lineColor,
                        'dataLabels': {
                            'enabled': true,
                            'color': colors[i]
                        }
                    });
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    },
                    tickInterval: 1
                };
            }
            if (datas.periodicity === 'квартал' || datas.periodicity === 'квартал с накоплением') {
                const categories: any[] = [];
                const milliseconds: number[] = [];
                const date = new Date();
                const currentM = date.getMonth();
                const currentY = date.getFullYear();
                datas.items.forEach((item: any) => {
                    const itemDate = item.date;
                    let lineColor = colors[i];
                    const year = itemDate.getFullYear();
                    let month = itemDate.getMonth() + 1;
                    const monthCur = itemDate.getMonth();
                    if (month <= 3) {
                        month = 'I';
                    } else if ((month > 3) && (month <= 6)) {
                        month = 'II';
                    } else if ((month > 6) && (month <= 9)) {
                        month = 'III';
                    } else if ((month > 9) && (month <= 12)) {
                        month = 'IV';
                    }
                    const d = month + '-' + (String(year)).substr(2);
                    if (currentY < year) {
                        lineColor = newColors[i];
                    } else if (currentY === year) {
                        if (currentM < monthCur + 1) {
                            lineColor = newColors[i];
                        }
                    }
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    datab.push({
                        'y': item.value,
                        'segmentColor': lineColor,
                        'color': lineColor
                    });
                });
                categories.sort((a: any, b: any) => parseInt(a.name) - parseInt(b.name));
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    },
                    categories: categories
                };
            }
            test.push({ name: names, type, 'data': datab, tooltip, legendIndex, color, dataLabels, yAxis: 0 } as any);
            test.forEach((item, index) => {
                if (datas.diffAxis === true) {
                    item.yAxis = index;
                }
            });
        }
        const options = Highcharts.setOptions({
            colors,
            chart: {
                backgroundColor: 'transparent',
                height: 400,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: widgetTitle,
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px'
                },
                widthAdjust: -150
            },
            exporting: {
                enabled: false
            },
            xAxis: xLine,
            yAxis: label,
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: '.7rem',
                            color: '#459ED8'
                        }
                    }
                },
                column: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true
                    }
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        allowOverlap: true,
                        style: {
                            fontSize: '.8rem',
                            fontWeight: 'normal',
                            textOutline: '0px'
                        }
                    },
                    animation: false
                }
            },
            credits: {
                enabled: false
            },
            tooltip: {
                shared: true
            },
            legend: {
                enabled: showLegend,
                x: 20,
                align: 'left',
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '400',
                    fontSize: '14px',
                    textOverflow: 'null'
                }
            },
            series: test
        });
        const element = document.createElement('div');
        const chart = Highcharts.chart(element, options);
        const form = {
            'title': widgetTitle,
            'periodicity': periodicity,
            'url': chart.getSVG(),
            'code': code,
            'id': id
        };
        chart.destroy();
        return form;
    },
    setOptionsMonth(data: any) {
        if (data[0] === undefined) {
            return null;
        }
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const newColors = ['#ccebff', '#ffc8c7', '#ffd3b0', '#c7e1ff'];
        const test: Highcharts.SeriesOptionsType[] = [];
        const label: any[] = [];
        const widgetTitle = data[0].widgetTitle;
        let xLine: any = {};
        const showLegend = true;
        const periodicity = data[0].data?.periodicity;
        const code = data[0].category;
        const id = data[0].id;
        for (let i = 0; i < data.length; i++) {
            const elem = data[i];
            const datas = elem.data;
            if (datas === null) {
                return null;
            }
            // описание грaфика по yAxis
            const labels: object = {
                format: '{value}',
                style: {
                    color: colors[i],
                    fontSize: 0
                }
            };
            const title: object = {
                text: datas.unit,
                style: {
                    color: colors[i],
                    fontSize: 0
                }
            };
            let opposite = false;
            if ((i % 2 === 0)) {
                opposite = true;
            }
            const maxPadding = 0.1;
            label.push({ labels, title, opposite, maxPadding });
            // создание графика(series)
            const datab: any = [];
            const type = 'coloredline';
            const tooltip: object = {
                valueSuffix: ' '
            };
            const color = colors[i];
            const dataLabels = {
                y: 0
            };
            const legendIndex = i;
            let names = elem.title + ', ' + datas.unit;
            if (datas.unit === null) {
                names = datas.name;
            }
            if (datas.periodicity === 'месяц' || datas.periodicity === 'месяц с накоплением') {
                const categories: any = [];
                const milliseconds: number[] = [];
                const date = new Date();
                const currentM = date.getMonth();
                const currentY = date.getFullYear();
                datas.items.forEach((item: any) => {
                    const itemDate = item.date;
                    let lineColor = colors[i];
                    const shortMonthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
                    const year = itemDate.getFullYear();
                    const month = itemDate.getMonth();
                    const d = shortMonthNames[month] + '/' + (String(year)).substr(2);
                    if (currentY < year) {
                        lineColor = newColors[i];
                    } else if (currentY === year) {
                        if (currentM < month + 1) {
                            lineColor = newColors[i];
                        }
                    }
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    datab.push({
                        'y': item.value,
                        'segmentColor': lineColor,
                        'color': lineColor
                    });
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '12px',
                            fontWeight: 600
                        },
                        // eslint-disable-next-line consistent-return
                        formatter() {
                            // eslint-disable-next-line @typescript-eslint/no-this-alias
                            const that: any = this;
                            if (that.pos % 2 !== 0) {
                                return '<span>' + that.value + '</span>';
                            }
                        }
                    },
                    tickInterval: 1,
                    categories: categories
                };
            }
            test.push({ name: names, type, 'data': datab, tooltip, legendIndex, color, dataLabels, yAxis: 0 } as any);
            test.forEach((item, index) => {
                if (datas.diffAxis === true) {
                    item.yAxis = index;
                }
            });
        }
        const plot = {
            line: {
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '.7rem',
                        color: '#459ED8'
                    },
                    // eslint-disable-next-line consistent-return
                    formatter() {
                        // eslint-disable-next-line @typescript-eslint/no-this-alias
                        const that: any = this;
                        if (that.point.index % 2 !== 0) {
                            return '<span>' + that.y.toLocaleString('RU-ru') + '</span>';
                        }
                    }
                }
            },
            column: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true
                },
                // eslint-disable-next-line consistent-return
                formatter() {
                    // eslint-disable-next-line @typescript-eslint/no-this-alias
                    const that: any = this;
                    if (that.point.index % 2 !== 0) {
                        return '<span>' + that.y.toLocaleString('RU-ru') + '</span>';
                    }
                }
            },
            series: {
                dataLabels: {
                    enabled: true,
                    allowOverlap: true,
                    style: {
                        fontSize: '.8rem',
                        fontWeight: 'normal',
                        textOutline: '0px'
                    },
                    // eslint-disable-next-line consistent-return
                    formatter() {
                        // eslint-disable-next-line @typescript-eslint/no-this-alias
                        const that: any = this;
                        if (that.point.index % 2 !== 0) {
                            return '<span>' + that.y.toLocaleString('RU-ru') + '</span>';
                        }
                    }
                },
                animation: false
            }
        };
        const options = Highcharts.setOptions({
            colors,
            chart: {
                backgroundColor: 'transparent',
                height: 400,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: widgetTitle,
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px'
                },
                widthAdjust: -150
            },
            exporting: {
                enabled: false
            },
            xAxis: xLine,
            yAxis: label,
            plotOptions: plot,
            credits: {
                enabled: false
            },
            tooltip: {
                shared: true
            },
            legend: {
                enabled: showLegend,
                x: 20,
                align: 'left',
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '400',
                    fontSize: '14px',
                    textOverflow: 'null'
                }
            },
            series: test
        });
        const element = document.createElement('div');
        const chart = Highcharts.chart(element, options);
        const form = {
            'title': widgetTitle,
            'periodicity': periodicity,
            'url': chart.getSVG(),
            'code': code,
            'id': id
        };
        chart.destroy();
        return form;
    }
};