









































































































































































































































































































import Class from "./soc-eco";
export default Class;
