import axios from 'axios';
import store from '@/services/store';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export abstract class HomeApi {
    public code = store.state._instanceCode;
    private static instanse = axios.create({
        headers: {
            'Access-Control-Allow-Origin': '*'
        }
    });
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getBp(): Promise<any> {
        const params = { year: 2021, budgetLevel: [2], obl: '55' };
        const url = '/api-py/intercon-bp-ci/';
        // eslint-disable-next-line no-return-await
        return await this.instanse.post(url, JSON.stringify(params));
    }

    static async getBpFast(): Promise<any> {
        const params = { year: 2021, budgetLevel: [2], obl: '55' };
        const url = '/api-py/intercon-bp-ci-fast/';
        // eslint-disable-next-line no-return-await
        return await this.instanse.post(url, JSON.stringify(params));
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getCi(): Promise<any> {
        const url = '/api/stat-dicts/target_indicator_count?year=2021';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getSnp(): Promise<any> {
        const url = '/api/snp/widget';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getSnp2(): Promise<any> {
        const url = '/api/snp/widget-2';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getSnp3(): Promise<any> {
        const url = '/api/snp/widget-3';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getSnp4(): Promise<any> {
        const url = '/api/snp/widget-4';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getInvest(): Promise<any> {
        const url = '/api/widget/total';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    // static async getGr(year = 2022): Promise<any> {
    //     const code: any = store.state._instanceCode;
    //     let url = `/api-py/get-budget-request-gr/${year}/450101`;
    //     if (code === '23') {
    //         url = `/api-py/get-cost-data-gr/${year}/150101`;
    //     }
    //     // eslint-disable-next-line no-return-await
    //     return await this.instanse.get(url);
    // }
    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getKat(year = 2022): Promise<any> {
        const code: any = store.state._instanceCode;
        let url = `/api-py/get-budget-request-kat/${year}/450101`;
        if (code === '23') {
            url = `/api-py/get-budget-request-kat/${year}/150101`;
        }
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getIncome(): Promise<any> {
        const date = new Date();
        const code: any = store.state._instanceCode;
        let url = `/api-py/get-request-budg-doh/${date.getFullYear()}/09/450101`;
        if (code === '23') {
            url = `/api-py/get-request-budg-doh/${date.getFullYear()}/09/150101`;
        }
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getOutcome(): Promise<any> {
        const date = new Date();
        const code: any = store.state._instanceCode;
        let url = `/api-py/get-request-budg-rash/${date.getFullYear()}/09/450101`;
        if (code === '23') {
            url = `/api-py/get-request-budg-rash/${date.getFullYear()}/09/150101`;
        }
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getBip(): Promise<any> {
        const url = '/api-py/bip-widget-one';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getBip2(): Promise<any> {
        const url = '/api-py/bip-widget-two';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
    static async getBip3(): Promise<any> {
        const url = '/api-py/budget-progs-sum-widget';
        // eslint-disable-next-line no-return-await
        return await this.instanse.get(url);
    }
}