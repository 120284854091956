<template>
    <div>
        <div
            style="display: table; width: 90%; margin-left: 15px"
            v-if="topData!=undefined && topData!=null"
        >
            <div style="display: table-row-group;">
                <template v-for="(topEl, topIndx) in topData">
                    <template v-if="topEl!=undefined">
                        <div class="tapeRow" :key="'topIndx'+topIndx+'head'">
                            <div class="tapeCell">
                              <a :href="topEl.url" target="_blank" rel="noopener noreferrer">
                                <c-socSrcIcon :source="topEl.socialNet" :name="true"></c-socSrcIcon>
                              </a>
                            </div>
                            <div class="tapeCell">{{dateTimeFormat().format(new Date(topEl.bDate))}}</div>
                            <div class="tapeCell">{{topEl.autor}}</div>
                            <div class="tapeCell" style="float: right">
                                <span
                                    class="mdi mdi-thumb-up-outline tapeSumSpan"
                                    v-if="topEl.likeSum!==null && topEl.likeSum!=undefined"
                                    title="Просмотры"
                                >&nbsp;{{topEl.likeSum}}</span>
                                <span
                                    class="mdi mdi-comment-outline tapeSumSpan"
                                    v-if="topEl.commentSum!==null && topEl.commentSum!=undefined"
                                    title="Комментарии"
                                >&nbsp;{{topEl.commentSum}}</span>
                                <span
                                    class="mdi mdi-share-outline tapeSumSpan"
                                    v-if="topEl.shareSum!==null && topEl.shareSum!=undefined"
                                    title="Репосты"
                                >{{topEl.shareSum}}</span>
                                <span
                                    class="mdi mdi-eye-outline tapeSumSpan"
                                    title="Просмотры"
                                >&nbsp;{{topEl.viewSum}}</span>
                            </div>
                        </div>
                        <div
                            class="tapeRow"
                            :key="'topIndx'+topIndx+'text'"
                            style="word-break: break-all;"
                        >
                            <div class="socialCaption" v-html="topEl.caption"></div>
                            <div
                                v-if="moreIndx==topIndx"
                                class="text"
                                @click="clkMoreClose(topIndx)"
                                v-html="fullText"
                            ></div>
                            <template v-else>
                                <div class="text" v-html="topEl.text"></div>
                                <div
                                    class="more"
                                    @click="getFullText(topIndx)"
                                    v-if="topEl.textShort"
                                >Подробнее...</div>
                            </template>
                        </div>
                        <br :key="'topIndx'+topIndx+'br'" />
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tapeRow {
    display: table-row;
    padding-top: 15px;
}
.tapeCell {
    float: left;
    margin-left: 0px;
    padding-right: 30px;
}
.tapeSumSpan {
    padding-right: 5px;
}
.text {
    padding-right: 1rem;
    color: #4a4a4a;
    text-align: left;
}
.more {
    color: #b4b4b4;
    font-size: smaller;
    cursor: pointer;
}
.socialCaption {
    font-weight: bold;
}
</style>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import CSocialSourceIcon from '@/modules/socialnet/components/cSocialSourceIcon.vue'
import axios from 'axios'

@Component({
  name: 'c-soc-tape',
  components: {
    'c-socSrcIcon': CSocialSourceIcon
  }
})
export default class CSocTape extends Vue {
    @Prop({
      required: true,
      default: []
    })
    data; // данные

    @Prop({
      required: false,
      default: 'involvemed'
    })
    sortField; // сортировка по какому полю

    topLenght = 5 // кол-во элементов в ленте
    moreIndx = -1; // индекс "подробнее"
    fullText = ''

    get topData () {
      const that = this
      let result = that.cloneArr(that.data)
      if (that.sortField === 'involvemed') {
        for (const el of result) {
          el.involvemed = 0
          if (el.commentSum !== undefined && el.commentSum !== null) { el.involvemed = el.involvemed + el.commentSum }
          if (el.likeSum !== undefined && el.likeSum !== null) { el.involvemed = el.involvemed + el.likeSum }
          if (el.shareSum !== undefined && el.shareSum !== null) { el.involvemed = el.involvemed + el.shareSum }
        }
      }
      result = result.sort(function (d1, d2) {
        return (d2[that.sortField] - d1[that.sortField])
      })
      result.length = that.topLenght
      return result
    }

    cloneArr (arr) {
      const newArr = []
      for (const el of arr) {
        newArr.push(Object.assign({}, el))
      }
      return newArr
    }

    clkMoreClose (indx) {
      if (indx === this.moreIndx) {
        this.fullText = ''
        this.moreIndx = -1
      }
    }

    dateTimeFormat () {
      return new Intl.DateTimeFormat('ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
    }

    async getFullText (indx) {
      const that = this
      that.fullText = ''
      let el = null
      await axios
        .post('/report-form-fullText', {
          params: {
            'type': 1,
            'id': that.topData[indx].id,
            'socialNet': that.topData[indx].socialNet
          }
        })
        .then(response => (el = response.data))
      that.fullText = el.data.text
      this.moreIndx = indx
    }
}
</script>
