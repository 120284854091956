






























import Class from './c-widget-tab';
export default Class;
