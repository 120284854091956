<template>
    <b-container fluid="true">
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="АБП"
                        label="АБП"
                    >
                        <b-form-select v-model="selected1" :options="options1"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontalq11"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Сфера"
                        label="Сфера"
                    >
                        <b-form-select v-model="selected4" :options="options4"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontalq"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Населенный пункт"
                        label="Населенный пункт"
                    >
                        <b-form-select v-model="selected2" :options="options2"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontalq1"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Срок действия госэкспертизы"
                        label="Срок действия госэкспертизы"
                    >
                        <b-form-select v-model="selected3" :options="options3"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="text-right">
                <b-button variant="primary"  href="#/demo-budget-execution/roads/bip-roads-modeling/">Моделирование
                </b-button>
            </div>
        </b-card>
        <b-table :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" :items="tableData" :filter="selected2">
            <template #cell(f1)="data">
                <div class="text-center">
                    {{ data.item.f1 }}
                </div>
            </template>
            <template #cell(f4)="data">
                <div class="text-right">
                    {{ data.item.f4 }}
                </div>
            </template>
            <template #cell(f5)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f6)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f7)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f8)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
        </b-table>
    </b-container>
</template>

<script>
export default {
    name: 'Roads',
    data() {
        return {
            options1: [
                {value: null, text: 'Все'},
                {value: 'УС', text: 'УС'},
                {value: 'УПТиАД', text: 'УПТиАД'},
            ],
            selected1: 'УПТиАД',
            options4: [
                {value: 'Дороги', text: 'Дороги'},
                {value: 'Здравоохранение', text: 'Здравоохранение'},
                {value: 'Образование', text: 'Образование'},
            ],
            selected4: 'Дороги',
            options2: [
                {value: null, text: 'Все'},
                {value: 'Актогайский р-н', text: 'Актогайский р-н'},
                {value: 'Баянаульский р-н', text: 'Баянаульский р-н'},
                {value: 'Железинский р-н', text: 'Железинский р-н'},
                {value: 'Иртышский р-н', text: 'Иртышский р-н'},
                {value: 'Теренкольский р-н', text: 'Теренкольский р-н'},
                {value: 'Аккулинский р-н', text: 'Аккулинский р-н'},
                {value: 'Майский р-н', text: 'Майский р-н'},
                {value: 'Павлодарский р-н', text: 'Павлодарский р-н'},
                {value: 'Успенский р-н', text: 'Успенский р-н'},
                {value: 'Щербактинский р-н', text: 'Щербактинский р-н'},
                {value: 'г. Аксу', text: 'г. Аксу'},
                {value: 'г. Павлодар', text: 'г. Павлодар'},
                {value: 'г. Экибастуз', text: 'г. Экибастуз'},
            ],
            selected2: null,
            options3: [
                {value: null, text: 'Все'},
                {value: '2021', text: '2021'},
                {value: '2022', text: '2022'},
                {value: '2023', text: '2023'},
            ],
            selected3: null,
            tableFields: [
                {
                    key: 'f1',
                    label: '№'
                },
                {
                    key: 'f2',
                    label: 'Проекты'
                },
                {
                    key: 'f3',
                    label: 'АБП'
                },
                {
                    key: 'f8',
                    label: 'Сфера'
                },
                {
                    key: 'f4',
                    label: 'Нас. пункт'
                },
                {
                    key: 'f5',
                    label: 'Стоимость, млн тенге'
                },
                {
                    key: 'f6',
                    label: 'Срок завершения'
                },
                {
                    key: 'f7',
                    label: 'Срок действия госэкспертизы'
                }
            ],
            tableData: [
                {
                    'f1': 1,
                    'f2': 'Средний ремонт автомобильной дороги Актогай-Шолаксор км 35-39 (4 км), 50-54 (4 км)',
                    'f3': 'УПТиАД',
                    'f4': 'Актогайский р-н',
                    'f5': '287,1',
                    'f6': 2022,
                    'f7': '15.08.2022',
                    'f8': 'Дороги'
                },
                {
                    'f1': 2,
                    'f2': 'Средний ремонт автомобильной дороги Панфилово-Бестобе км 49-64 (15 км)',
                    'f3': 'УПТиАД',
                    'f4': 'Иртышский р-н',
                    'f5': '613,9',
                    'f6': 2022,
                    'f7': '09.06.2023',
                    'f8': 'Дороги'
                },
                {
                    'f1': 3,
                    'f2': 'Средний ремонт автомобильной дороги Беловка-Калкаман км 19-33 (14 км)',
                    'f3': 'УПТиАД',
                    'f4': 'г. а. Аксу',
                    'f5': '494',
                    'f6': 2022,
                    'f7': '28.03.2023',
                    'f8': 'Дороги'
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>