<template>
    <b-container>
        <b-button variant="primary" href="#/demo-budget-execution/roads/roads/">Потребности</b-button>
        <b-button variant="primary" href="#/demo-budget-execution/roads/bip-roads/">БИП</b-button>
        <b-button variant="primary" href="#/demo-budget-execution/roads/bip-roads-modeling/">Моделирование</b-button>
        <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1IxuBB-y1vBll9jKiZPifYlNGY-Bb5qjh" width="100%" height="800"></iframe>
    </b-container>
</template>

<script>
export default {
    name: 'RoadsMap'
};
</script>

<style scoped>

</style>