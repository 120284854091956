<template>
    <b-container fluid="true">
        <div class="section-title budget">Моделирование</div>
        <b-tabs v-model="tabIndex">
            <b-tab title="Шаг 1">
                <b-card title="Перечень новых проектов">
                    <div class="h3 mb-0 text-right">
                        <b-icon-cart4 variant="success"></b-icon-cart4>
                        <b-badge pill variant="warning">{{ checkedRows }}</b-badge>
                    </div>
                    <b-table :fields="tableFields" bordered :items="tableData" :filter="selected2"  striped thead-class="bold_header">
                        <template #thead-top="data">
                            <b-tr class="text-center">
                                <b-th colspan="5" rowspan="2"></b-th>
                                <b-th rowspan="2">ПРО</b-th>
                                <b-th rowspan="2">Нурлы жол</b-th>
                                <b-th rowspan="2">ГПИИР</b-th>
                                <b-th rowspan="2">ДКЗ</b-th>
                                <b-th colspan="5">Дополнительные критерии</b-th>
                                <b-th colspan="2" rowspan="2"></b-th>
                            </b-tr>
                            <b-tr class="text-center">
                                <b-th></b-th>
                                <b-th colspan="2">Нал. объектов /предприятий</b-th>
                                <b-th colspan="2">Числ. Населения</b-th>
                            </b-tr>
                        </template>
                        <template #cell(f1)="data">
                            <div class="text-center bold_header">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f2)="data">
                            <div class="text-left bold_header">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f3)="data">
                            <div class="text-right">
                                {{ data.item.f3 }}
                            </div>
                        </template>
                        <template #cell(f4)="data">
                            <div class="text-right">
                                {{ data.item.f4 }}
                            </div>
                        </template>
                        <template #cell(f5)="data">
                            <div class="text-right">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f6)="data">
                            <div class="text-right">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f7)="data">
                            <div class="text-right">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f8)="data">
                            <div class="text-right">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f12)="data">
                            <b-checkbox v-if="data.index>0" v-model="data.item.f12" class="text-center"></b-checkbox>
                        </template>
                        <template slot="bottom-row">
                            <td/>
                            <td>Значение ЦИ с учетом реализации проектов</td>
                            <td class="text-right">{{ totalSumRow.f3 }}</td>
                            <td/>
                            <td/>
                            <td class="text-right">{{ totalSumRow.f4 }}</td>
                            <td class="text-right">{{ totalSumRow.f5 }}</td>
                            <td class="text-right">{{ totalSumRow.f6 }}</td>
                            <td class="text-right">{{ totalSumRow.f7 }}</td>
                        </template>
                        <template slot="custom-foot">
                            <b-tr variant="danger">
                                <td/>
                                <td>Необходимо для достижения ЦИ</td>
                                <td class="text-right">{{ (tableData[0].f3 - totalSumRow.f3).toFixed(1) }}</td>
                                <td/>
                                <td/>
                                <td class="text-right">{{ (tableData[0].f4 - totalSumRow.f4).toFixed(1) }}</td>
                                <td class="text-right">{{ (tableData[0].f5 - totalSumRow.f5).toFixed(1) }}</td>
                                <td class="text-right">{{ (tableData[0].f6 - totalSumRow.f6).toFixed(1) }}</td>
                                <td class="text-right">{{ (tableData[0].f7 - totalSumRow.f7).toFixed(1) }}</td>
                            </b-tr>
                        </template>
                    </b-table>
                    <div class="text-right">
                        <b-button @click="tabIndex++">Отправить на расчет</b-button>
                    </div>
                </b-card>
            </b-tab>
            <b-tab title="Шаг 2">
                <b-card title="Перечень новых проектов">
                    <b-row>
                        <b-form-group
                            label-cols-sm="4"
                            label-cols-lg="3"
                            content-cols-sm
                            content-cols-lg="7"
                            label="Лимит"
                            class="ml-3"
                        >
                            <b-form-input readonly="true" :value="limit+' млрд.'" class="text-right"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label-cols-sm="4"
                            label-cols-lg="3"
                            content-cols-sm
                            content-cols-lg="7"
                            label="Выбрано"
                            class="ml-3"
                        >
                            <b-form-input readonly="true" :value="usedSum+' млрд.'" class="text-right"></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label-cols-sm="4"
                            label-cols-lg="3"
                            content-cols-sm
                            content-cols-lg="7"
                            label="Остаток"
                            class="ml-3"
                        >
                            <b-form-input readonly="true" :value="remaindSum+' млрд.'"
                                          class="text-right"></b-form-input>
                        </b-form-group>
                    </b-row>
                    <b-table :fields="tableFields2" bordered no-border-collapse striped thead-class="bold_header" :items="model2">
                        <template #cell(f1)="data">
                            <div class="text-center">
                                {{ data.item.f1 }}
                            </div>
                        </template>
                        <template #cell(f2)="data">
                            <div class="text-left">
                                {{ data.item.f2 }}
                            </div>
                        </template>
                        <template #cell(f11)="data">
                            <div class="text-right">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f12)="data">
                            <div class="text-right">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f13)="data">
                            <div class="text-right">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f14)="data">
                            <div class="text-right">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f15)="data">
                            <div class="text-right">
                                {{ data.value }}
                            </div>
                        </template>
                        <template #cell(f16)="data">
                            <b-form-input class="text-right"
                                          :value="data.item.f16"
                                          @change="v => data.item.f16 = v">
                            </b-form-input>
                        </template>
                        <template slot="bottom-row">
                            <td/>
                            <td>Итого</td>
                            <td/>
                            <td/>
                            <td/>
                            <td/>
                            <td class="text-right">{{ totalSum }}</td>
                        </template>
                    </b-table>
                    <div class="text-right">
                        <b-button v-b-modal.modal-1>Утвердить</b-button>
                    </div>
                </b-card>
            </b-tab>
        </b-tabs>
        <b-modal id="modal-1" title="Результат" ok-only>
            Результаты моделирования
            успешно утверждены!
        </b-modal>
    </b-container>
</template>

<script>
import {BIconCart4} from 'bootstrap-vue'

export default {
    name: 'Roads',
    components: {
        BIconCart4
    },
    data() {
        return {
            tabIndex: 0,
            limit: 4,
            options1: [
                {value: null, text: 'Все'},
                {value: 'УС', text: 'УС'},
                {value: 'УПТиАД', text: 'УПТиАД'}
            ],
            selected1: 0,
            options2: [
                {value: null, text: 'Все'},
                {value: 'Актогайский р-н', text: 'Актогайский р-н'},
                {value: 'Баянаульский р-н', text: 'Баянаульский р-н'},
                {value: 'Железинский р-н', text: 'Железинский р-н'},
                {value: 'Иртышский р-н', text: 'Иртышский р-н'},
                {value: 'Теренкольский р-н', text: 'Теренкольский р-н'},
                {value: 'Аккулинский р-н', text: 'Аккулинский р-н'},
                {value: 'Майский р-н', text: 'Майский р-н'},
                {value: 'Павлодарский р-н', text: 'Павлодарский р-н'},
                {value: 'Успенский р-н', text: 'Успенский р-н'},
                {value: 'Щербактинский р-н', text: 'Щербактинский р-н'},
                {value: 'г. Аксу', text: 'г. Аксу'},
                {value: 'г. Павлодар', text: 'г. Павлодар'},
                {value: 'г. Экибастуз', text: 'г. Экибастуз'}
            ],
            selected2: 0,
            options3: [
                {value: null, text: 'Все'},
                {value: '2021', text: '2021'},
                {value: '2022', text: '2022'},
                {value: '2023', text: '2023'}
            ],
            selected3: 0,
            tableFields: [
                {
                    key: 'f1',
                    label: '№',
                    stickyColumn: true
                },
                {
                    key: 'f2',
                    label: 'Проект',
                    stickyColumn: true
                },
                {
                    key: 'f3',
                    label: 'Протяж. рем-мых дорог, км'
                },
                {
                    key: 'f19',
                    label: 'Стоимость, млн тенге'
                },
                {
                    key: 'f20',
                    label: 'Срок действия госэкспертизы'
                },
                {
                    key: 'f4',
                    label: 'Доля автодорог местного знач., нах-ся в хорошем и удов. состоянии, %'
                },
                {
                    key: 'f5',
                    label: 'Пров-ие рем-ых работ, км.'
                },
                {
                    key: 'f6',
                    label: 'Инвест. в основной капитал, % реального роста к уровню 2019 г.'
                },
                {
                    key: 'f7',
                    label: 'Раб. места'
                },
                {
                    key: 'f8',
                    label: 'Инт-сть, авт/сут'
                },
                {
                    key: 'f9',
                    label: 'Отрасл. закл.'
                },
                {
                    key: 'f17',
                    label: 'Рег. стандарт'
                },
                {
                    key: 'f10',
                    label: 'Отрасл. закл.'
                },
                {
                    key: 'f18',
                    label: 'Рег. стандарт'
                },
                {
                    key: 'f11',
                    label: 'Балл'
                },
                {
                    key: 'f12',
                    label: 'Поддержать'
                }
            ],
            tableFields2: [
                {
                    key: 'f1',
                    label: '№'
                },
                {
                    key: 'f2',
                    label: 'Проект'
                },
                {
                    key: 'f11',
                    label: 'Балл'
                },
                {
                    key: 'f13',
                    label: 'Запрашиваемая стоимость финансирования в МБ, млн тенге'
                },
                {
                    key: 'f14',
                    label: 'Направлена БЗ на получение целевого трансферта из РБ, млн тенге'
                },
                {
                    key: 'f15',
                    label: 'Доля стоимости к финансированию МБ, %'
                },
                {
                    key: 'f16',
                    label: 'Сумма к финансированию из МБ, млн тенге'
                }
            ],
            tableData: [
                {
                    'f1': null,
                    'f2': 'Плановое значение ЦИ',
                    'f3': 222,
                    'f4': 89,
                    'f5': 367,
                    'f6': 142,
                    'f7': 261,
                    'f8': null,
                    'f9': '',
                    'f10': '',
                    'f11': '',
                    'f12': '',
                    'f13': null,
                    'f14': null,
                    'f15': '',
                    'f16': null
                },
                {
                    'f1': 1,
                    'f2': 'Средний ремонт автомобильной дороги Актогай-Шолаксор км 35-39 (4 км), 50-54 (4 км)',
                    'f3': 8,
                    'f4': 0.04,
                    'f5': 8,
                    'f6': 0.02,
                    'f7': 35,
                    'f8': 450,
                    'f9': '15 МСБ',
                    'f17': '14 МСБ',
                    'f10': 82000,
                    'f18': 80000,
                    'f11': 7.2,
                    'f12': true,
                    'f13': 287,
                    'f14': 0,
                    'f15': 100,
                    'f16': 287,
                    'f19': 287.1,
                    'f20': '15.08.2022',
                },
                {
                    'f1': 2,
                    'f2': 'Средний ремонт автомобильной дороги Панфилово-Бестобе км 49-64 (15 км)',
                    'f3': 15,
                    'f4': 0.08,
                    'f5': 15,
                    'f6': 0.04,
                    'f7': 55,
                    'f8': 550,
                    'f9': '1 Школа',
                    'f17': '1 Школа',
                    'f10': 45000,
                    'f18': 45000,
                    'f11': 8.2,
                    'f12': true,
                    'f13': 614,
                    'f14': 208,
                    'f15': 66,
                    'f16': 405,
                    'f19': 613.9,
                    'f20': '09.06.2023',
                },
                {
                    'f1': 3,
                    'f2': 'Средний ремонт автомобильной дороги Беловка-Калкаман км 19-33 (14 км)',
                    'f3': 14,
                    'f4': 0.08,
                    'f5': 14,
                    'f6': 0.03,
                    'f7': 50,
                    'f8': 200,
                    'f9': '3 торговых точек',
                    'f17': '4 торговых точек',
                    'f10': 6000,
                    'f18': 4560,
                    'f11': 6.3,
                    'f12': false,
                    'f13': 0,
                    'f14': 0,
                    'f15': '0',
                    'f16': 0,
                    'f19': 494,
                    'f20': '28.03.2023',
                }
            ]
        };
    },
    computed: {
        model2() {
            return this.tableData.filter(row => row.f12);
        },
        checkedRows() {
            return this.model2.length
        },
        totalSumRow() {
            const result = this.tableData.filter(row => row.f12);
            const totalSum = {
                f3: 0,
                f4: 0,
                f5: 0,
                f6: 100,
                f7: 0
            };
            for (const item of result) {
                totalSum.f3 += parseFloat(item.f3);
                totalSum.f5 += parseFloat(item.f5);
                totalSum.f6 += parseFloat(item.f6);
                totalSum.f7 += parseFloat(item.f7);
                totalSum.f8 += parseFloat(item.f8);
            }
            totalSum.f4 = ((3043 + totalSum.f3) / 3711 * 100).toFixed(1)
            return totalSum
        },
        totalSum() {
            const result = this.tableData.filter(row => row.f12);
            let sum = 0;
            for (const item of result) {
                sum += parseFloat(item.f16);
                item.f15 = (parseFloat(item.f16) / (parseFloat(item.f13)) * 100).toFixed(1);
            }
            return sum
        },
        usedSum() {
            const result = this.tableData.filter(row => row.f12);
            let sum = 0;
            for (const item of result) {
                sum += parseFloat(item.f16);
            }
            return (sum / 1000).toFixed(1);
        },
        remaindSum() {
            return this.limit - this.usedSum;
        }
    }
};
</script>

<style scoped>

</style>