<template>
    <b-container>
        <b-button variant="primary" href="#/demo-budget-execution/schools/schools/">Потребности</b-button>
        <b-button variant="primary" href="#/demo-budget-execution/schools/bip-schools/">БИП</b-button>
        <b-button variant="primary" href="#/demo-budget-execution/schools/bip-schools-modeling/">Моделирование</b-button>
        <iframe src="https://www.google.com/maps/d/u/0/embed?mid=11UJYs6hYSJL9Wo8ROJR9-5mWs3XnNJH-&ll=52.04392168434889%2C76.38030799338733&z=7" width="100%" height="800"></iframe>
    </b-container>
</template>

<script>
export default {
    name: 'SchoolsMap'
};
</script>

<style scoped>

</style>