import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/services/store';

import '../initialization';
import { accessor } from '../store';
import { EWidgetType, ISelectedIndicatorItems, TWidget, IWidgetChartMultipleAxes, IWidgetChartBars, IWidgetChartBudget, IWidgetChartModelData, IWidgetTextBars, IWidgetTextLines, IWidgetMeters, IWidgetChartLinearMarkers, IWidgetChart3dPie, IWidgetChartFlatPie, IWidgetChartRadiusPie, IWidgetChartStackColumn, IWidgetChartTreeMap, IWidgetChartActual, IWidgetChartModelLinear } from '../store/types';
import { assignGrow } from './common';

import CContentTextBars from './c-content-text-bars';
import CContentTextLines from './c-content-text-lines';
import CContentMeters from './c-content-meters';
import CContentLinearMarkers from './c-content-chart-linear-marker';
import CContentChartBars from './c-content-chart-bars';
import CContentChart3dPie from './c-content-chart-3d-pie';
import CContentChartFlatPie from './c-content-chart-flat-pie';
import CContentChartRadiusPie from './c-content-chart-radius-pie';
import CContentChartMultipleAxes from './c-content-chart-multiple-axes';
import CContentChartStackColumn from './c-content-chart-stack-column';
import CContentChartTreeMap from './c-content-chart-tree-map';
import CContentChartActual from './c-content-chart-actual';
import CContentChartGantt from './c-content-chart-gantt';
import CContentChartBudget from './c-content-chart-budget';
import CContentChartModelData from './c-content-chart-model-data';
import CContentModelLinear from './c-content-chart-model-linear';
import CContentCovid from './c-content-chart-covid/c-content-chart-covid.vue';
import CContentVaccination from './c-content-chart-vaccination/c-content-chart-vaccination.vue';
import CContentSoc from './c-content-chart-soc/c-content-chart-soc.vue';

import theme from '@/services/theme';

type IWidgets = IWidgetTextLines | IWidgetTextBars | IWidgetMeters | IWidgetChartLinearMarkers | IWidgetChart3dPie | IWidgetChartFlatPie | IWidgetChartRadiusPie | IWidgetChartStackColumn | IWidgetChartTreeMap | IWidgetChartActual | IWidgetChartModelData | IWidgetChartMultipleAxes | IWidgetChartBudget | IWidgetChartBars | IWidgetChartModelLinear;


@Component({
    components: {
        'c-content-text-bars': CContentTextBars,
        'c-content-text-lines': CContentTextLines,
        'c-content-meters': CContentMeters,
        'c-content-linear-marker': CContentLinearMarkers,
        'c-content-chart-bars': CContentChartBars,
        'c-content-chart-3d-pie': CContentChart3dPie,
        'c-content-chart-flat-pie': CContentChartFlatPie,
        'c-content-chart-radius-pie': CContentChartRadiusPie,
        'c-content-chart-multiple-axes': CContentChartMultipleAxes,
        'c-content-chart-stack-column': CContentChartStackColumn,
        'c-content-chart-tree-map': CContentChartTreeMap,
        'c-content-chart-actual': CContentChartActual,
        'c-content-chart-gantt': CContentChartGantt,
        'c-content-chart-budget': CContentChartBudget,
        'c-content-chart-model-data': CContentChartModelData,
        'c-content-chart-model-linear': CContentModelLinear,
        'c-content-chart-covid': CContentCovid,
        'c-content-chart-vaccination': CContentVaccination,
        'c-content-chart-soc': CContentSoc
    }
})
export default class CWidget extends Vue {
    public srcModal = '/img/light-theme/info_light.png';

    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;

    @Prop()
    public readonly redrawTrigger!: any;

    private redrawAddition = 0;

    public get actualRedrawTrigger(): any {
        return {
            redrawTrigger: this.redrawTrigger,
            redrawAddition: this.redrawAddition
        };
    }

    @Prop({
        type: Object,
        required: true
    })
    public readonly data!: TWidget;
    private commentModalShow = false;

    private widgetDownload = false;

    private chartAction = '';

    public srcSettings = '/img/light-theme/konstructor_light.png';

    public created() {
        this.$watch('themeDark', () => {
            this.srcModal = '/img/light-theme/info_light.png';

            if (this.themeDark) {
                this.srcModal = '/img/dark-theme/info_dark.png';
            }

            this.srcSettings = '/img/light-theme/konstructor_light.png';

            if (this.themeDark) {
                this.srcSettings = '/img/dark-theme/konstructor_dark.png';
            }
        });

        this.srcModal = '/img/light-theme/info_light.png';

        if (this.themeDark) {
            this.srcModal = '/img/dark-theme/info_dark.png';
        }

        this.srcSettings = '/img/light-theme/konstructor_light.png';

        if (this.themeDark) {
            this.srcSettings = '/img/dark-theme/konstructor_dark.png';
        }
    }

    public mounted() {
        setTimeout(() => {
            this.redrawAddition = Date.now();
        });
    }

    // переключение темы
    private get themeDark(): boolean {
        return theme.dark;
    }

    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }

    public get id(): number {
        return this.data.id;
    }

    public get title(): string {
        if ((this.data.title.trim().length === 0) && (this.singleSelectedItem !== null)) {
            return this.singleSelectedItem.shortIndicator;
        }

        return this.data.title;
    }

    public get indicatorKeys(): string[] {
        const data = this.data as IWidgets;
        const resArr: string[] = [];
        for (const indicatorConfig of data.indicatorConfigs) {
            resArr.push(indicatorConfig.indicator);
        }
        return resArr;
    }

    public get commentText(): string[] {
        const commentArr: string[] = [];

        this.indicatorKeys.forEach((indicatorKey) => {
            const selectedItems = accessor.selectedMap.get(indicatorKey);
            const growDatas = assignGrow(indicatorKey);

            if ((selectedItems !== undefined) && (growDatas !== undefined) && (growDatas.current !== undefined)) {
                let text = 'Показатель "' + selectedItems.indicator + '"';
                if (growDatas.current.periodStart !== undefined) {
                    text += this.getPeriodText(selectedItems.periodicity, growDatas.current.periodStart);
                }
                text += ' составляет ' + growDatas.current.fact + ' ' + growDatas.unit + '.';
                if (growDatas.growProc === 0) {
                    text += ' Изменение по сравнению с предыдущим периодом не наблюдается.';
                } else if (growDatas.growProc !== null) {
                    text += ' Наблюдается ';
                    if (growDatas.growProc > 0) {
                        text += ' рост на ' + growDatas.growProc + '% по сравнению с предыдущим аналогичным периодом.';
                    } else if (growDatas.growProc < 0) {
                        text += ' спад на ' + Math.abs(growDatas.growProc) + '% по сравнению с предыдущим аналогичным периодом.';
                    }
                }
                commentArr.push(text);
            }

            /* if (selectedItems !== undefined) {
                const selectedItem = selectedItems.items;
                const unit = selectedItems.unit;
                const unitLowerCased = ((unit !== null) ? unit.charAt(0).toLowerCase() + unit.slice(1) : null);

                if (selectedItems.withPlan) {
                    for (let i = selectedItem.length - 1; i > -1; i--) {
                        const current = selectedItem[i];
                        if (current !== undefined) {
                            if ((current.fact !== 0) && (current.plan !== 0)) {
                                const j = i - 1;
                                const previous = selectedItem[j];

                                let growIndicProc = null;
                                if (previous !== undefined) {
                                    growIndicProc = (((unitLowerCased === 'процент') || (unitLowerCased === '%'))
                                        ? Math.round(current.fact - previous.fact)
                                        : Math.round(((current.fact - previous.fact) / previous.fact) * 100));
                                }
                                let text = 'Показатель "' + selectedItems.indicator + '"';
                                if (current.periodStart !== undefined) {
                                    text += this.getPeriodText(selectedItems.periodicity, current.periodStart);
                                }
                                text += ' составляет ' + current.fact + ' ' + selectedItems.unit + '.';
                                if (growIndicProc === 0) {
                                    text += ' Изменение по сравнению с предыдущим периодом не наблюдается.';
                                } else if (growIndicProc !== null) {
                                    text += ' Наблюдается ';
                                    if (growIndicProc > 0) {
                                        text += ' рост на ' + growIndicProc + '% по сравнению с предыдущим аналогичным периодом.';
                                    } else if (growIndicProc < 0) {
                                        text += ' спад на ' + Math.abs(growIndicProc) + '% по сравнению с предыдущим аналогичным периодом.';
                                    }
                                }
                                commentArr.push(text);
                                break;
                            }
                        }
                    }
                } else {
                    const current = selectedItems.item;
                    if (current === undefined) {
                        return undefined;
                    }
                    const previous = selectedItems.previousItem;
                    let growIndicProc = null;
                    if (previous !== undefined) {
                        growIndicProc = (((unitLowerCased === 'процент') || (unitLowerCased === '%'))
                            ? Math.round(current.fact - previous.fact)
                            : Math.round(((current.fact - previous.fact) / previous.fact) * 100));
                    }
                    let text = 'Показатель "' + selectedItems.indicator + '"';
                    if (current.periodStart !== undefined) {
                        text += this.getPeriodText(selectedItems.periodicity, current.periodStart);
                    }
                    text += ' составляет ' + current.fact + ' ' + selectedItems.unit + '.';
                    if (growIndicProc === 0) {
                        text += ' Изменение по сравнению с предыдущим периодом не наблюдается.';
                    } else if (growIndicProc !== null) {
                        text += ' Наблюдается ';
                        if (growIndicProc > 0) {
                            text += ' рост на ' + growIndicProc + '% по сравнению с предыдущим аналогичным периодом.';
                        } else if (growIndicProc < 0) {
                            text += ' спад на ' + Math.abs(growIndicProc) + '% по сравнению с предыдущим аналогичным периодом.';
                        }
                    }
                    commentArr.push(text);
                }
            } */
            return commentArr;
        });
        return commentArr;
    }

    private getPeriodText(periodicity: string, periodDate: Date): string {
        // eslint-disable-next-line no-param-reassign
        periodicity = periodicity.toLowerCase();
        let text = '';
        switch (periodicity) {
            case 'год': {
                text += ' за ' + periodDate.getFullYear() + ' год';
                break;
            }
            case 'полугодие': {
                if (periodDate.getMonth() === 0) {
                    text += ' за 1 полугодие';
                } else {
                    text += ' за 2 полугодие';
                }
                break;
            }
            case 'квартал': case 'квартал с накоплением': {
                switch (periodDate.getMonth()) {
                    case 0: {
                        text += ' за 1 квартал';
                        break;
                    }
                    case 3: {
                        text += ' за 2 квартал';
                        break;
                    }
                    case 6: {
                        text += ' за 3 квартал';
                        break;
                    }
                    case 9: {
                        text += ' за 4 квартал';
                        break;
                    }
                    default: {
                        text += ' за не понятно какой квартал';
                    }
                }
                if (periodicity === 'квартал с накоплением') {
                    text += ' с накоплением';
                }
                text += ' ' + periodDate.getFullYear() + ' года';
                break;
            }
            case 'месяц': {
                text += ' за ' + Intl.DateTimeFormat(this.$i18n.locale, { month: 'long' }).format(periodDate);
                text += ' ' + periodDate.getFullYear() + ' года';
                break;
            }
            case 'месяц с накоплением': {
                text += ' за ' + Intl.DateTimeFormat(this.$i18n.locale, { month: 'long' }).format(periodDate) + ' с накоплением';
                text += ' ' + periodDate.getFullYear() + ' года';
                break;
            }
            default: {
                text += ' на ' + Intl.DateTimeFormat(this.$i18n.locale, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }).format(periodDate);
            }
        }
        return text;
    }

    public get type(): EWidgetType {
        return this.data.type;
    }

    public get singleSelectedItem(): ISelectedIndicatorItems | null {
        if (this.data.indicatorKeys.length === 1) {
            const result = accessor.selectedMap.get(this.data.indicatorKeys[0]);
            if (result !== undefined) {
                return result;
            }
        }
        return null;
    }

    public toSettings(id: number) {
        this.$router.push({ path: '/widget-yard', query: { widgetId: id.toString() } });
    }
}