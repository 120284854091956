






























































import Class from './socio';
export default Class;
