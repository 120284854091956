











































































































































import { Vue, Component } from 'vue-property-decorator';
import Highcharts from 'highcharts';


const useHtmlElement = (possibleEl: any, block: (el: HTMLElement) => void) => {
    if (possibleEl instanceof HTMLElement) {
        block(possibleEl);
    }
};

const renderHighcharts = (possibleEl: any, options: Highcharts.Options) => {
    useHtmlElement(possibleEl, el => Highcharts.chart(el, options));
};

const renderColumnHighcharts = (possibleEl: any, options: Highcharts.Options) => {
    const extendedOptions: Highcharts.Options = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: ''
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        xAxis: {
            categories: ['']
        },
        exporting: {
            enabled: false
        }
    };

    Object.getOwnPropertyNames(options).forEach(key => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        extendedOptions[key] = options[key];
    });

    renderHighcharts(possibleEl, extendedOptions);
};


@Component
export default class EfficiencyMark extends Vue {
    private dateText: string = ((): string => {
        const date = new Date();

        let day = String(date.getDate());
        while (day.length < 2) {
            day = '0' + day;
        }

        let month = String(date.getMonth() + 1);
        while (month.length < 2) {
            month = '0' + month;
        }

        let year = String(date.getFullYear());
        while (year.length < 4) {
            year = '0' + year;
        }

        return `${day}.${month}.${year}`;
    })();


    private renderExecutionOfIncome() {
        renderColumnHighcharts(this.$refs.executionOfIncome, {
            series: [
                {
                    type: 'column',
                    name: 'План',
                    data: [152]
                },
                {
                    type: 'column',
                    name: 'Факт',
                    data: [147]
                }
            ]
        });
    }

    private renderExecutionOfOutcome() {
        renderColumnHighcharts(this.$refs.executionOfOutcome, {
            series: [
                {
                    type: 'column',
                    name: 'План',
                    data: [135]
                },
                {
                    type: 'column',
                    name: 'Факт',
                    data: [130]
                }
            ]
        });
    }

    private mounted() {
        this.renderExecutionOfIncome();
        this.renderExecutionOfOutcome();
    }
}
