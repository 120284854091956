<template>
    <b-container fluid="true">
        <div class="section-title budget">Определение потребностей</div>
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Целевой индикатор"
                        label="Целевой индикатор"
                    >
                        <b-form-select v-model="selected1" :options="options1"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontalq"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Город/район"
                        label="Город/район"
                    >
                        <b-form-select v-model="selected2" :options="options2"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="text-right">
                <b-button variant="primary" href="#/demo-budget-execution/schools/schools-map/">Открыть на карте</b-button>
                <b-button variant="primary" class="ml-3" href="#/demo-budget-execution/schools/bip-schools/">БИП</b-button>
            </div>
        </b-card>
        <b-table :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" :items="tableData" :filter="selected2">
            <template #cell(f1)="data">
                <div class="text-center">
                    {{ data.item.f1 }}
                </div>
            </template>
            <template #cell(f4)="data">
                <div class="text-right">
                    {{ data.item.f4 }}
                </div>
            </template>
            <template #cell(f5)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f6)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
        </b-table>
    </b-container>
</template>

<script>
export default {
    name: 'Roads',
    data() {
        return {
            options1: [
                {value: 0, text: 'Все'},
                {value: 1, text: 'Снижение дефицита ученических мест в г. Павлодаре (специфичный), кол-во мест'}
            ],
            selected1: 0,
            options2: [
                {value: null, text: 'Все'},
                {value: 'Актогайский р-н', text: 'Актогайский р-н'},
                {value: 'Баянаульский р-н', text: 'Баянаульский р-н'},
                {value: 'Железинский р-н', text: 'Железинский р-н'},
                {value: 'Иртышский р-н', text: 'Иртышский р-н'},
                {value: 'Теренкольский р-н', text: 'Теренкольский р-н'},
                {value: 'Аккулинский р-н', text: 'Аккулинский р-н'},
                {value: 'Майский р-н', text: 'Майский р-н'},
                {value: 'Павлодарский р-н', text: 'Павлодарский р-н'},
                {value: 'Успенский р-н', text: 'Успенский р-н'},
                {value: 'Щербактинский р-н', text: 'Щербактинский р-н'},
                {value: 'г.Аксу', text: 'г. Аксу'},
                {value: 'г.Павлодар', text: 'г. Павлодар'},
                {value: 'г.Экибастуз', text: 'г. Экибастуз'},
            ],
            selected2: 0,
            filter: null,
            tableFields: [
                {
                    key: 'f1',
                    label: '№'
                },
                {
                    key: 'f2',
                    label: 'город/район'
                },
                {
                    key: 'f3',
                    label: 'Наименование'
                },
                {
                    key: 'f4',
                    label: 'Проектная мощность'
                },
                {
                    key: 'f5',
                    label: 'Количество детей'
                },
                {
                    key: 'f6',
                    label: 'Профицит/Дефицит'
                }
            ],
            tableData: [
                {
                    'f1': 1,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №34"',
                    'f4': 1030,
                    'f5': 1526,
                    'f6': -496
                },
                {
                    'f1': 2,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ им. К. Макпалеева"',
                    'f4': 1640,
                    'f5': 1418,
                    'f6': 222
                },
                {
                    'f1': 3,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "Лицей-интернат "БІЛІМ-ИННОВАЦИЯ" для одаренных девочек" ',
                    'f4': 250,
                    'f5': 274,
                    'f6': -24
                },
                {
                    'f1': 4,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №2"',
                    'f4': 960,
                    'f5': 971,
                    'f6': -11
                },
                {
                    'f1': 5,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "Лицей-интернат "БІЛІМ-ИННОВАЦИЯ" для одаренных юношей" ',
                    'f4': 250,
                    'f5': 260,
                    'f6': -10
                },
                {
                    'f1': 6,
                    'f2': 'г.Павлодар',
                    'f3': 'ТОО "Лингва" школа',
                    'f4': 150,
                    'f5': 49,
                    'f6': 101
                },
                {
                    'f1': 7,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №30"',
                    'f4': 420,
                    'f5': 565,
                    'f6': -145
                },
                {
                    'f1': 8,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №13"',
                    'f4': 182,
                    'f5': 336,
                    'f6': -154
                },
                {
                    'f1': 9,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ им. К. Бекхожина"',
                    'f4': 1230,
                    'f5': 726,
                    'f6': 504
                },
                {
                    'f1': 10,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №41"',
                    'f4': 710,
                    'f5': 1631,
                    'f6': -921
                },
                {
                    'f1': 11,
                    'f2': 'г.Павлодар',
                    'f3': 'ГУ "Комплекс "Музыкальный колледж - музыкальная школа-интернат для одаренных детей"',
                    'f4': 293,
                    'f5': 272,
                    'f6': 21
                },
                {
                    'f1': 12,
                    'f2': 'г.Павлодар',
                    'f3': 'ГУ "Специализированная школа "Жас дарын"',
                    'f4': 280,
                    'f5': 405,
                    'f6': -125
                },
                {
                    'f1': 13,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №27"',
                    'f4': 924,
                    'f5': 801,
                    'f6': 123
                },
                {
                    'f1': 14,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №26"',
                    'f4': 950,
                    'f5': 1083,
                    'f6': -133
                },
                {
                    'f1': 15,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №39"',
                    'f4': 1560,
                    'f5': 2202,
                    'f6': -642
                },
                {
                    'f1': 16,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №37"',
                    'f4': 1200,
                    'f5': 1163,
                    'f6': 37
                },
                {
                    'f1': 17,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ «СОШ им. М. Ауезова»',
                    'f4': 1296,
                    'f5': 1761,
                    'f6': -465
                },
                {
                    'f1': 18,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №17"',
                    'f4': 1176,
                    'f5': 1455,
                    'f6': -279
                },
                {
                    'f1': 19,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "ШЛ №16"',
                    'f4': 1200,
                    'f5': 1570,
                    'f6': -370
                },
                {
                    'f1': 20,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №28"',
                    'f4': 750,
                    'f5': 987,
                    'f6': -237
                },
                {
                    'f1': 21,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "ШЛ №20"',
                    'f4': 200,
                    'f5': 142,
                    'f6': 58
                },
                {
                    'f1': 22,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №7"',
                    'f4': 800,
                    'f5': 588,
                    'f6': 212
                },
                {
                    'f1': 23,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №15"',
                    'f4': 400,
                    'f5': 298,
                    'f6': 102
                },
                {
                    'f1': 24,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШГ №9"',
                    'f4': 964,
                    'f5': 2215,
                    'f6': -1251
                },
                {
                    'f1': 25,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №21"',
                    'f4': 1200,
                    'f5': 1728,
                    'f6': -528
                },
                {
                    'f1': 26,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №29"',
                    'f4': 1200,
                    'f5': 2187,
                    'f6': -987
                },
                {
                    'f1': 27,
                    'f2': 'г.Павлодар',
                    'f3': 'ГУ "Областной многопрофильный лицей-интернат для одаренных детей" ',
                    'f4': 300,
                    'f5': 283,
                    'f6': 17
                },
                {
                    'f1': 28,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №18"',
                    'f4': 1320,
                    'f5': 1181,
                    'f6': 139
                },
                {
                    'f1': 29,
                    'f2': 'г.Павлодар',
                    'f3': 'ГУ "Областная казахская гимназия-интернат для одаренных детей им. И.Алтынсарина',
                    'f4': 500,
                    'f5': 444,
                    'f6': 56
                },
                {
                    'f1': 30,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №6"',
                    'f4': 756,
                    'f5': 567,
                    'f6': 189
                },
                {
                    'f1': 31,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "Гимназия для одаренных детей имени Абая"',
                    'f4': 500,
                    'f5': 660,
                    'f6': -160
                },
                {
                    'f1': 32,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №43"',
                    'f4': 960,
                    'f5': 1300,
                    'f6': -340
                },
                {
                    'f1': 33,
                    'f2': 'г.Павлодар',
                    'f3': 'ГУ "Школа-лицей № 8 для одаренных детей"',
                    'f4': 1200,
                    'f5': 876,
                    'f6': 324
                },
                {
                    'f1': 34,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №24"',
                    'f4': 980,
                    'f5': 1834,
                    'f6': -854
                },
                {
                    'f1': 35,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СШ №25"',
                    'f4': 1078,
                    'f5': 1872,
                    'f6': -794
                },
                {
                    'f1': 36,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №14"',
                    'f4': 1140,
                    'f5': 842,
                    'f6': 298
                },
                {
                    'f1': 37,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №36"',
                    'f4': 1200,
                    'f5': 1450,
                    'f6': -250
                },
                {
                    'f1': 38,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №1"',
                    'f4': 960,
                    'f5': 478,
                    'f6': 482
                },
                {
                    'f1': 39,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №5"',
                    'f4': 1320,
                    'f5': 1349,
                    'f6': -29
                },
                {
                    'f1': 40,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ им. Б. Момышулы"',
                    'f4': 1568,
                    'f5': 1877,
                    'f6': -309
                },
                {
                    'f1': 41,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №19"',
                    'f4': 1050,
                    'f5': 1050,
                    'f6': 0
                },
                {
                    'f1': 42,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №23"',
                    'f4': 250,
                    'f5': 302,
                    'f6': -52
                },
                {
                    'f1': 43,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №35"',
                    'f4': 1200,
                    'f5': 910,
                    'f6': 290
                },
                {
                    'f1': 44,
                    'f2': 'г.Павлодар',
                    'f3': 'ГУ "Гимназия №3 для одаренных детей" ',
                    'f4': 420,
                    'f5': 579,
                    'f6': -159
                },
                {
                    'f1': 45,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "Областная специализированная военная школа-интернат" ',
                    'f4': 100,
                    'f5': 100,
                    'f6': 0
                },
                {
                    'f1': 46,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №11"',
                    'f4': 975,
                    'f5': 1265,
                    'f6': -290
                },
                {
                    'f1': 47,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СШ им. М. Алимбаева"',
                    'f4': 600,
                    'f5': 346,
                    'f6': 254
                },
                {
                    'f1': 48,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №40"',
                    'f4': 420,
                    'f5': 980,
                    'f6': -560
                },
                {
                    'f1': 49,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "Кенжекольская СОШ"',
                    'f4': 600,
                    'f5': 1230,
                    'f6': -630
                },
                {
                    'f1': 50,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "ООШ №32"',
                    'f4': 190,
                    'f5': 231,
                    'f6': -41
                },
                {
                    'f1': 51,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №33"',
                    'f4': 960,
                    'f5': 888,
                    'f6': 72
                },
                {
                    'f1': 52,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "СОШ №31"',
                    'f4': 320,
                    'f5': 167,
                    'f6': 153
                },
                {
                    'f1': 53,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "ООШ №38"',
                    'f4': 100,
                    'f5': 233,
                    'f6': -133
                },
                {
                    'f1': 54,
                    'f2': 'г.Павлодар',
                    'f3': 'КГУ "Жетекшинская СОШ"',
                    'f4': 160,
                    'f5': 413,
                    'f6': -253
                },
                {
                    'f1': 1,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ имени К. Шулембаева"',
                    'f4': 1320,
                    'f5': 465,
                    'f6': 855
                },
                {
                    'f1': 2,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "Начальная школа № 5"',
                    'f4': 40,
                    'f5': 20,
                    'f6': 20
                },
                {
                    'f1': 3,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ  "КСШ № 1"',
                    'f4': 1320,
                    'f5': 1216,
                    'f6': 104
                },
                {
                    'f1': 4,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ № 8"',
                    'f4': 1568,
                    'f5': 1150,
                    'f6': 418
                },
                {
                    'f1': 5,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "Школа-лицей города Аксу"',
                    'f4': 340,
                    'f5': 346,
                    'f6': -6
                },
                {
                    'f1': 6,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ № 4"',
                    'f4': 960,
                    'f5': 1228,
                    'f6': -268
                },
                {
                    'f1': 7,
                    'f2': 'г.Аксу',
                    'f3': 'ГУ Специализированная гимназия для одаренных детей',
                    'f4': 400,
                    'f5': 355,
                    'f6': 45
                },
                {
                    'f1': 8,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СОШ №2"',
                    'f4': 1200,
                    'f5': 1525,
                    'f6': -325
                },
                {
                    'f1': 9,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "Средняя школа № 7 города Аксу" ',
                    'f4': 1300,
                    'f5': 1088,
                    'f6': 212
                },
                {
                    'f1': 10,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ имени М. Кабылбекова"',
                    'f4': 320,
                    'f5': 170,
                    'f6': 150
                },
                {
                    'f1': 11,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ села Айнаколь"',
                    'f4': 640,
                    'f5': 170,
                    'f6': 470
                },
                {
                    'f1': 12,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ имени К. Камзина"',
                    'f4': 640,
                    'f5': 276,
                    'f6': 364
                },
                {
                    'f1': 13,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "Достыкская средняя школа"',
                    'f4': 390,
                    'f5': 127,
                    'f6': 263
                },
                {
                    'f1': 14,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ села Береке"',
                    'f4': 480,
                    'f5': 345,
                    'f6': 135
                },
                {
                    'f1': 15,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ имени Бауыржана Момышулы"',
                    'f4': 198,
                    'f5': 183,
                    'f6': 15
                },
                {
                    'f1': 16,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "Основная школа станции Спутник"',
                    'f4': 120,
                    'f5': 38,
                    'f6': 82
                },
                {
                    'f1': 17,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "Средняя школа имени Ю. Гагарина"',
                    'f4': 640,
                    'f5': 360,
                    'f6': 280
                },
                {
                    'f1': 18,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "ОШ села Сольветка"',
                    'f4': 100,
                    'f5': 35,
                    'f6': 65
                },
                {
                    'f1': 19,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ села Уштерек"',
                    'f4': 450,
                    'f5': 290,
                    'f6': 160
                },
                {
                    'f1': 20,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ " Жамбылская средняя школа"',
                    'f4': 392,
                    'f5': 197,
                    'f6': 195
                },
                {
                    'f1': 21,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ села Енбек"',
                    'f4': 370,
                    'f5': 195,
                    'f6': 175
                },
                {
                    'f1': 22,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ имени Донентаева"',
                    'f4': 2040,
                    'f5': 120,
                    'f6': 1920
                },
                {
                    'f1': 23,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ села Калкаман"',
                    'f4': 300,
                    'f5': 470,
                    'f6': -170
                },
                {
                    'f1': 24,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ села Акжол"',
                    'f4': 420,
                    'f5': 101,
                    'f6': 319
                },
                {
                    'f1': 25,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "СШ села Кызылжар"',
                    'f4': 480,
                    'f5': 312,
                    'f6': 168
                },
                {
                    'f1': 26,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ  "СШ села Сарышыганак"',
                    'f4': 624,
                    'f5': 90,
                    'f6': 534
                },
                {
                    'f1': 27,
                    'f2': 'г.Аксу',
                    'f3': 'КГУ "НШ села Суатколь"',
                    'f4': 40,
                    'f5': 10,
                    'f6': 30
                },
                {
                    'f1': 1,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Школа-гимназия № 24 многопрофильного направления"',
                    'f4': 1296,
                    'f5': 961,
                    'f6': 335
                },
                {
                    'f1': 2,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Областной многопрофильный полиязычный лицей-интернат для одаренных детей" ',
                    'f4': 500,
                    'f5': 238,
                    'f6': 262
                },
                {
                    'f1': 3,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ СОШ № 33',
                    'f4': 640,
                    'f5': 678,
                    'f6': -38
                },
                {
                    'f1': 4,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ Школа-лицей №36',
                    'f4': 1200,
                    'f5': 1050,
                    'f6': 150
                },
                {
                    'f1': 5,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Школа - лицей № 6"',
                    'f4': 964,
                    'f5': 1004,
                    'f6': -40
                },
                {
                    'f1': 6,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Казахская гимназия для девочек № 25"',
                    'f4': 200,
                    'f5': 426,
                    'f6': -226
                },
                {
                    'f1': 7,
                    'f2': 'г.Экибастуз',
                    'f3': 'ГУ "Специализированная школа-лицей № 1 для одаренных детей"',
                    'f4': 920,
                    'f5': 486,
                    'f6': 434
                },
                {
                    'f1': 8,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 9"',
                    'f4': 1496,
                    'f5': 1239,
                    'f6': 257
                },
                {
                    'f1': 9,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 13"',
                    'f4': 1568,
                    'f5': 873,
                    'f6': 695
                },
                {
                    'f1': 10,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Школа-гимназия № 35"',
                    'f4': 600,
                    'f5': 748,
                    'f6': -148
                },
                {
                    'f1': 11,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Школа - гимназия № 7"',
                    'f4': 964,
                    'f5': 965,
                    'f6': -1
                },
                {
                    'f1': 12,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 21"',
                    'f4': 1292,
                    'f5': 808,
                    'f6': 484
                },
                {
                    'f1': 13,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Школа-гимназия № 26"',
                    'f4': 1296,
                    'f5': 1191,
                    'f6': 105
                },
                {
                    'f1': 14,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 5"',
                    'f4': 960,
                    'f5': 580,
                    'f6': 380
                },
                {
                    'f1': 15,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 2 имени Абая Кунанбаева"',
                    'f4': 1568,
                    'f5': 715,
                    'f6': 853
                },
                {
                    'f1': 16,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 18"',
                    'f4': 1568,
                    'f5': 1321,
                    'f6': 247
                },
                {
                    'f1': 17,
                    'f2': 'г.Экибастуз',
                    'f3': 'ГУ "Специализированная ОШ "Зерде" ',
                    'f4': 426,
                    'f5': 411,
                    'f6': 15
                },
                {
                    'f1': 18,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Школа - гимназия № 22 им. С. Торайгырова "',
                    'f4': 504,
                    'f5': 739,
                    'f6': -235
                },
                {
                    'f1': 19,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 23"',
                    'f4': 1600,
                    'f5': 867,
                    'f6': 733
                },
                {
                    'f1': 20,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 10"',
                    'f4': 1568,
                    'f5': 1501,
                    'f6': 67
                },
                {
                    'f1': 21,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 11"',
                    'f4': 1568,
                    'f5': 1243,
                    'f6': 325
                },
                {
                    'f1': 22,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 4"',
                    'f4': 960,
                    'f5': 621,
                    'f6': 339
                },
                {
                    'f1': 23,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 12"',
                    'f4': 1568,
                    'f5': 738,
                    'f6': 830
                },
                {
                    'f1': 24,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 17"',
                    'f4': 1176,
                    'f5': 907,
                    'f6': 269
                },
                {
                    'f1': 25,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 16"',
                    'f4': 1176,
                    'f5': 371,
                    'f6': 805
                },
                {
                    'f1': 26,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Аккольская СОШ"',
                    'f4': 360,
                    'f5': 91,
                    'f6': 269
                },
                {
                    'f1': 27,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "ООШ им. К. Алгамбарова"',
                    'f4': 120,
                    'f5': 62,
                    'f6': 58
                },
                {
                    'f1': 28,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Байетская СОШ"',
                    'f4': 200,
                    'f5': 141,
                    'f6': 59
                },
                {
                    'f1': 29,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ Атыгайская СОШ',
                    'f4': 150,
                    'f5': 113,
                    'f6': 37
                },
                {
                    'f1': 30,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Экибастузская СОШ"',
                    'f4': 480,
                    'f5': 77,
                    'f6': 403
                },
                {
                    'f1': 31,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Майкаинская СОШ"',
                    'f4': 162,
                    'f5': 92,
                    'f6': 70
                },
                {
                    'f1': 32,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Комсомольская СОШ"',
                    'f4': 120,
                    'f5': 77,
                    'f6': 43
                },
                {
                    'f1': 33,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ им. Алькея Маргулана"',
                    'f4': 280,
                    'f5': 87,
                    'f6': 193
                },
                {
                    'f1': 34,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ № 14"',
                    'f4': 200,
                    'f5': 111,
                    'f6': 89
                },
                {
                    'f1': 35,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "СОШ Бескауга"',
                    'f4': 464,
                    'f5': 62,
                    'f6': 402
                },
                {
                    'f1': 36,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Кудайкольская СОШ"',
                    'f4': 464,
                    'f5': 64,
                    'f6': 400
                },
                {
                    'f1': 37,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Карасорская СОШ"',
                    'f4': 192,
                    'f5': 41,
                    'f6': 151
                },
                {
                    'f1': 38,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Олентинская ООШ"',
                    'f4': 464,
                    'f5': 43,
                    'f6': 421
                },
                {
                    'f1': 39,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Саргамысская СОШ"',
                    'f4': 320,
                    'f5': 80,
                    'f6': 240
                },
                {
                    'f1': 40,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ СОШ № 28 им. Абая',
                    'f4': 420,
                    'f5': 547,
                    'f6': -127
                },
                {
                    'f1': 41,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Торт - Кудукская СОШ"',
                    'f4': 240,
                    'f5': 30,
                    'f6': 210
                },
                {
                    'f1': 42,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ "Бозшакульская ООШ"',
                    'f4': 264,
                    'f5': 44,
                    'f6': 220
                },
                {
                    'f1': 43,
                    'f2': 'г.Экибастуз',
                    'f3': 'КГУ Шидертинская СОШ',
                    'f4': 600,
                    'f5': 533,
                    'f6': 67
                },
                {
                    'f1': 1,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  ОСШ им. М. Кайырбаева  ',
                    'f4': 960,
                    'f5': 449,
                    'f6': 511
                },
                {
                    'f1': 2,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  ОСШ им. Абая',
                    'f4': 520,
                    'f5': 337,
                    'f6': 183
                },
                {
                    'f1': 3,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ   ОСШ имени К.Идрисова ',
                    'f4': 640,
                    'f5': 88,
                    'f6': 552
                },
                {
                    'f1': 4,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  Отеская НШ',
                    'f4': 30,
                    'f5': 15,
                    'f6': 15
                },
                {
                    'f1': 5,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  Енбекшинская ОШ',
                    'f4': 360,
                    'f5': 35,
                    'f6': 325
                },
                {
                    'f1': 6,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ   Жалаулинская ОСШ им. Н. Алшинова',
                    'f4': 350,
                    'f5': 75,
                    'f6': 275
                },
                {
                    'f1': 7,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  Балтасапская ОШ',
                    'f4': 80,
                    'f5': 39,
                    'f6': 41
                },
                {
                    'f1': 8,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ Жалаулинская НШ',
                    'f4': 30,
                    'f5': 10,
                    'f6': 20
                },
                {
                    'f1': 9,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ ОСШ имени Естая ',
                    'f4': 540,
                    'f5': 56,
                    'f6': 484
                },
                {
                    'f1': 10,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  Шиликтинская ОШ',
                    'f4': 80,
                    'f5': 18,
                    'f6': 62
                },
                {
                    'f1': 11,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  ОШ  имени Х.Шаяхметова ',
                    'f4': 96,
                    'f5': 30,
                    'f6': 66
                },
                {
                    'f1': 12,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ    Караобинская  ОСШ',
                    'f4': 450,
                    'f5': 115,
                    'f6': 335
                },
                {
                    'f1': 13,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  Шидертинская ОШ',
                    'f4': 365,
                    'f5': 34,
                    'f6': 331
                },
                {
                    'f1': 14,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  ОСШ  имени Тленшина ',
                    'f4': 624,
                    'f5': 167,
                    'f6': 457
                },
                {
                    'f1': 15,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ Жамбылская ОШ',
                    'f4': 140,
                    'f5': 22,
                    'f6': 118
                },
                {
                    'f1': 16,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ   ОСШ   имени Муткенова ',
                    'f4': 640,
                    'f5': 104,
                    'f6': 536
                },
                {
                    'f1': 17,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ Ленинская ОШ',
                    'f4': 100,
                    'f5': 22,
                    'f6': 78
                },
                {
                    'f1': 18,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  Жанатапская НШ',
                    'f4': 40,
                    'f5': 13,
                    'f6': 27
                },
                {
                    'f1': 19,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ ОСШ  им. Ныгманова ',
                    'f4': 100,
                    'f5': 71,
                    'f6': 29
                },
                {
                    'f1': 20,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ   Приреченская ОСШ',
                    'f4': 350,
                    'f5': 85,
                    'f6': 265
                },
                {
                    'f1': 21,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ    Агрономийская  ОСШ',
                    'f4': 464,
                    'f5': 89,
                    'f6': 375
                },
                {
                    'f1': 22,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ Харьковская  ОСШ',
                    'f4': 320,
                    'f5': 69,
                    'f6': 251
                },
                {
                    'f1': 23,
                    'f2': 'Актогайский р-н',
                    'f3': 'КГУ  Шолаксорская ОСШ',
                    'f4': 320,
                    'f5': 48,
                    'f6': 272
                },
                {
                    'f1': 1,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Баянаульская СОШ №2 ',
                    'f4': 500,
                    'f5': 373,
                    'f6': 127
                },
                {
                    'f1': 2,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ СОШ им. З. Акишева ',
                    'f4': 640,
                    'f5': 353,
                    'f6': 287
                },
                {
                    'f1': 3,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Средняя общеобразовательная школа-интернат им. Ш.Айманова ',
                    'f4': 600,
                    'f5': 477,
                    'f6': 123
                },
                {
                    'f1': 4,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  СОШ им. Д. Рахметова ',
                    'f4': 310,
                    'f5': 106,
                    'f6': 204
                },
                {
                    'f1': 5,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Каражарская  ООШ',
                    'f4': 60,
                    'f5': 57,
                    'f6': 3
                },
                {
                    'f1': 6,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  СОШ им. Ж. Агадилова ',
                    'f4': 464,
                    'f5': 174,
                    'f6': 290
                },
                {
                    'f1': 7,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ СОШ им. Машхур Жусуп Копеева',
                    'f4': 150,
                    'f5': 99,
                    'f6': 51
                },
                {
                    'f1': 8,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  СОШ им. Б.Хайдарова ',
                    'f4': 460,
                    'f5': 194,
                    'f6': 266
                },
                {
                    'f1': 9,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Жайминская СОШ',
                    'f4': 100,
                    'f5': 79,
                    'f6': 21
                },
                {
                    'f1': 10,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  СОШ им. Алкея Маргулана ',
                    'f4': 530,
                    'f5': 111,
                    'f6': 419
                },
                {
                    'f1': 11,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ Егиндыбулакская СОШ',
                    'f4': 124,
                    'f5': 80,
                    'f6': 44
                },
                {
                    'f1': 12,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  СОШ им. К. Кеменгерова ',
                    'f4': 464,
                    'f5': 115,
                    'f6': 349
                },
                {
                    'f1': 13,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Шоманкольская начальная общеобразовательная школа ',
                    'f4': 20,
                    'f5': 13,
                    'f6': 7
                },
                {
                    'f1': 14,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Первомайская начальная общеобразовательная школа ',
                    'f4': 40,
                    'f5': 7,
                    'f6': 33
                },
                {
                    'f1': 15,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ СОШ им. Ж.Аймаутова ',
                    'f4': 220,
                    'f5': 113,
                    'f6': 107
                },
                {
                    'f1': 16,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ Лекерская ООШ',
                    'f4': 80,
                    'f5': 66,
                    'f6': 14
                },
                {
                    'f1': 17,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Майкаинская СОШ №1 ',
                    'f4': 420,
                    'f5': 723,
                    'f6': -303
                },
                {
                    'f1': 18,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Майкаинская СОШ №2 ',
                    'f4': 964,
                    'f5': 684,
                    'f6': 280
                },
                {
                    'f1': 19,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Ушкулунская ООШ',
                    'f4': 70,
                    'f5': 34,
                    'f6': 36
                },
                {
                    'f1': 20,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Шадринская СОШ',
                    'f4': 170,
                    'f5': 120,
                    'f6': 50
                },
                {
                    'f1': 21,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Учебно-воспитательный комплекс школа-детский сад им. К. Сатпаева ',
                    'f4': 198,
                    'f5': 118,
                    'f6': 80
                },
                {
                    'f1': 22,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  СОШ им. С.Торайгырова ',
                    'f4': 245,
                    'f5': 142,
                    'f6': 103
                },
                {
                    'f1': 23,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ СОШ им. Е. Бекмаханова ',
                    'f4': 210,
                    'f5': 118,
                    'f6': 92
                },
                {
                    'f1': 24,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Акшийская ООШ',
                    'f4': 80,
                    'f5': 68,
                    'f6': 12
                },
                {
                    'f1': 25,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Служонская СОШ',
                    'f4': 104,
                    'f5': 37,
                    'f6': 67
                },
                {
                    'f1': 26,
                    'f2': 'Баянаульский р-н',
                    'f3': 'КГУ  Шоптыкольская СОШ',
                    'f4': 120,
                    'f5': 60,
                    'f6': 60
                },
                {
                    'f1': 1,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ ОСШ №1 ',
                    'f4': 980,
                    'f5': 302,
                    'f6': 678
                },
                {
                    'f1': 2,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ ОСШ № 2 ',
                    'f4': 704,
                    'f5': 355,
                    'f6': 349
                },
                {
                    'f1': 3,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Общеобразовательная средняя школа №3 ',
                    'f4': 198,
                    'f5': 164,
                    'f6': 34
                },
                {
                    'f1': 4,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Захаровская ООШ',
                    'f4': 192,
                    'f5': 32,
                    'f6': 160
                },
                {
                    'f1': 5,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ ООШ села Аккаин ',
                    'f4': 120,
                    'f5': 17,
                    'f6': 103
                },
                {
                    'f1': 6,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Моисеевская ООШ',
                    'f4': 75,
                    'f5': 53,
                    'f6': 22
                },
                {
                    'f1': 7,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Пятерыжская ООШ',
                    'f4': 100,
                    'f5': 32,
                    'f6': 68
                },
                {
                    'f1': 8,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Березовская ООШ',
                    'f4': 480,
                    'f5': 37,
                    'f6': 443
                },
                {
                    'f1': 9,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Жолтаптыкская ООШ',
                    'f4': 90,
                    'f5': 16,
                    'f6': 74
                },
                {
                    'f1': 10,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Алакольская ОСШ',
                    'f4': 320,
                    'f5': 114,
                    'f6': 206
                },
                {
                    'f1': 11,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Башмачинская ОСШ',
                    'f4': 420,
                    'f5': 83,
                    'f6': 337
                },
                {
                    'f1': 12,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Абайская ООШ',
                    'f4': 80,
                    'f5': 7,
                    'f6': 73
                },
                {
                    'f1': 13,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Береговая ООШ',
                    'f4': 192,
                    'f5': 39,
                    'f6': 153
                },
                {
                    'f1': 14,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Кузьминская ООШ',
                    'f4': 192,
                    'f5': 6,
                    'f6': 186
                },
                {
                    'f1': 15,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ ОСШ им. Ч. Валиханова ',
                    'f4': 320,
                    'f5': 84,
                    'f6': 236
                },
                {
                    'f1': 16,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Веселорощинская ОСШ',
                    'f4': 480,
                    'f5': 123,
                    'f6': 357
                },
                {
                    'f1': 17,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Славяновская ООШ',
                    'f4': 90,
                    'f5': 9,
                    'f6': 81
                },
                {
                    'f1': 18,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Енбекшинская ООШ',
                    'f4': 464,
                    'f5': 49,
                    'f6': 415
                },
                {
                    'f1': 19,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ ОСШ села Жаңа жұлдыз ',
                    'f4': 464,
                    'f5': 151,
                    'f6': 313
                },
                {
                    'f1': 20,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Ескаринская ОСШ',
                    'f4': 120,
                    'f5': 30,
                    'f6': 90
                },
                {
                    'f1': 21,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Екишокская ООШ',
                    'f4': 75,
                    'f5': 25,
                    'f6': 50
                },
                {
                    'f1': 22,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Лесная ОСШ',
                    'f4': 640,
                    'f5': 49,
                    'f6': 591
                },
                {
                    'f1': 23,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Крупская ООШ',
                    'f4': 64,
                    'f5': 31,
                    'f6': 33
                },
                {
                    'f1': 24,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Михайловская СОШ',
                    'f4': 640,
                    'f5': 121,
                    'f6': 519
                },
                {
                    'f1': 25,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Мынкульская ООШ',
                    'f4': 80,
                    'f5': 35,
                    'f6': 45
                },
                {
                    'f1': 26,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Будёновская ООШ',
                    'f4': 108,
                    'f5': 25,
                    'f6': 83
                },
                {
                    'f1': 27,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Новомирская ОСШ',
                    'f4': 320,
                    'f5': 60,
                    'f6': 260
                },
                {
                    'f1': 28,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Озерная ООШ',
                    'f4': 480,
                    'f5': 25,
                    'f6': 455
                },
                {
                    'f1': 29,
                    'f2': 'Железинский  р-н',
                    'f3': 'КГУ Прииртышская ОСШ им. Т.П. Праслова ',
                    'f4': 464,
                    'f5': 106,
                    'f6': 358
                },
                {
                    'f1': 1,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ «Иртышская СОШ №2»',
                    'f4': 360,
                    'f5': 342,
                    'f6': 18
                },
                {
                    'f1': 2,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Иртышская СОШ №1"',
                    'f4': 960,
                    'f5': 305,
                    'f6': 655
                },
                {
                    'f1': 3,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ «Иртышская СОШ №3»',
                    'f4': 484,
                    'f5': 318,
                    'f6': 166
                },
                {
                    'f1': 4,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ «Иртышская СОШ №4»',
                    'f4': 198,
                    'f5': 330,
                    'f6': -132
                },
                {
                    'f1': 5,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Абайская СОШ"',
                    'f4': 369,
                    'f5': 109,
                    'f6': 260
                },
                {
                    'f1': 6,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Агашорынская СОШ"',
                    'f4': 460,
                    'f5': 118,
                    'f6': 342
                },
                {
                    'f1': 7,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ «Амангельдинская СОШ»',
                    'f4': 466,
                    'f5': 50,
                    'f6': 416
                },
                {
                    'f1': 8,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Ленинская СОШ"',
                    'f4': 464,
                    'f5': 35,
                    'f6': 429
                },
                {
                    'f1': 9,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "М.Горьковская СОШ"',
                    'f4': 150,
                    'f5': 80,
                    'f6': 70
                },
                {
                    'f1': 10,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "СОШ им.Исы Байзакова "',
                    'f4': 117,
                    'f5': 29,
                    'f6': 88
                },
                {
                    'f1': 11,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ «Каракудукская СОШ»',
                    'f4': 365,
                    'f5': 69,
                    'f6': 296
                },
                {
                    'f1': 12,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ Косагашская СОШ',
                    'f4': 360,
                    'f5': 83,
                    'f6': 277
                },
                {
                    'f1': 13,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Коскольская СОШ" ',
                    'f4': 464,
                    'f5': 61,
                    'f6': 403
                },
                {
                    'f1': 14,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Кызылжарская СОШ"',
                    'f4': 420,
                    'f5': 203,
                    'f6': 217
                },
                {
                    'f1': 15,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Кызылкакская СОШ"',
                    'f4': 60,
                    'f5': 63,
                    'f6': -3
                },
                {
                    'f1': 16,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Луговская ООШ"',
                    'f4': 392,
                    'f5': 34,
                    'f6': 358
                },
                {
                    'f1': 17,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Майконырская СОШ"',
                    'f4': 461,
                    'f5': 68,
                    'f6': 393
                },
                {
                    'f1': 18,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ Панфиловская СОШ',
                    'f4': 360,
                    'f5': 86,
                    'f6': 274
                },
                {
                    'f1': 19,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ «Северная СОШ»',
                    'f4': 420,
                    'f5': 105,
                    'f6': 315
                },
                {
                    'f1': 20,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ Караагашская СОШ',
                    'f4': 108,
                    'f5': 53,
                    'f6': 55
                },
                {
                    'f1': 21,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Селетинская СОШ"',
                    'f4': 320,
                    'f5': 39,
                    'f6': 281
                },
                {
                    'f1': 22,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Буланбайская ООШ"',
                    'f4': 50,
                    'f5': 28,
                    'f6': 22
                },
                {
                    'f1': 23,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Берлыкская ООШ" ',
                    'f4': 100,
                    'f5': 50,
                    'f6': 50
                },
                {
                    'f1': 24,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ "Тохтинская ООШ"',
                    'f4': 328,
                    'f5': 21,
                    'f6': 307
                },
                {
                    'f1': 25,
                    'f2': 'Иртышский  р-н',
                    'f3': 'КГУ «Узынсуская СОШ»',
                    'f4': 560,
                    'f5': 66,
                    'f6': 494
                },
                {
                    'f1': 1,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ СОШ № 2 им. А.Текенова',
                    'f4': 964,
                    'f5': 417,
                    'f6': 547
                },
                {
                    'f1': 2,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ СОШ № 1 имени Ёлгина А. Н. ',
                    'f4': 804,
                    'f5': 535,
                    'f6': 269
                },
                {
                    'f1': 3,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ СОШ № 3 имени Катшы Оспановой',
                    'f4': 420,
                    'f5': 357,
                    'f6': 63
                },
                {
                    'f1': 4,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Инталинская ООШ',
                    'f4': 125,
                    'f5': 23,
                    'f6': 102
                },
                {
                    'f1': 5,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Байконысская СОШ',
                    'f4': 560,
                    'f5': 149,
                    'f6': 411
                },
                {
                    'f1': 6,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Кызылтанская СОШ',
                    'f4': 190,
                    'f5': 96,
                    'f6': 94
                },
                {
                    'f1': 7,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Тлеубайская ООШ',
                    'f4': 60,
                    'f5': 19,
                    'f6': 41
                },
                {
                    'f1': 8,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Береговая СОШ',
                    'f4': 784,
                    'f5': 221,
                    'f6': 563
                },
                {
                    'f1': 9,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Зеленорощинская ООШ',
                    'f4': 50,
                    'f5': 20,
                    'f6': 30
                },
                {
                    'f1': 10,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Осьмерыжская ООШ',
                    'f4': 80,
                    'f5': 23,
                    'f6': 57
                },
                {
                    'f1': 11,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Березовская СОШ',
                    'f4': 320,
                    'f5': 53,
                    'f6': 267
                },
                {
                    'f1': 12,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Бобровская СОШ',
                    'f4': 600,
                    'f5': 139,
                    'f6': 461
                },
                {
                    'f1': 13,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Жаскайратская ОСШ',
                    'f4': 80,
                    'f5': 20,
                    'f6': 60
                },
                {
                    'f1': 14,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Львовская СОШ',
                    'f4': 240,
                    'f5': 84,
                    'f6': 156
                },
                {
                    'f1': 15,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Воскресенская СОШ',
                    'f4': 250,
                    'f5': 61,
                    'f6': 189
                },
                {
                    'f1': 16,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Трофимовская СОШ',
                    'f4': 464,
                    'f5': 86,
                    'f6': 378
                },
                {
                    'f1': 17,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Жанакурлысская ООШ',
                    'f4': 108,
                    'f5': 11,
                    'f6': 97
                },
                {
                    'f1': 18,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Ивановская СОШ',
                    'f4': 250,
                    'f5': 104,
                    'f6': 146
                },
                {
                    'f1': 19,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Калиновская СОШ',
                    'f4': 450,
                    'f5': 95,
                    'f6': 355
                },
                {
                    'f1': 20,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Фрументьевская основная общеобразовательная школа',
                    'f4': 240,
                    'f5': 30,
                    'f6': 210
                },
                {
                    'f1': 21,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Октябрьская СОШ',
                    'f4': 320,
                    'f5': 96,
                    'f6': 224
                },
                {
                    'f1': 22,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Первомайская ООШ',
                    'f4': 50,
                    'f5': 13,
                    'f6': 37
                },
                {
                    'f1': 23,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Песчанская СОШ №1',
                    'f4': 640,
                    'f5': 173,
                    'f6': 467
                },
                {
                    'f1': 24,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Песчанская СОШ № 2',
                    'f4': 536,
                    'f5': 184,
                    'f6': 352
                },
                {
                    'f1': 25,
                    'f2': 'р-н Тереңкөл',
                    'f3': 'КГУ Федоровская СОШ',
                    'f4': 550,
                    'f5': 159,
                    'f6': 391
                },
                {
                    'f1': 1,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ Лебяжинская СОШ',
                    'f4': 900,
                    'f5': 467,
                    'f6': 433
                },
                {
                    'f1': 2,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "СОШ имени Абулкаира Баймульдина"',
                    'f4': 464,
                    'f5': 78,
                    'f6': 386
                },
                {
                    'f1': 3,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Такирская ООШ"',
                    'f4': 130,
                    'f5': 31,
                    'f6': 99
                },
                {
                    'f1': 4,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Жамбылская СОШ"',
                    'f4': 392,
                    'f5': 121,
                    'f6': 271
                },
                {
                    'f1': 5,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Жанатанская ООШ"',
                    'f4': 80,
                    'f5': 42,
                    'f6': 38
                },
                {
                    'f1': 6,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ Ленинская ООШ',
                    'f4': 120,
                    'f5': 41,
                    'f6': 79
                },
                {
                    'f1': 7,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Казынская СОШ"',
                    'f4': 300,
                    'f5': 76,
                    'f6': 224
                },
                {
                    'f1': 8,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Бескарагайская СОШ"',
                    'f4': 450,
                    'f5': 71,
                    'f6': 379
                },
                {
                    'f1': 9,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ Потанинская СОШ',
                    'f4': 624,
                    'f5': 132,
                    'f6': 492
                },
                {
                    'f1': 10,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "НШ Шамши"',
                    'f4': 20,
                    'f5': 9,
                    'f6': 11
                },
                {
                    'f1': 11,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "ООШ имени Абая"',
                    'f4': 100,
                    'f5': 46,
                    'f6': 54
                },
                {
                    'f1': 12,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Майкарагайская СОШ"',
                    'f4': 350,
                    'f5': 57,
                    'f6': 293
                },
                {
                    'f1': 13,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "СОШ  имени Б. Уахатова"',
                    'f4': 180,
                    'f5': 84,
                    'f6': 96
                },
                {
                    'f1': 14,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Малыбайская СОШ"',
                    'f4': 140,
                    'f5': 77,
                    'f6': 63
                },
                {
                    'f1': 15,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Казантайская ООШ"',
                    'f4': 100,
                    'f5': 37,
                    'f6': 63
                },
                {
                    'f1': 16,
                    'f2': 'Аккулинский р-н',
                    'f3': 'ГУ "Шакинская СОШ"',
                    'f4': 420,
                    'f5': 73,
                    'f6': 347
                },
                {
                    'f1': 17,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Амангельдинская НШ"',
                    'f4': 80,
                    'f5': 11,
                    'f6': 69
                },
                {
                    'f1': 18,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Шарбактинская СОШ"',
                    'f4': 464,
                    'f5': 171,
                    'f6': 293
                },
                {
                    'f1': 19,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Жабаглинская ООШ"',
                    'f4': 80,
                    'f5': 20,
                    'f6': 60
                },
                {
                    'f1': 20,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ Зор-Октябрьская ООШ',
                    'f4': 120,
                    'f5': 64,
                    'f6': 56
                },
                {
                    'f1': 21,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Ямышевская СОШ"',
                    'f4': 198,
                    'f5': 169,
                    'f6': 29
                },
                {
                    'f1': 22,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "СОШ имени Садуакаса Сатыбалдина"',
                    'f4': 198,
                    'f5': 45,
                    'f6': 153
                },
                {
                    'f1': 23,
                    'f2': 'Аккулинский р-н',
                    'f3': 'КГУ "Тлектесская СОШ"',
                    'f4': 80,
                    'f5': 51,
                    'f6': 29
                },
                {
                    'f1': 1,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Коктюбинская СОШ" отдела образования Майского района, управления образования Павлодарской области ',
                    'f4': 350,
                    'f5': 414,
                    'f6': -64
                },
                {
                    'f1': 2,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "СОШ имени Е. Мукашева" отдела образования Майского района, управления образования Павлодарской области ',
                    'f4': 640,
                    'f5': 310,
                    'f6': 330
                },
                {
                    'f1': 3,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Акжарская СОШ" отдела образования Майского района, управления образования Павлодарской области ',
                    'f4': 264,
                    'f5': 91,
                    'f6': 173
                },
                {
                    'f1': 4,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Акшиманская ОСШ" отдела образования Майского района, управления образования Павлодарской области ',
                    'f4': 200,
                    'f5': 48,
                    'f6': 152
                },
                {
                    'f1': 5,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Средняя общеобразовательная школа-лицей имени А. Иманова" отдела образования Майского района, управления образования Павлодарской области ',
                    'f4': 536,
                    'f5': 120,
                    'f6': 416
                },
                {
                    'f1': 6,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Жалтырская СОШ" отдела образования Майского района, управления образования Павлодарской области ',
                    'f4': 320,
                    'f5': 153,
                    'f6': 167
                },
                {
                    'f1': 7,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Казанская средняя общеобразовательная школа" отдела образования Майского района, управления образования Павлодарской области',
                    'f4': 360,
                    'f5': 61,
                    'f6': 299
                },
                {
                    'f1': 8,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Казахстанская ОСШ" отдела образования Майского района, управления образования Павлодарской области ',
                    'f4': 264,
                    'f5': 49,
                    'f6': 215
                },
                {
                    'f1': 9,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Каратерекская средняя общеобразовательная школа" отдела образования Майского района, управления образования Павлодарской области',
                    'f4': 552,
                    'f5': 130,
                    'f6': 422
                },
                {
                    'f1': 10,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Майская средняя общеобразовательная школа" отдела образования Майского района, управления образования Павлодарской области',
                    'f4': 624,
                    'f5': 82,
                    'f6': 542
                },
                {
                    'f1': 11,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Майтюбекская СОШ" отдела образования Майского района, управления образования Павлодарской области ',
                    'f4': 380,
                    'f5': 63,
                    'f6': 317
                },
                {
                    'f1': 12,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ "Малайсаринская СОШ" отдела образования Майского района, управления образования Павлодарской области ',
                    'f4': 624,
                    'f5': 121,
                    'f6': 503
                },
                {
                    'f1': 13,
                    'f2': 'Майский р-н',
                    'f3': 'КГУ «Сатинская основная средняя школа» отдела образования Майского района, управления образования Павлодарской области',
                    'f4': 350,
                    'f5': 51,
                    'f6': 299
                },
                {
                    'f1': 1,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Пресное',
                    'f4': 320,
                    'f5': 85,
                    'f6': 235
                },
                {
                    'f1': 2,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Hабережное',
                    'f4': 420,
                    'f5': 292,
                    'f6': 128
                },
                {
                    'f1': 3,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.«Жана кала»',
                    'f4': 170,
                    'f5': 87,
                    'f6': 83
                },
                {
                    'f1': 4,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Ефремовка',
                    'f4': 450,
                    'f5': 203,
                    'f6': 247
                },
                {
                    'f1': 5,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Зангар',
                    'f4': 90,
                    'f5': 121,
                    'f6': -31
                },
                {
                    'f1': 6,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Коряковка',
                    'f4': 30,
                    'f5': 15,
                    'f6': 15
                },
                {
                    'f1': 7,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Заря',
                    'f4': 520,
                    'f5': 394,
                    'f6': 126
                },
                {
                    'f1': 8,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Hовоямышево',
                    'f4': 420,
                    'f5': 340,
                    'f6': 80
                },
                {
                    'f1': 9,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.«Айтім ауылы»',
                    'f4': 60,
                    'f5': 44,
                    'f6': 16
                },
                {
                    'f1': 10,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Каратогай',
                    'f4': 200,
                    'f5': 9,
                    'f6': 191
                },
                {
                    'f1': 11,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Красноармейка',
                    'f4': 350,
                    'f5': 429,
                    'f6': -79
                },
                {
                    'f1': 12,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Луганск',
                    'f4': 680,
                    'f5': 304,
                    'f6': 376
                },
                {
                    'f1': 13,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Богдановка',
                    'f4': 360,
                    'f5': 37,
                    'f6': 323
                },
                {
                    'f1': 14,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Аккудык',
                    'f4': 15,
                    'f5': 9,
                    'f6': 6
                },
                {
                    'f1': 15,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Госплемстанция',
                    'f4': 350,
                    'f5': 331,
                    'f6': 19
                },
                {
                    'f1': 16,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Березовка',
                    'f4': 30,
                    'f5': 11,
                    'f6': 19
                },
                {
                    'f1': 17,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Ольгинка',
                    'f4': 480,
                    'f5': 115,
                    'f6': 365
                },
                {
                    'f1': 18,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Розовка',
                    'f4': 180,
                    'f5': 253,
                    'f6': -73
                },
                {
                    'f1': 19,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Рождественка',
                    'f4': 150,
                    'f5': 97,
                    'f6': 53
                },
                {
                    'f1': 20,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Маралды',
                    'f4': 140,
                    'f5': 53,
                    'f6': 87
                },
                {
                    'f1': 21,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Чернорецк',
                    'f4': 250,
                    'f5': 265,
                    'f6': -15
                },
                {
                    'f1': 22,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Достык',
                    'f4': 120,
                    'f5': 59,
                    'f6': 61
                },
                {
                    'f1': 23,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Караколь',
                    'f4': 30,
                    'f5': 14,
                    'f6': 16
                },
                {
                    'f1': 24,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Hовочерноярка',
                    'f4': 536,
                    'f5': 278,
                    'f6': 258
                },
                {
                    'f1': 25,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Сычевка',
                    'f4': 40,
                    'f5': 71,
                    'f6': -31
                },
                {
                    'f1': 26,
                    'f2': 'Павлодарский р-н',
                    'f3': 'с.Шакат',
                    'f4': 640,
                    'f5': 182,
                    'f6': 458
                },
                {
                    'f1': 1,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Успенская СОШ № 2"',
                    'f4': 825,
                    'f5': 199,
                    'f6': 626
                },
                {
                    'f1': 2,
                    'f2': 'Успенский  р-н',
                    'f3': 'KГУ "Успенская СОШ № 1"',
                    'f4': 300,
                    'f5': 306,
                    'f6': -6
                },
                {
                    'f1': 3,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ Успенская СОШ № 3',
                    'f4': 198,
                    'f5': 177,
                    'f6': 21
                },
                {
                    'f1': 4,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Белоусовская СОШ"',
                    'f4': 320,
                    'f5': 85,
                    'f6': 235
                },
                {
                    'f1': 5,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Богатырская ООШ"',
                    'f4': 320,
                    'f5': 22,
                    'f6': 298
                },
                {
                    'f1': 6,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ Козыкеткенская СОШ',
                    'f4': 504,
                    'f5': 84,
                    'f6': 420
                },
                {
                    'f1': 7,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Ковалевская ООШ"',
                    'f4': 250,
                    'f5': 58,
                    'f6': 192
                },
                {
                    'f1': 8,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Каратайская СОШ"',
                    'f4': 320,
                    'f5': 79,
                    'f6': 241
                },
                {
                    'f1': 9,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Лозовская СОШ"',
                    'f4': 450,
                    'f5': 99,
                    'f6': 351
                },
                {
                    'f1': 10,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Вознесенская ООШ"',
                    'f4': 150,
                    'f5': 47,
                    'f6': 103
                },
                {
                    'f1': 11,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Галицкая СОШ"',
                    'f4': 320,
                    'f5': 192,
                    'f6': 128
                },
                {
                    'f1': 12,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Новопокровская ООШ"',
                    'f4': 202,
                    'f5': 45,
                    'f6': 157
                },
                {
                    'f1': 13,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Ольгинская СОШ"',
                    'f4': 624,
                    'f5': 77,
                    'f6': 547
                },
                {
                    'f1': 14,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Павловская СОШ"',
                    'f4': 624,
                    'f5': 98,
                    'f6': 526
                },
                {
                    'f1': 15,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Дмитриевская НОШ"',
                    'f4': 192,
                    'f5': 10,
                    'f6': 182
                },
                {
                    'f1': 16,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Константиновская СОШ"',
                    'f4': 500,
                    'f5': 167,
                    'f6': 333
                },
                {
                    'f1': 17,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Равнопольская ООШ"',
                    'f4': 300,
                    'f5': 39,
                    'f6': 261
                },
                {
                    'f1': 18,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Таволжанская СОШ"',
                    'f4': 350,
                    'f5': 54,
                    'f6': 296
                },
                {
                    'f1': 19,
                    'f2': 'Успенский  р-н',
                    'f3': 'КГУ "Тимирязевская ООШ"',
                    'f4': 480,
                    'f5': 51,
                    'f6': 429
                },
                {
                    'f1': 1,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "ООШ №2 "',
                    'f4': 520,
                    'f5': 304,
                    'f6': 216
                },
                {
                    'f1': 2,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ СОШ  №3',
                    'f4': 320,
                    'f5': 310,
                    'f6': 10
                },
                {
                    'f1': 3,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "СОШ имени Абая Кунанбаева "',
                    'f4': 300,
                    'f5': 281,
                    'f6': 19
                },
                {
                    'f1': 4,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "СОШ с ГК"',
                    'f4': 1176,
                    'f5': 383,
                    'f6': 793
                },
                {
                    'f1': 5,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Александровская СОШ"',
                    'f4': 480,
                    'f5': 75,
                    'f6': 405
                },
                {
                    'f1': 6,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Жана-аульская СОШ"',
                    'f4': 320,
                    'f5': 92,
                    'f6': 228
                },
                {
                    'f1': 7,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ «Алексеевская СОШ»',
                    'f4': 480,
                    'f5': 97,
                    'f6': 383
                },
                {
                    'f1': 8,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Галкинская СОШ"',
                    'f4': 640,
                    'f5': 128,
                    'f6': 512
                },
                {
                    'f1': 9,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Арбигенская СОШ"',
                    'f4': 192,
                    'f5': 30,
                    'f6': 162
                },
                {
                    'f1': 10,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Жылыбулакская ООШ"',
                    'f4': 504,
                    'f5': 66,
                    'f6': 438
                },
                {
                    'f1': 11,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ «Хмельницкая СОШ»',
                    'f4': 640,
                    'f5': 222,
                    'f6': 418
                },
                {
                    'f1': 12,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ «Красиловская СОШ»',
                    'f4': 320,
                    'f5': 60,
                    'f6': 260
                },
                {
                    'f1': 13,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ «Карабидайская ООШ"',
                    'f4': 250,
                    'f5': 31,
                    'f6': 219
                },
                {
                    'f1': 14,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Назаровская ООШ"',
                    'f4': 320,
                    'f5': 20,
                    'f6': 300
                },
                {
                    'f1': 15,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Орловская СОШ"',
                    'f4': 480,
                    'f5': 182,
                    'f6': 298
                },
                {
                    'f1': 16,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Северная ООШ"',
                    'f4': 320,
                    'f5': 35,
                    'f6': 285
                },
                {
                    'f1': 17,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Сосновская СОШ"',
                    'f4': 392,
                    'f5': 49,
                    'f6': 343
                },
                {
                    'f1': 18,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Заборовская ООШ"',
                    'f4': 120,
                    'f5': 27,
                    'f6': 93
                },
                {
                    'f1': 19,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Малиновская ООШ"',
                    'f4': 320,
                    'f5': 35,
                    'f6': 285
                },
                {
                    'f1': 20,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Алгинская НОШ"',
                    'f4': 25,
                    'f5': 8,
                    'f6': 17
                },
                {
                    'f1': 21,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Маралдинская НОШ"',
                    'f4': 25,
                    'f5': 6,
                    'f6': 19
                },
                {
                    'f1': 22,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ «Чигириновская СОШ»',
                    'f4': 480,
                    'f5': 129,
                    'f6': 351
                },
                {
                    'f1': 23,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ «Есильбайская СОШ»',
                    'f4': 216,
                    'f5': 85,
                    'f6': 131
                },
                {
                    'f1': 24,
                    'f2': 'Щербактинский  р-н',
                    'f3': 'КГУ "Шалдайская СОШ"',
                    'f4': 636,
                    'f5': 276,
                    'f6': 360
                },
                {
                    'f1': null,
                    'f2': 'всего',
                    'f3': '357',
                    'f4': 169527,
                    'f5': 111367,
                    'f6': 58160
                }
            ]
        }
    }
}
</script>

<style scoped>

</style>