



















































































































































import Class from './c-actual';
export default Class;
