import { Component, Prop, Vue } from 'vue-property-decorator';
import '@/modules/widget-yard/initialization';
import { accessor } from '@/modules/widget-yard/store';
import { ISelectedIndicatorItems } from '@/modules/widget-yard/store/types';

import queue from '@/modules/widget-yard/c-widget/queue';
import { IGrowIndex, assignGrow } from '@/modules/widget-yard/c-widget/common';


interface IShownData {
    symbol: string;
    actual: boolean;
    value: string;
    unit: string;
    growIndicProc: null | number; // индикатор роста в процентах
    growIndic: null | number; // 0- равное значение, 1 положительный рост, -1 падение роста
}

interface IShownItem {
    title: string;
    data: null | IShownData;
}

@Component
export default class CActual extends Vue {
    private created() {
        this.$watch('selectedMap', this.selectedMapChanged);
    }

    private mounted() {
        this.selectedMapChanged();
    }

    private beforeDestroy() {
        queue.clear(this);
    }


    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly debug!: boolean;


    @Prop({
        type: String,
        required: true
    })
    public readonly data!: any;


    public get indicatorKeys(): string {
        return this.data;
    }


    public get selectedMap(): Map<string, ISelectedIndicatorItems> {
        const result = new Map<string, ISelectedIndicatorItems>();


        const selectedIndicator = accessor.selectedMap.get(this.indicatorKeys);
        if (selectedIndicator !== undefined) {
            result.set(this.indicatorKeys, selectedIndicator);
        }

        return result;
    }

    private selectedMapChanged() {
        queue.schedule(this, () => {
            this.shownData = this.getShownData();
        });
    }


    private shownData: IGrowIndex[] = [];

    public getShownData(): IGrowIndex[] {
        const result: IGrowIndex[] = [];

        const growDatas = assignGrow(this.indicatorKeys);
        const data = ((growDatas !== undefined) ? growDatas : null);
        /*
        let title = 'N/A';

        const indicator = accessor.getIndicator(this.indicatorKeys);
        if (indicator !== undefined) {
            title = indicator.shortIndicator;
        }
        let data: null | IShownData = null;
        const selectedItems = this.selectedMap.get(this.indicatorKeys);
        if (selectedItems !== undefined) {
            const current = selectedItems.item;
            if (current !== undefined) {
                const unit = selectedItems.unit;
                let unitLowerCased = unit.charAt(0).toLowerCase() + unit.slice(1);
                if (unitLowerCased === 'процент') {
                    unitLowerCased = '%';
                }
                const value = this.$n(current.fact, 'number');
                const actual = selectedItems.isItemActual;
                const typeIndic = selectedItems.character;
                const previous = selectedItems.previousItem;
                let growIndicProc = null;
                let growIndic = 1;
                if (previous !== undefined) {
                    growIndicProc = Math.round(((current.fact / previous.fact) * 100 - 100) * 100) / 100;
                    if (typeIndic === 0) {
                        growIndic = typeIndic;
                    } else {
                        if (growIndicProc === 0) {
                            growIndic = typeIndic;
                        } else {
                            growIndic = typeIndic * growIndicProc;
                        }
                    }
                }
                data = { actual, value, unit: unitLowerCased, growIndicProc, growIndic };
            }
        } */
        if (data !== null) {
            if (data.unit.includes('тенге')) {
                data.digit = data.unit.replace('тенге', '');
            }
            if (data.unit.includes('доллар США')) {
                data.digit = data.unit.replace('доллар США', '');
            }
            result.push(data);
        }

        return result;
    }
}