<template>
    <div class="main-page">
        <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow">
            <div class="row filter-block">
                <div class="col-4 period">
                    <template v-for="(period, index) in periodLst">
                        <a href="#"
                           v-if="index<periodLst.length-1"
                           :key="'per_indx'+ index"
                           :class='{active: period.value === curPeriod.value}'
                           @click="selectPeriod(period)">
                            {{ period.name }}
                        </a>
                    </template>
                    <a href="#"
                       class="calendar"
                       :class='{active: curPeriod.value === null}'
                       @click="selectPeriod(periodLst[3])"></a>
                    <date-picker v-show="curPeriod.value === null"
                                 v-model="selectedPeriod"
                                 range
                                 format="DD.MM.YYYY"
                                 @input="chgPicker"
                                 range-separator=" - "
                    ></date-picker>
                </div>
                <!-- <div style="width: 50%">
                  <b-nav pills>
                    <b-nav-item
                      v-for="(per, per_indx) in periodLst"
                      :key="'per_indx'+ per_indx"
                      :active="per.value==curPeriod"
                      @click="selectPeriod(per.value)"
                    >{{per.name}}</b-nav-item>
                    <b-nav-item @click="selectPeriod(null)" :active="curPeriod==null">
                      <b-badge>
                        <span class="mdi mdi-calendar-month"></span>
                      </b-badge>
                    </b-nav-item>
                  </b-nav>
                </div> -->
                <div class="col-3">
                    <treeselect v-if="topicTreeLst.length>0" :options="topicTreeLst" :clearable="false"
                                :disable-branch-nodes="true" :show-count="true" v-model="topicId" @input="updateValue"
                                placeholder="Выбрать тему..."/>
                </div>
                <div class="col totals">
                    <!-- <b-dropdown
                        size="lg"
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                    >
                        <template v-slot:button-content>
                            <span
                                :class="{'mdi':true, 'mdi-circle': true, 'tonePos': curTone.value==2, 'toneNeg': curTone.value==-2, 'toneNeut': curTone.value==1}"
                                :title="curTone.text"
                            ></span>
                        </template>
                        <b-dropdown-item v-for="(tn, tn_indx) in tone" :key="'tn_indx'+tn_indx" @click="chgTone(tn)">
                            <span
                                :class="{ 'tonePos': tn.value==2, 'toneNeg': tn.value==-2, 'toneNeut': tn.value==1}"
                            >{{tn.text}}</span>
                        </b-dropdown-item>
                    </b-dropdown> -->
                    <label>
                        <b-form-checkbox switch class="tot" v-model="tones.tot.switch">{{ tones.tot.text }}
                        </b-form-checkbox>
                        <span class="toneTot">{{ infoData.all }}</span>
                    </label>
                    <label>
                        <b-form-checkbox switch class="pos" v-model="tones.pos.switch">{{ tones.pos.text }}
                        </b-form-checkbox>
                        <span class="tonePos">{{ infoData.pos }}</span>
                    </label>
                    <label>
                        <b-form-checkbox switch class="neg" v-model="tones.neg.switch">{{ tones.neg.text }}
                        </b-form-checkbox>
                        <span class="toneNeg">{{ infoData.neg }}</span>
                    </label>
                    <label>
                        <b-form-checkbox switch class="neut" v-model="tones.neut.switch">{{ tones.neut.text }}
                        </b-form-checkbox>
                        <span class="toneNeut">{{ infoData.neut }}</span>
                    </label>
                </div>
            </div>
            <div class="chart-block">
                <c-socCharts
                    :main="true"
                    :themeData="{ theme: theme, period: curPeriod, datas: data }"
                    :selectPeriod="selectedPeriod"
                    :tone="curTone"
                    :spheres="sphereData!==null ? sphereData.spheres : null"
                ></c-socCharts>
            </div>
            <div class="table-block" v-if="data!=null && data.length>0">
                <div style="float: left; width: 50%">
                    <div style="text-align: center; padding-bottom: 15px">Топ по вовлечённости</div>
                    <c-soc-tape :data="data" :sortField="'involvemed'"></c-soc-tape>
                </div>
                <div style="float: right; width: 50%">
                    <div style="text-align: center; padding-bottom: 15px">Топ по просмотрам</div>
                    <c-soc-tape :data="data" :sortField="'viewSum'"></c-soc-tape>
                </div>
            </div>
        </b-overlay>
    </div>
</template>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
    border: none;
}

.tot .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #1f4e79;
}

.pos .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #3b7cb7;
}

.neg .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #fcaf7c;
}

.neut .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #7f7f7f;
}
</style>

<style lang="scss" scoped>
.filter-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row nowrap;

    margin-bottom: 2rem;
}

.period a {
    display: inline;
    margin-right: 0.5rem;
}

a.calendar {
    background-position: bottom right;

    &::after {
        content: 'выбрать период';
        margin-right: 1.8rem;
    }
}

.totals {
    margin-left: auto;
    white-space: nowrap;

    & label {
        margin-left: 1rem;
        color: #bfbfbf;

        & span {
            font-weight: bold;
        }
    }
}
</style>

<script>
import {Vue, Component} from 'vue-property-decorator'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'

import cSocialCharts from '@/modules/socialnet/components/cSocialCharts.vue'
import CSocTape from '@/components/сSocTape'
import Treeselect from '@riophae/vue-treeselect'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

@Component({
    components: {
        'date-picker': DatePicker,
        'c-socCharts': cSocialCharts,
        'c-soc-tape': CSocTape,
        'treeselect': Treeselect
    }
})
export default class Main extends Vue {
    periodLst = [
        {name: 'сутки', value: 1},
        {name: 'неделя', value: 7},
        {name: 'месяц', value: 30},
        {name: 'выбрать период', value: null, selectPeriod: true}]; // список периодов
    infoData = {all: 0, pos: 0, neg: 0, neut: 0}
    selectedPeriod = null // произвольный период
    curPeriod = {name: 'сутки', value: 1} // текущий период (=null для произвольного периода)
    topicId = 1612344144 // id темы
    theme = {} // объект темы
    type = 1
    data = []
    loading = false
    tone = [
        {text: 'Все', value: null},
        {text: 'Негативная', value: '-2'},
        {text: 'Нейтральная', value: '1'},
        {text: 'Позитивная', value: '2'}
    ]
    curTone = {text: 'Всего', switch: true, value: null}
    sphereData = null // сферы с базы
    tones = {
        tot: {text: 'Всего', switch: true, value: null},
        pos: {text: 'Позитивная', switch: false, value: 2},
        neg: {text: 'Негативная', switch: false, value: -2},
        neut: {text: 'Нейтральная', switch: false, value: 1}
    }
    topicTreeLst = []

    updateValue() {
        const that = this
        let fl = false
        for (let i = 0; i < that.topicTreeLst.length; i++) {
            if (fl) {
                break
            }
            const parent = that.topicTreeLst[i]
            if (parent.id === that.topicId) {
                that.theme = parent
                break
            }
            if (parent.children !== null && parent.children !== undefined) {
                for (let j = 0; j < parent.children.length; j++) {
                    const child = parent.children[j]
                    if (child.id === that.topicId) {
                        that.theme = child
                        fl = true
                        break
                    }
                }
            }
        }
        this.loadData()
    }

    created() {
        this.$watch('tones.tot.switch', (val) => {
            if (val) {
                this.tones.pos.switch = false
                this.tones.neg.switch = false
                this.tones.neut.switch = false
                this.curTone = this.tones.tot
                this.loadData()
            }
        })
        this.$watch('tones.pos.switch', (val) => {
            if (val) {
                this.tones.tot.switch = false
                this.tones.neg.switch = false
                this.tones.neut.switch = false
                this.curTone = this.tones.pos
                this.loadData()
            }
        })
        this.$watch('tones.neg.switch', (val) => {
            if (val) {
                this.tones.pos.switch = false
                this.tones.tot.switch = false
                this.tones.neut.switch = false
                this.curTone = this.tones.neg
                this.loadData()
            }
        })
        this.$watch('tones.neut.switch', (val) => {
            if (val) {
                this.tones.pos.switch = false
                this.tones.neg.switch = false
                this.tones.tot.switch = false
                this.curTone = this.tones.neut
                this.loadData()
            }
        })
    }

    selectPeriod(period) {
        const that = this
        that.curPeriod = period
        if (that.curPeriod.value != null || that.getSelectedPeriod() != null) {
            that.loadData()
        }
    }

    async loadData() {
        const that = this
        that.loading = true
        let selectedPeriod = null
        let keywords = null
        let attrSearch = false
        that.data = []

        if (that.theme != null) {
            keywords = that.theme.keywords
            if (that.theme.attrSearch != null && that.theme.attrSearch !== undefined) {
                attrSearch = that.theme.attrSearch
            }
        }
        if (that.curPeriod.value == null) {
            selectedPeriod = this.getSelectedPeriod()
        }
        if (that.curPeriod.value == null && selectedPeriod == null) {
            that.curPeriod = this.periodLst[0]
        }

        const ajxParams = {
            params: {
                'type': that.type,
                'period': that.curPeriod.value,
                'selectedPeriod': selectedPeriod,
                'keywords': keywords,
                'attrSearch': attrSearch,
                'tone': that.curTone.value
            }
        }
        let data = null
        data = await axios.post('api-py/mm-post/report-form', ajxParams)
        that.loading = false
        if (data.data.status !== 'success') {
            alert('Ошибка загрузки данных!')
            console.log('response', data)
            return
        }
        that.data = that.getUnrelevant(data.data.data)
        that.infoData = {all: 0, pos: 0, neg: 0, neut: 0}
        let pos = 0
        let neg = 0
        let neut = 0
        for (const el of that.data) {
            if (el.tone === -2) {
                neg++
            } else if (el.tone === 1) {
                neut++
            } else if (el.tone === 2) {
                pos++
            }
        }
        this.infoData = {all: that.data.length, pos: pos, neg: neg, neut: neut}
    }

    getSelectedPeriod() {
        const that = this
        let result = null
        if (that.curPeriod.value == null) {
            if (that.selectedPeriod == null || that.selectedPeriod.length < 2 || that.selectedPeriod[0] == null || that.selectedPeriod[1] == null) {
                return null
            }
            result = []
            result.push(that.selectedPeriod[0].getTime() / 1000)
            const eDate = that.selectedPeriod[1]
            eDate.setHours(23, 59, 59)
            result.push(eDate.getTime() / 1000)
        }
        return result
    }

    async loadTopicLst() {
        const that = this
        that.loading = true
        let response = await axios.get('api-py/mm-data/tsection-list')
        if (response.data.status !== 'success') {
            alert('Ошибка загрузки темы tsection-list')
        }
        const sectionList = response.data.tsection_list

        response = await axios.get('api-py/mm-data/topics-list')
        if (response.data.status !== 'success') {
            alert('Ошибка загрузки темы topics-list')
        }
        const themeLst = response.data.topics_list

        const res = []

        for (const s of sectionList) {
            s.label = s.name
            s.children = null
            res.push(s)
            if (that.topicId === s.id) {
                that.theme = s
            }
            for (let i = 0; i < themeLst.length; i++) {
                const theme = themeLst[i]
                for (const t of s.topics) {
                    if (t === theme.id) {
                        if (res[res.length - 1].children == null) {
                            res[res.length - 1].children = []
                        }
                        theme.label = theme.name
                        res[res.length - 1].children.push(theme)
                        if (that.topicId === theme.id) {
                            that.theme = theme
                        }
                        themeLst.splice(i, 1)
                        i--
                    }
                }
            }
        }
        for (const theme of themeLst) {
            theme.label = theme.name
            res.unshift(theme)

            if (this.topicId === theme.id) {
                this.theme = theme
            }
        }
        that.topicTreeLst = res
        that.loadData()
    }

    /* async loadTopic () {
      const that = this
      that.loading = true
      let data = null
      data = await axios.post('/topic-by-id', { params: { id: that.topicId } })
      if (data.data.status !== 'success') {
        alert('Ошибка загрузки темы')
      }
      that.theme = data.data.topic
      that.loadData()
    } */

    chgPicker() {
        const that = this
        if (that.curPeriod.value == null && that.getSelectedPeriod() != null) {
            that.loadData()
        }
    }

    getUnrelevant(data) { // вытащить нерелевантные данные
        const that = this
        if (that.theme == null) {
            return data
        }
        if (data === null || data === undefined) {
            return []
        }
        const resData = []

        for (const el of data) {
            let fl = false
            if (el.unrelevant !== undefined && el.unrelevant != null) {
                for (const unEl of el.unrelevant) {
                    if (unEl === that.theme.id) {
                        fl = true
                        break
                    }
                }
            }
            if (!fl) {
                resData.push(el)
            }
        }
        return resData
    }

    /* chgTone (tone) {
      const that = this
      if (tone.value === that.curTone.value) { return }
      that.curTone = tone
      that.loadData()
    } */

    loadSphere() {
        const that = this
        axios
            .get('api-py/mm-data/spheres')
            .then(response => (that.sphereData = response.data))
    }

    mounted() {
        this.loadTopicLst()
        // this.loadTopic()
        this.loadSphere()
    }
}
</script>>
