





















import Class from './home';
export default Class;
