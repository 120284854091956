<template>
    <b-container>
        <div class="section-title budget">Бюджетная заявка: проекты</div>
        <b-card>
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="7"
                track-by="name"
                label="АБП"
            >
                <b-input-group>
                    <b-input-group-addon>
                        <b-form-input v-model="curAbp"></b-form-input>
                    </b-input-group-addon>
                    <b-form-select text-field="name_ru" value-field="abp" :options="abpBase" v-model="curAbp" @change="chgAbp">
                    </b-form-select>
                </b-input-group>
            </b-form-group>
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="7"
                label="ГУ"
            >
                <b-input-group>
                    <b-input-group-addon>
                        <b-form-input v-model="curComp"></b-form-input>
                    </b-input-group-addon>
                    <b-form-select text-field="name_ru" value-field="code" :options="companyBase" v-model="curComp">
                    </b-form-select>
                </b-input-group>
            </b-form-group>
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="7"
                label="Программа"
            >
                <b-input-group>
                    <b-input-group-addon>
                        <b-form-input v-model="curProg"></b-form-input>
                    </b-input-group-addon>
                    <b-form-select text-field="name_ru" value-field="prg" :options="progBase" v-model="curProg">
                    </b-form-select>
                </b-input-group>
            </b-form-group>
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="7"
                label="Подпрограмма"
            >
                <b-input-group>
                    <b-input-group-addon>
                        <b-form-input v-model="curSubProg"></b-form-input>
                    </b-input-group-addon>
                    <b-form-select text-field="name_ru" value-field="ppr" :options="subProgBase" v-model="curSubProg">
                    </b-form-select>
                </b-input-group>
            </b-form-group>
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm
                content-cols-lg="7"
                label="Специфика"
            >
                <b-input-group>
                    <b-input-group-addon>
                        <b-form-input v-model="curSpecific"></b-form-input>
                    </b-input-group-addon>
                    <b-form-select text-field="name_ru" value-field="spf" :options="specificBase" v-model="curSpecific">
                    </b-form-select>
                </b-input-group>
            </b-form-group>
        </b-card>
        <div><div class="text-right m-2">тыс. тенге</div></div>
        <b-table-simple :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" caption-top>
            <b-thead class="text-center bold_header">
                <b-tr>
                    <b-th rowspan="2">№</b-th>
                    <b-th rowspan="2">Наименование проекта</b-th>
                    <b-th rowspan="2">Общая стоимость за вычетом ПИР</b-th>
                    <b-th rowspan="2">Стоимость по договору</b-th>
                    <b-th rowspan="2">Экономия</b-th>
                    <b-th colspan="3" class="text-center">Сумма на плановый период</b-th>
                    <b-th rowspan="2">Перечень предоставляемой документации</b-th>
                </b-tr>
                <b-tr>
                    <b-th>2022</b-th>
                    <b-th>2023</b-th>
                    <b-th>2024</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="(item, index) in tableData" :key="index">
                    <b-td>{{ item.f1 }}</b-td>
                    <b-td>{{ item.f2 }}</b-td>
                    <b-td class="text-right">{{ $n(item.f3) }}</b-td>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td class="text-right">{{ $n(item.f3) }}</b-td>
                    <b-td>{{ item.f4 }}</b-td>
                    <b-td>{{ item.f5 }}</b-td>
                    <b-td>{{ item.f6 }}</b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
        <b-card title="Прикрепленные файлы:">
            <b-list-group>
                <b-list-group-item>
                    <b-link target="_blank" href="/media/request_temp.pdf">1. Пояснительная записка</b-link>
                </b-list-group-item>
                <b-list-group-item>
                    <b-link target="_blank" href="/media/request_temp.pdf">2. Копия договора на ПСД от 04.09.2020 г. №80/20</b-link>
                </b-list-group-item>
                <b-list-group-item>
                    <b-link target="_blank" href="/media/request_temp.pdf">3. Копия трехстороннего договора
                        от 18.05.2020 г. б/н
                    </b-link>
                </b-list-group-item>
                <b-list-group-item>
                    <b-link target="_blank" href="/media/request_temp.pdf">4. Акт на землю
                        от 04.09.2020 г. №30/20
                    </b-link>
                </b-list-group-item>
                <b-list-group-item>
                    <b-link target="_blank" href="/media/request_temp.pdf">5. ГЭ № EPVL-0090/20 от 29.07.2020 г.</b-link>
                </b-list-group-item>
            </b-list-group>
            <div class="text-right mt-3">
                <b-button class="m-1">Скачать в xls</b-button>
                <b-button class="m-1" variant="primary"  v-b-modal.modal-1>Утвердить</b-button>
                <b-button class="m-1" variant="success" href="/#/demo-budget-execution/request/budget-commission">Отправить</b-button>
            </div>
        </b-card>
        <b-modal id="modal-1" title="Результат" ok-only>
            Бюджетная завка успешно утверждена!
        </b-modal>
    </b-container>
</template>

<script>
export default {
    name: 'Request',
    data() {
        return {
            abpBase: [],
            curAbp: 271,
            companyBase: [],
            curComp: 2713002,
            progBase: [],
            curProg: 15,
            subProgBase: [],
            curSubProg: 0,
            specificBase: [],
            curSpecific: 431,
            tableFields: [
                {
                    key: 'f1',
                    label: '№',
                    rowspan: 2
                },
                {
                    key: 'f2',
                    label: 'Наименование проекта'
                },
                {
                    key: 'f3',
                    label: 'Общая стоимость за вычетом ПИР'
                },
                {
                    key: 'f4',
                    label: 'Стоимость по договору'
                },
                {
                    key: 'f5',
                    label: 'Экономия'
                },
                {
                    key: 'f6',
                    label: '2022'
                },
                {
                    key: 'f7',
                    label: '2023'
                },
                {
                    key: 'f8',
                    label: '2024'
                },
                {
                    key: 'f9',
                    label: 'Перечень предоставляемой документации'
                }
            ],
            tableData: [
                {
                    'f1': null,
                    'f2': 'ВСЕГО',
                    'f3': 4754571,
                    'f4': '',
                    'f5': '',
                    'f6': ''
                },
                {
                    'f1': 1,
                    'f2': 'Проектирование жилого микрорайона "Достык" в г. Павлодар. Строительство СОШ на 1200 мест',
                    'f3': 2479934,
                    'f4': '',
                    'f5': '',
                    'f6': 'Копия договора на ПСД от 04.09.2020 г. №80/20, копия трехстороннего договора от 18.05.2020 г. б/н'
                },
                {
                    'f1': 2,
                    'f2': 'Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар',
                    'f3': 1371014,
                    'f4': '',
                    'f5': '',
                    'f6': 'ГЭ № EPVL-0090/20 от 29.07.2020 г.'
                },
                {
                    'f1': 3,
                    'f2': 'Строительство пристройки на 348 мест в СОШГ №9 г. Павлодар',
                    'f3': 903623,
                    'f4': '',
                    'f5': '',
                    'f6': ' Акт на землю от 04.09.2020 г. №30/20'
                }
            ]
        };
    },
    methods: {
        async loadAbp() {
            let response = [];
            this.abpBase = [];
            try {
                // response = await fetch('/api-py/get-dict-func-childs-by-parent-type/' + this.curFunGr.id + '/3/');
                response = await fetch('/api-py/get-dict-func-childs-by-parent-type-budgetLevel/' + '21784' + '/3/' + '[2]');
                response = await response.json();
            } catch (error) {
                response = [];
            }
            response.sort((a, b) => (a.abp - b.abp > 0) ? 1 : -1);
            this.abpBase = this.setIdArr(response, 'abp');
            // if (this.abpBase.length > 0) {
            //     this.curAbp = this.abpBase[0];
            // } else {
            //     this.curAbp = 271;
            // }
            this.curAbp = 271;
            this.chgAbp();
        },
        async loadCompany() {
            let result = [];
            this.curComp = null;
            try {
                result = await fetch('/api-py/dict-gu-by-region-abp/450101/' + this.curAbp)
                    .then(response => response.json());
            } catch (error) {
                result = [];
            }
            // if (result.length > 0) { this.curComp = this.setNameLang(result[0], 'code'); }
            this.companyBase = result;
            this.curComp = 2713002;
        },
        findAbp(abp) {
            for (const item of this.abpBase) {
                if (item.abp === abp) {
                    return item;
                }
            }
        },
        findProg(prog) {
            for (const item of this.progBase) {
                if (item.prg === prog) {
                    return item;
                }
            }
        },
        async loadProg() {
            let response = [];
            this.progBase = [];
            if (this.curAbp !== null) {
                try {
                    response = await fetch('/api-py/get-dict-func-childs-by-parent-type/' + this.findAbp(this.curAbp).id + '/4');
                    // response = await fetch('/api-py/get-dict-func-childs-by-parent-type-budgetLevel/' + this.curAbp.id + '/4/' + encodeURI(JSON.stringify(this.budgetLevel)));
                    response = await response.json();
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log('error', error);
                    // this.makeToast('danger', 'Ошибка запроса loadEbkFunc', error.toString());
                }
            }
            response.sort((a, b) => (a.prg - b.prg > 0) ? 1 : -1);
            this.progBase = this.setIdArr(response, 'prg');

            if (this.progBase.length > 0) {
                this.curProg = this.progBase[0].prg;
            } else {
                this.curProg = null;
            }
            this.chgProg();
        },

        async loadSubProg() {
            let response = [];
            this.subProgBase = [];
            if (this.curProg !== null) {
                try {
                    response = await fetch('/api-py/get-dict-func-childs-by-parent-type/' + this.findProg(this.curProg).id + '/5');
                    // response = await fetch('/api-py/get-dict-func-childs-by-parent-type-budgetLevel/' + this.curProg.id + '/5/' + encodeURI(JSON.stringify([this.budgetLevel])));
                    response = await response.json();
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log('error', error);
                    response = [];
                    // this.makeToast('danger', 'Ошибка запроса loadEbkFunc', error.toString());
                }
            }
            response.sort((a, b) => (a.ppr - b.ppr > 0) ? 1 : -1);
            this.subProgBase = this.setIdArr(response, 'ppr');

            if (this.subProgBase.length > 0) {
                this.curSubProg = this.subProgBase[0];
            } else {
                this.curSubProg = null;
            }
        },
        chgProg() {
            this.loadSubProg();
        },
        setIdArr(arr, codeName) {
            const result = [];
            for (const el of arr) {
                if (result.length > 0 && el[codeName] === result[result.length - 1][codeName]) {
                    result[result.length - 1].idArr.push(el.id);
                } else {
                    result.push(Object.assign({ idArr: [el.id] }, el));
                }
            }
            return result;
        },
        chgAbp() {
            this.loadProg();
            if (this.curAbp !== null) {
                this.loadCompany();
            }
        },
        async loadSpec() {
            let result = [];
            try {
                result = await fetch('/api-py/dict-ebk-spf/')
                    .then(response => response.json());
            } catch (error) {
                result = [];
            }
            result.sort((a, b) => (a.ppr - b.ppr > 0) ? 1 : -1);
            this.specificBase = this.setIdArr(result, 'spf');

            if (this.specificBase.length > 0) {
                this.curSpecific = this.specificBase[0];
            } else {
                this.curSpecific = null;
            }
        }
    },
    mounted() {
        this.loadAbp();
        this.loadSpec();
    }
};
</script>

<style scoped>

</style>