












































import Class from './c-widget';
export default Class;
