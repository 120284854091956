import { Vue, Component } from 'vue-property-decorator';
import { accessor } from '@/modules/widget-yard/store';
import '@/modules/widget-yard/initialization';
import { TWidget } from '@/modules/widget-yard/store/types';
import CActualTab from './c-actual-tab';
import CWidgetTab from './c-widget-tab';

interface IData {
    checked: boolean | null;
    widget: TWidget;
}
interface ITabPlashkaData {
    indx: number;
    name: string;
    plashkaData: any;
    img: string | null;
}
interface IPlashkaData {
    checked: boolean | null;
    widget: TWidget;
}


@Component({
    components: {
        'c-actual-tab': CActualTab,
        'c-widget-tab': CWidgetTab
    }
})
export default class Socio extends Vue {
    private windowResizeEventHandler!: () => void;
    private windowClickListener: undefined | ((this: Window, ev: MouseEvent) => void);
    private tabPlashkaData: ITabPlashkaData[] = [];
    private tabIndex = 0;
    private tabData: ITabPlashkaData[] = [];
    private indxTab = 0;
    private tabPlash: ITabPlashkaData[] = [];
    private toggle = '<<';
    private isActive = true;
    private isTablet = false; // планшетная версия
    private isActualTablet = false;
    private colSum = 2;
    private colActual = 2;
    private keys = 0;
    private readonly maxTabWidth = 1200;
    private actualData: ITabPlashkaData = { indx: 0, name: 'Социально-экономические показатели', plashkaData: [], img: '/img/mangistau.jpeg' };

    private isMobile = false;
    private opened = false;

    private showSEI = false;


    private created() {
        this.windowResizeEventHandler = () => {
            if (window.innerWidth <= this.maxTabWidth) {
                this.colSum = 1;
                if ((window.innerWidth > 500) && (window.innerWidth < 900)) {
                    this.colActual = 2;
                } else {
                    this.colActual = 1;
                }
                this.isTablet = true;
            }

            if (window.innerWidth <= 800) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        };
        window.addEventListener('resize', this.windowResizeEventHandler);

        this.windowClickListener = () => {
            this.opened = false;
        };
        window.addEventListener('click', this.windowClickListener);
    }

    private updated() {
        this.plashkaIcons();
    }

    private columnClicked() {
        setTimeout(() => {
            this.opened = false;
        });
    }

    private get getTabPlashkaData() {
        if (this.tabPlashkaData.length === 0 && this.getPlashkaData() !== null && this.getPlashkaData().length > 0) {
            this.tabPlashkaData = [];
            this.tabPlashkaData.push({ indx: 111, name: 'Социально-экономические показатели', plashkaData: this.getPlashkaActual(), img: null });
            this.tabPlashkaData.push({ indx: 222, name: 'Все', plashkaData: this.getPlashkaData(), img: null });
            this.getPlashki();
        }
        return this.tabPlashkaData;
    }

    private getPlashkaActual() {
        const allWidgets: TWidget[] = accessor.widgets;
        const indicatorKeys: string[] = [];
        for (const widg of allWidgets) {
            if (widg.type === 'chart-actual') {
                widg.indicatorKeys.forEach((key) => {
                    indicatorKeys.push(key);
                });
            }
        }
        const data = {
            indicatorKeys: indicatorKeys
        };
        return data;
    }

    private getPlashkaData(): IPlashkaData[] {
        const allWidgets: TWidget[] = accessor.widgets;
        const data: IPlashkaData[] = [];
        for (const widg of allWidgets) {
            data.push({ checked: null, widget: widg });
        }
        return data;
    }
    private async getPlashki() { // получение плашек из сохранения
        const tabPlashkaData1: ITabPlashkaData[] = [];
        this.tabPlash.push({ indx: 222, name: 'Все', plashkaData: this.getPlashkaData(), img: null });
        if (this.tabPlashkaData.length === 0) { return; }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let savedArr: any[] = [];
        await fetch('/api/plashki/position')
            .then(response => response.json())
            .then(json => (savedArr = json));

        for (const obj of savedArr) {
            const data: ITabPlashkaData = { indx: obj.indx, name: obj.name, plashkaData: [], img: null };
            const plashkaData = this.getPlashkaData(); // this.tabPlash[0].plashkaData.slice();
            for (const objPlashkaData of obj.plashkaData) {
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let j = 0; j < plashkaData.length; j++) {
                    if (objPlashkaData === plashkaData[j].widget.id) {
                        data.plashkaData.push({ checked: true, widget: plashkaData[j].widget });
                        plashkaData.splice(j, 1);
                        break;
                    }
                }
            }

            if ((obj.indx !== 6) && (obj.indx !== 14)) {
                tabPlashkaData1.push(data);
            }
        }
        this.getTabPlashkaData.splice(1, 1);
        tabPlashkaData1.push(this.tabPlash[0]);
        this.tabPlashkaData = this.tabPlashkaData.concat(tabPlashkaData1);
    }

    private changetabIndex(index: number, tabData: ITabPlashkaData[]) {
        this.tabIndex = index;
        this.tabData = tabData;
        this.indxTab = this.tabIndex;

        if ((index === 0) || (index === 111)) {
            this.showSEI = !this.showSEI;
        }
    }

    private mainPanel() {
        this.isActive = !this.isActive;
        if (this.isActive === false) {
            this.toggle = '>>';
            this.colSum = (this.isTablet ? 2 : 3);
            this.colActual = (this.isTablet ? 2 : 3);
            this.keys = 1;
        } else {
            this.colSum = (this.isTablet ? 1 : 2);
            this.colActual = (this.isTablet ? 1 : 2);
            this.toggle = '<<';
            this.keys = 0;
        }
    }
    private plashkaIcons() {
        this.getTabPlashkaData.forEach((plashka) => {
            switch (plashka.indx) {
                case 1:
                    plashka.img = '/img/main-test/demography.png';
                    break;
                case 2:
                    plashka.img = '/img/main-test/zhkh.jpg';
                    break;
                case 3:
                    plashka.img = '/img/main-test/education.png';
                    break;
                case 4:
                    plashka.img = '/img/main-test/medicine.png';
                    break;
                case 5:
                    plashka.img = '/img/main-test/social.jpg';
                    break;
                case 6:
                    plashka.img = '/img/main-test/budget.png';
                    break;
                case 7:
                    plashka.img = '/img/main-test/construction.jfif';
                    break;
                case 8:
                    plashka.img = '/img/main-test/agricultural.jfif';
                    break;
                case 9:
                    plashka.img = '/img/main-test/transport.png';
                    break;
                case 10:
                    plashka.img = '/img/main-test/communication.jpg';
                    break;
                case 11:
                    plashka.img = '/img/main-test/industry.jpeg';
                    break;
                case 12:
                    plashka.img = '/img/main-test/trade.png';
                    break;
                case 13:
                    plashka.img = '/img/main-test/smbusiness.png';
                    break;
                case 14:
                    plashka.img = '/img/main-test/models.png';
                    break;
                case 15:
                    plashka.img = '/img/main-test/models.png';
                    break;
                case 111:
                    plashka.img = '/img/mangistau.jpeg';
                    break;
                case 222:
                    plashka.img = '/img/main-test/allWidgets.png';
                    break;
                default:
                    break;
            }

            /* this.getTabPlashkaData.forEach((plashka) => {
            if (plashka.name === 'Социально-экономические показатели') {
                const img = '/img/mangistau.jpeg';
                plashka.img = img;
            }
            if (plashka.name === 'ДЕМОГРАФИЯ') {
                const img = '/img/main-test/demography.png';
                plashka.img = img;
            }
            if (plashka.name === 'Социально-экономическая модель ВРП') {
                const img = '/img/main-test/models.png';
                plashka.img = img;
            }
            if (plashka.name === 'ЖКХ') {
                const img = '/img/main-test/zhkh.jpg';
                plashka.img = img;
            }
            if (plashka.name === 'ОБРАЗОВАНИЕ') {
                const img = '/img/main-test/education.png';
                plashka.img = img;
            }
            if (plashka.name === 'ЗДРАВООХРАНЕНИЕ') {
                const img = '/img/main-test/medicine.png';
                plashka.img = img;
            }
            if (plashka.name === 'ТРУД и СОЦИАЛЬНАЯ ЗАЩИТА') {
                const img = '/img/main-test/social.jpg';
                plashka.img = img;
            }
            if (plashka.name === 'БЮДЖЕТ ГОРОДА') {
                const img = '/img/main-test/budget.png';
                plashka.img = img;
            }
            if (plashka.name === 'БЮДЖЕТ МАНГИСТАУСКОЙ ОБЛАСТИ') {
                const img = '/img/main-test/budget.png';
                plashka.img = img;
            }
            if (plashka.name === 'СТРОИТЕЛЬСТВО') {
                const img = '/img/main-test/construction.jfif';
                plashka.img = img;
            }
            if (plashka.name === 'СЕЛЬСКОЕ ХОЗЯЙСТВО') {
                const img = '/img/main-test/agricultural.jfif';
                plashka.img = img;
            }
            if (plashka.name === 'ТРАНСПОРТ') {
                const img = '/img/main-test/transport.png';
                plashka.img = img;
            }
            if (plashka.name === 'СВЯЗЬ') {
                const img = '/img/main-test/communication.jpg';
                plashka.img = img;
            }
            if (plashka.name === 'ПРОМЫШЛЕННОСТЬ') {
                const img = '/img/main-test/industry.jpeg';
                plashka.img = img;
            }
            if (plashka.name === 'ТОРГОВЛЯ') {
                const img = '/img/main-test/trade.png';
                plashka.img = img;
            }
            if (plashka.name === 'МАЛЫЙ и СРЕДНИЙ БИЗНЕС') {
                const img = '/img/main-test/smbusiness.png';
                plashka.img = img;
            }
            if (plashka.name === 'Программа развития  г. Нур-Султана на 2016-2020 годы') {
                const img = '/img/main-test/development.jfif';
                plashka.img = img;
            }
            if (plashka.name === 'Все') {
                const img = '/img/main-test/allWidgets.png';
                plashka.img = img;
            } */
        });
    }
}