<template>
  <div class="row" style="height: 600px; flex-wrap: nowrap !important;">
    <div class="left-panel" :class="{'col-3': displaySection}" @mouseleave="displaySection = false" @mouseover="displaySection=true">
      <c-section v-show=true @selectTema='onSelectTema' @selectSection='onSelectSection'></c-section>
    </div>
    <b-col cols style="padding-left: 0">
      <b-tabs card>
        <b-tab title="Социальные сети" active>
          <c-soc-media v-if="theme !==null && Object.keys(theme).length !== 0"
            :type="1"
            :theme="theme"
            :section="section"
            :period="$route.query.period"
            :selPeriod="selectPeriod"
            :tone="$route.query.tone"
          ></c-soc-media>
        </b-tab>
        <b-tab title="Масс-медиа">
          <c-soc-media v-if="theme !==null && Object.keys(theme).length !== 0"
            :type="2"
            :theme="theme"
            :section="section"
            :period="$route.query.period"
            :selPeriod="selectPeriod"
            :tone="$route.query.tone"
          ></c-soc-media>
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>

<script lang="js">
import { Vue, Component } from 'vue-property-decorator'
import bootstrap, {
  BIcon,
  BIconThreeDots,
  BIconFilePlus,
  BIconCheckCircle,
  BIconXCircle,
  BIconArrowClockwise,
  BIconFolderPlus,
  BIconPen,
  BIconTrash
} from 'bootstrap-vue'
import CSection from '@/modules/topic/components/cSection.vue'
import cSocMedia from '@/modules/socialnet/components/cSocMedia.vue'

Vue.use(bootstrap)

@Component({
  components: {
    'c-section': CSection,
    'c-soc-media': cSocMedia,
    BIcon,
    BIconThreeDots,
    BIconFilePlus,
    BIconCheckCircle,
    BIconXCircle,
    BIconArrowClockwise,
    BIconFolderPlus,
    BIconPen,
    BIconTrash
  }
})
export default class Topic extends Vue {
  data () {
    return {
      theme: null,
      section: null
    }
  }

  displaySection = true
  selectPeriod = null

  mounted () {
    if (Object.keys(this.$route.query).length > 0) {
      if (this.$route.query.theme !== null) {
        this.theme = this.$route.query.theme
      }

      if ((this.$route.query.periodFrom !== null) && (this.$route.query.periodTo !== null)) {
        this.selectPeriod = []
        this.selectPeriod.push(new Date(this.$route.query.periodFrom))
        this.selectPeriod.push(new Date(this.$route.query.periodTo))
      }
    }
  }

  onSelectSection (data) {
    this.section = data.currentSection
  }

  onSelectTema (data) {
    this.theme = data.currentTema
  }

  get autorList () {
    const data = []
    for (let i = 0; i < 100; i++) {
      data.push(
        { name: i + ' пользователь', source: 'instagram', audienceSum: Math.round(1000 * Math.random() + Math.random() * 10) },
        { name: i + ' пользователь', source: 'facebook' }
      )
    }
    return data
  }

  makeToast (variant, tostbody, title) {
    this.$bvToast.toast(tostbody, {
      title: title,
      variant: variant,
      autoHideDelay: 2000,
      solid: true
    })
  }
}

</script>