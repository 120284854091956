




























































































































import Class from './s-widget';
export default Class;
