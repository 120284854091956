import { Component, Vue, Prop } from 'vue-property-decorator';

import '../../initialization';

import CContent from '../c-content';



interface ISection {
    id: number;
    name: string;
    src: string;
    widgets: any;
}

@Component({
    components: {
        'c-content': CContent
    }
})
export default class SWidget extends Vue {
    @Prop({
        type: Object,
        required: true
    })
    public readonly section!: ISection;
    @Prop({
        type: Boolean,
        required: false,
        default: false
    })
    public readonly board!: boolean;
    private curIndex = 0;
    private fullSize = false;
    private up() {
        if (this.curIndex < this.section.widgets.length) {
            this.curIndex++;
        }
    }
    private down() {
        if (this.curIndex > 0) {
            this.curIndex--;
        }
    }
    private fullSizible() {
        this.fullSize = !this.fullSize;
        this.$root.$emit('fullSizeActive', true);
    }

    private toMM() {
        const url = 'http://mm.csi.kz/main';
        window.location.href = url;
    }

    private toEco() {
        const url = {
            path: '/soc-eco',
            query: {
                name: 'Основные показатели' as any,
                code: '01.01' as any,
                id: 211 as any,
                href: '/soc-eco' as any
            }
        };
        this.$router.push(url);
    }

    private toMod() {
        const url = {
            path: '/soc-mod',
            query: {
                name: 'Основные показатели' as any,
                code: '02.01' as any,
                id: 57335 as any,
                href: '/soc-mod' as any
            }
        };
        this.$router.push(url);
    }

    private toMonitoring() {
        const url = {
            path: '/monitoring',
            query: {
                report: true as any
            }
        };
        this.$router.push(url);
    }
}