<template>
    <b-container>
        <div class="section-title budget">Заключение областной бюджетной комиссии</div>
        <b-card>
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm="3"
                content-cols-lg="3"
                label="На период"
            >
                <b-form-select :options="options"></b-form-select>
            </b-form-group>
            <div class="text-right">
                <b-button variant="primary" v-b-modal.modal-1>Сформировать План Госзакупок</b-button>
                <b-button variant="primary" class="ml-3" href="#/demo-budget-execution/request/budget-plan/">
                    Сформировать План Финансирования
                </b-button>
            </div>
        </b-card>
        <b-table :fields="tableFields" bordered no-border-collapse striped thead-class="bold_header" :items="tableData" caption-top>
            <template #table-caption ><div class="text-right">тыс. тенге</div></template>
            <template #cell(f6)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f7)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
            <template #cell(f8)="data">
                <div class="text-right">
                    {{ $n(data.value) }}
                </div>
            </template>
        </b-table>
        <div class="text-right mt-3">
            <b-button class="m-1">Скачать в xls</b-button>
        </div>
        <b-modal id="modal-1" title="Результат" ok-only>
            План государственных закупок сформирован !
        </b-modal>
    </b-container>
</template>

<script>
export default {
    name: 'BudgetCommission1',
    data() {
        return {
            options: [
                2022, 2023, 2024
            ],
            tableFields: [
                {
                    key: 'f1',
                    label: 'АБП'
                },
                {
                    key: 'f2',
                    label: 'БП'
                },
                {
                    key: 'f3',
                    label: 'ПП'
                },
                {
                    key: 'f4',
                    label: 'Специфика'
                },
                {
                    key: 'f5',
                    label: 'Проекты'
                },
                {
                    key: 'f6',
                    label: 'Расходы по БЗ АБП в пределах доведенного лимита'
                },
                {
                    key: 'f7',
                    label: 'Заявлено расходов на новые инициативы на ОБК'
                },
                {
                    key: 'f8',
                    label: 'Решено ОБК учесть расходы на новые инициативы'
                },
                {
                    key: 'f9',
                    label: 'Решение ОБК'
                },
                {
                    key: 'f10',
                    label: 'Обоснование'
                }

            ],
            tableData: [
                {
                    'f1': 271,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': '',
                    'f6': 3469920,
                    'f7': 30422880,
                    'f8': 24336441,
                    'f9': '',
                    'f10': ''
                },
                {
                    'f1': null,
                    'f2': 86,
                    'f3': null,
                    'f4': null,
                    'f5': '',
                    'f6': 2479934,
                    'f7': 2274637,
                    'f8': 1822825,
                    'f9': '',
                    'f10': ''
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': 15,
                    'f4': null,
                    'f5': '',
                    'f6': 2479934,
                    'f7': 2274637,
                    'f8': 1822825,
                    'f9': '',
                    'f10': ''
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': 431,
                    'f5': '',
                    'f6': 2479934,
                    'f7': 2274637,
                    'f8': 1822825,
                    'f9': '',
                    'f10': ''
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Проектирование жилого микрорайона "Достык" в г. Павлодар. Строительство СОШ на 1200 мест',
                    'f6': 2479934,
                    'f7': '',
                    'f8': '',
                    'f9': 'Поддержан',
                    'f10': ''
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар',
                    'f6': '',
                    'f7': 1371014,
                    'f8': 1371014,
                    'f9': 'Поддержан',
                    'f10': ''
                },
                {
                    'f1': null,
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 'Строительство пристройки на 348 мест в СОШГ №9 г. Павлодар',
                    'f6': '',
                    'f7': 903623,
                    'f8': 451811,
                    'f9': 'Частично поддержан',
                    'f10': 'В связи с недостатком бюджетных средств'
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>