import { Component, Vue } from 'vue-property-decorator';
import store from '@/services/store';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import VueElementLoading from 'vue-element-loading';
import axios from 'axios';

@Component({
    components: {
        'treeselect': Treeselect,
        'loading': VueElementLoading
    }
})

export default class Forms extends Vue {
    public get isAuthorized(): boolean {
        return store.state.user.authorized;
    }

    private currentKatoData1: any[] = [];

    private inputFormsData: any[] = [];

    private selectedForm2: any = {};

    private selectedKato2: any = null;

    private selectedDirectoryFirst2: any = null;
    private selectedDirectorySecond2: any = null;

    private currentDirectoryFirstTitle = '';
    private currentDirectoryFirstData: any[] = [];

    private currentDirectorySecondTitle = '';
    private currentDirectorySecondData: any[] = [];

    private indicators2: any[] = [];

    private indicatorTitles: object[] = [];

    private allDataTypes = new Set();

    private isShow = true;

    private katoListItems: any = [];

    private currentFormDataTypes: any[] = [];

    private currentData: any[] = [];

    private selectedDate: [number, number, number] = [0, 0, 0];
    private selectedDayDate: any = '';
    private selectedDates: any[] = [];
    private katoListData: any[] = [];

    private async getSelectedForm() {
        let lists: any[] = [];
        await fetch(`/api/input-forms/${this.selectedForm2.id}`)
            .then(response => response.json())
            .then(json => (lists = json));
        return lists;
    }

    private async created() {
        this.inputFormsData = await this.inputForms();

        this.$watch('selectedDayDate', () => {
            const day = this.selectedDayDate.split('-');
            if (day.length === 2) {
                this.selectedDate = [parseInt(day[0]), parseInt(day[1]), 0];
            }
            if (day.length === 3) {
                this.selectedDate = [parseInt(day[0]), parseInt(day[1]) - 1, parseInt(day[2])];
            }
        });

        this.$watch('selectedDate', async () => {
            this.selectedKato2 = null;
            await this.getCurrentData(new Date(...this.selectedDate).getTime());
        });

        this.$watch('selectedForm2', async () => {
            const getForm: any = await this.getSelectedForm();
            this.katoListItems = await this.getSelectedForm();
            this.allDataTypes = new Set<any>();
            this.selectedDate = [0, 0, 0];
            this.selectedDates = [];

            this.indicatorTitles = [];

            this.currentDirectoryFirstData = [];

            this.currentDirectoryFirstTitle = '';
            this.currentDirectorySecondData = [];

            this.currentDirectorySecondTitle = '';

            this.selectedKato2 = null;
            this.selectedDirectoryFirst2 = null;
            this.selectedDirectorySecond2 = null;

            this.currentKatoData1 = [];
            this.katoListData = [];

            if (this.selectedForm2.dataPeriodicity && this.selectedForm2.dataPeriodicity.name_ru === 'Год') {
                for (let i = new Date().getFullYear() - 20; i < new Date().getFullYear() + 20; i++) {
                    this.selectedDates.push({
                        value: [new Date(i.toString()).getFullYear() + 1, 0, 0],
                        text: i.toString() + ' год'
                    });
                }
                this.selectedDate = [new Date().getFullYear() + 1, 0, 0];
            } else if (this.selectedForm2.dataPeriodicity && (this.selectedForm2.dataPeriodicity.name_ru === 'Месяц'
                || this.selectedForm2.dataPeriodicity.name_ru === 'Месяц с накоплением')) {
                if (new Date().getMonth() < 9) {
                    this.selectedDayDate = `${new Date().getFullYear()}-0${new Date().getMonth() + 1}`;
                } else {
                    this.selectedDayDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
                }
            } else if (this.selectedForm2.dataPeriodicity && (this.selectedForm2.dataPeriodicity.name_ru === 'Квартал'
                || this.selectedForm2.dataPeriodicity.name_ru === 'Квартал с накоплением')) {
                for (let i = new Date().getFullYear() - 20; i < new Date().getFullYear() + 20; i++) {
                    const quarter = ['I', 'II', 'III', 'IV'];

                    this.selectedDates.push({
                        value: [i, new Date(`${i}-04`).getMonth(), 0],
                        text: `${quarter[0]} квартал ${i} года`
                    });
                    this.selectedDates.push({
                        value: [i, new Date(`${i}-07`).getMonth(), 0],
                        text: `${quarter[1]} квартал ${i} года`
                    });
                    this.selectedDates.push({
                        value: [i, new Date(`${i}-10`).getMonth(), 0],
                        text: `${quarter[2]} квартал ${i} года`
                    });
                    this.selectedDates.push({
                        value: [i + 1, new Date(`${i}-01`).getMonth(), 0],
                        text: `${quarter[3]} квартал ${i} года`
                    });
                }
                const currentMonth = new Date().getMonth() + 1;
                if (currentMonth >= 1 && currentMonth < 4) {
                    this.selectedDate = [new Date().getFullYear(), 3, 0];
                } else if (currentMonth >= 4 && currentMonth < 7) {
                    this.selectedDate = [new Date().getFullYear(), 6, 0];
                } else if (currentMonth >= 7 && currentMonth < 10) {
                    this.selectedDate = [new Date().getFullYear(), 9, 0];
                } else if (currentMonth >= 10 && currentMonth < 13) {
                    this.selectedDate = [new Date().getFullYear() + 1, 0, 0];
                }
            } else if (this.selectedForm2.dataPeriodicity && (this.selectedForm2.dataPeriodicity.name_ru === 'Полугодие'
                || this.selectedForm2.dataPeriodicity.name_ru === 'Полугодие с накоплением')) {
                for (let i = new Date().getFullYear() - 20; i < new Date().getFullYear() + 20; i++) {
                    const quarter = ['I', 'II'];

                    this.selectedDates.push({
                        value: [i, new Date(`${i + 1}-07`).getMonth(), 0],
                        text: `${quarter[0]} полугодие ${i} года`
                    });
                    this.selectedDates.push({
                        value: [i + 1, new Date(`${i}-01`).getMonth(), 0],
                        text: `${quarter[1]} полугодие ${i} года`
                    });
                }
                const currentHalfAYear = new Date().getMonth() + 1;
                if (currentHalfAYear >= 1 && currentHalfAYear < 7) {
                    this.selectedDate = [new Date().getFullYear(), 6, 0];
                } else if (currentHalfAYear >= 7 && currentHalfAYear < 13) {
                    this.selectedDate = [new Date().getFullYear() + 1, 0, 0];
                }
            } else {
                if (new Date().getMonth() + 1 >= 10 && new Date().getDate() >= 10) {
                    this.selectedDayDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
                } else if (new Date().getMonth() + 1 < 10 && new Date().getDate() >= 10) {
                    this.selectedDayDate = `${new Date().getFullYear()}-0${new Date().getMonth() + 1}-${new Date().getDate()}`;
                } else if (new Date().getMonth() + 1 >= 10 && new Date().getDate() < 10) {
                    this.selectedDayDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-0${new Date().getDate()}`;
                } else if (new Date().getMonth() + 1 < 10 && new Date().getDate() < 10) {
                    this.selectedDayDate = `${new Date().getFullYear()}-0${new Date().getMonth() + 1}-0${new Date().getDate()}`;
                }
            }

            this.katoListData = getForm.dictKatoItemsDto;

            const map: any = {};
            // eslint-disable-next-line no-return-assign
            this.katoListData.forEach((item: any) => item.code ? map[item.code] = item : null);
            for (let i = 0; i < this.katoListData.length; i++) {
                const categ = this.katoListData[i];
                if (categ.code) {
                    if (categ.code !== '000000000' && categ.code.substr(2) === '0000000') {
                        if (map['000000000']) {
                            if (!map['000000000'].children) {
                                map['000000000'].children = [];
                            }
                            map['000000000'].children.push(categ);
                            this.katoListData.splice(i, 1);
                            i--;
                        }
                    } else if (categ.code !== '000000000' && categ.code.substr(4) === '00000') {
                        if (map[categ.code.substr(0, 2) + '0000000']) {
                            if (!map[categ.code.substr(0, 2) + '0000000'].children) {
                                map[categ.code.substr(0, 2) + '0000000'].children = [];
                            }
                            map[categ.code.substr(0, 2) + '0000000'].children.push(categ);
                            this.katoListData.splice(i, 1);
                            i--;
                        }
                    } else if (categ.code !== '000000000' && categ.code.substr(6) === '000') {
                        if (map[categ.code.substr(0, 4) + '00000']) {
                            if (!map[categ.code.substr(0, 4) + '00000'].children) {
                                map[categ.code.substr(0, 4) + '00000'].children = [];
                            }
                            map[categ.code.substr(0, 4) + '00000'].children.push(categ);
                            this.katoListData.splice(i, 1);
                            i--;
                        }
                    } else if (categ.code !== '000000000' && categ.code.substr(7) === '00') {
                        if (map[categ.code.substr(0, 6) + '000']) {
                            if (!map[categ.code.substr(0, 6) + '000'].children) {
                                map[categ.code.substr(0, 6) + '000'].children = [];
                            }
                            map[categ.code.substr(0, 6) + '000'].children.push(categ);
                            this.katoListData.splice(i, 1);
                            i--;
                        }
                    } else if (categ.code !== '000000000' && categ.code.substr(7) !== '00') {
                        if (map[categ.code.substr(0, 7) + '00']) {
                            if (!map[categ.code.substr(0, 7) + '00'].children) {
                                map[categ.code.substr(0, 7) + '00'].children = [];
                            }
                            map[categ.code.substr(0, 7) + '00'].children.push(categ);
                            this.katoListData.splice(i, 1);
                            i--;
                        }
                    }
                }
            }

            this.currentKatoData1 = [...this.katoListData];
            this.loading = false;
        });

        this.$watch('selectedKato2', async () => {
            this.allDataTypes = new Set<any>();
            this.indicatorTitles = [];

            this.currentDirectoryFirstData = [];
            this.selectedDirectoryFirst2 = null;

            this.currentDirectorySecondData = [];
            this.selectedDirectorySecond2 = null;

            this.currentDirectoryFirstTitle = '';

            if (this.selectedKato2 !== null && typeof this.selectedKato2 !== 'undefined') {
                if (this.selectedForm2.dict1 !== null && this.selectedForm2.dict1ItemsText !== '') {
                    const directoryFirstData = await getDict1(this.selectedForm2);

                    this.currentDirectoryFirstData = [];

                    const seletecedDict1: any[] = [];
                    const currentDirectoryFirst = this.selectedForm2.dict1ItemsText.split(',');
                    currentDirectoryFirst.forEach((dict1: any) => {
                        if (!isNaN(parseInt(dict1))) {
                            const item = directoryFirstData.find((x: any) => x.id === parseInt(dict1));
                            if (typeof item !== 'undefined') {
                                seletecedDict1.push(item);
                            }
                        }
                    });
                    seletecedDict1.forEach((categ: any) => {
                        categ.children = [];
                        // eslint-disable-next-line for-direction
                        for (let i = seletecedDict1.length - 1; i > -1; --i) {
                            if (categ.children !== undefined) {
                                if (categ.id === seletecedDict1[i].parent) {
                                    seletecedDict1[i].customLabel = seletecedDict1[i].name_ru;
                                    categ.children.push(seletecedDict1[i]);
                                    seletecedDict1.splice(i, 1);
                                }
                                categ.children.sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id));
                            }
                        }
                        if (categ.children.length === 0) {
                            delete categ.children;
                        }
                    });
                    this.currentDirectoryFirstData = seletecedDict1;

                    this.currentDirectoryFirstTitle = this.selectedForm2.dict1.name_ru;
                } else {
                    this.indicators2 = this.selectedForm2.indicators;

                    let dict1Data: any[] = [];
                    let dict2Data: any[] = [];

                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < this.indicators2.length; i++) {
                        let currentDataTypes: any = new Set();

                        const itemObj: any = {};

                        // eslint-disable-next-line no-await-in-loop
                        dict1Data = await getDict1(this.indicators2[i]);
                        // eslint-disable-next-line no-await-in-loop
                        dict2Data = await getDict2(this.indicators2[i]);

                        this.indicators2[i].dataTypes.forEach((type: any) => {
                            if (type.dataType.name_ru === 'План') {
                                itemObj.valuePlan = 0;
                            } else if (type.dataType.name_ru === 'Прогноз') {
                                itemObj.valueForecast = 0;
                            } else if (type.dataType.name_ru === 'Факт') {
                                itemObj.valueFact = 0;
                            }
                        });

                        this.indicators2[i].dataTypes.forEach((type: any) => {
                            currentDataTypes.add(type);
                            this.allDataTypes.add(type.dataType.name_ru);
                        });

                        this.currentFormDataTypes = Array.from(this.allDataTypes).sort((a: any, b: any) => {
                            if (a < b) { return -1; }
                            if (a > b) { return 1; }
                            return 0;
                        });

                        currentDataTypes = Array.from(currentDataTypes).sort((a: any, b: any) => {
                            if (a.dataType.name_ru < b.dataType.name_ru) { return -1; }
                            if (a.dataType.name_ru > b.dataType.name_ru) { return 1; }
                            return 0;
                        });

                        const sortDataTypes = this.indicators2[i].dataTypes.sort((a: any, b: any) => {
                            if (a.dataType.name_ru < b.dataType.name_ru) { return -1; }
                            if (a.dataType.name_ru > b.dataType.name_ru) { return 1; }
                            return 0;
                        });

                        const isShow = dict1Data && dict1Data.length > 0 ? { isShow: true } : { isShow: null };

                        this.indicatorTitles.push(
                            {
                                id: this.indicators2[i].id,
                                title: { ...this.indicators2[i].indicator, ...itemObj, ...isShow, available: true },
                                dictFirst: getDirectory2(this.indicators2[i].dict1ItemsText, this.indicators2[i].dict2ItemsText, dict1Data, dict2Data, itemObj),
                                dataTypes: sortDataTypes,
                                unit: this.indicators2[i].unit,
                                typesss: currentDataTypes
                            });
                    }

                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    getCurrentTypes();
                    await this.getCurrentData(new Date(...this.selectedDate).getTime());
                }
            }
        });

        this.$watch('selectedDirectoryFirst2', async () => {
            this.allDataTypes = new Set<any>();
            this.indicatorTitles = [];

            this.currentDirectorySecondData = [];
            this.currentDirectorySecondTitle = '';

            this.selectedDirectorySecond2 = null;

            this.currentDirectorySecondData = [];

            if (this.selectedDirectoryFirst2 !== null && typeof this.selectedDirectoryFirst2 !== 'undefined') {
                if (this.selectedForm2.dict2 !== null && this.selectedForm2.dict2ItemsText !== '') {
                    const directorySecondData = await getDict2(this.selectedForm2);

                    this.currentDirectorySecondData = [];

                    const seletecedDict2: any[] = [];
                    const currentDirectorySecond = this.selectedForm2.dict2ItemsText.split(',');
                    currentDirectorySecond.forEach((dict1: any) => {
                        if (!isNaN(parseInt(dict1))) {
                            const item = directorySecondData.find((x: any) => x.id === parseInt(dict1));
                            if (typeof item !== 'undefined') {
                                seletecedDict2.push(item);
                            }
                        }
                    });
                    seletecedDict2.forEach((categ: any) => {
                        categ.children = [];
                        // eslint-disable-next-line for-direction
                        for (let i = seletecedDict2.length - 1; i > -1; --i) {
                            if (categ.children !== undefined) {
                                if (categ.id === seletecedDict2[i].parent) {
                                    seletecedDict2[i].customLabel = seletecedDict2[i].name_ru;
                                    categ.children.push(seletecedDict2[i]);
                                    seletecedDict2.splice(i, 1);
                                }
                                categ.children.sort((a: any, b: any) => parseInt(a.id) - parseInt(b.id));
                            }
                        }
                        if (categ.children.length === 0) {
                            delete categ.children;
                        }
                    });
                    this.currentDirectorySecondData = seletecedDict2;

                    this.currentDirectorySecondTitle = this.selectedForm2.dict2.name_ru;
                } else {
                    this.indicators2 = this.selectedForm2.indicators;

                    let dict1Data: any[] = [];
                    let dict2Data: any[] = [];

                    // eslint-disable-next-line @typescript-eslint/prefer-for-of
                    for (let i = 0; i < this.indicators2.length; i++) {
                        let currentDataTypes: any = new Set();

                        // eslint-disable-next-line no-await-in-loop
                        dict1Data = await getDict1(this.indicators2[i]);
                        // eslint-disable-next-line no-await-in-loop
                        dict2Data = await getDict2(this.indicators2[i]);

                        const itemObj: any = {};

                        this.indicators2[i].dataTypes.forEach((type: any) => {
                            if (type.dataType.name_ru === 'План') {
                                itemObj.valuePlan = 0;
                            } else if (type.dataType.name_ru === 'Прогноз') {
                                itemObj.valueForecast = 0;
                            } else if (type.dataType.name_ru === 'Факт') {
                                itemObj.valueFact = 0;
                            }
                        });


                        this.indicators2[i].dataTypes.forEach((type: any) => {
                            currentDataTypes.add(type);
                            this.allDataTypes.add(type.dataType.name_ru);
                        });

                        this.currentFormDataTypes = Array.from(this.allDataTypes).sort((a: any, b: any) => {
                            if (a < b) {
                                return -1;
                            } if (a > b) {
                                return 1;
                            }
                            return 0;
                        });

                        currentDataTypes = Array.from(currentDataTypes).sort((a: any, b: any) => {
                            if (a.dataType.name_ru < b.dataType.name_ru) {
                                return -1;
                            } if (a.dataType.name_ru > b.dataType.name_ru) {
                                return 1;
                            }
                            return 0;
                        });

                        const sortDataTypes = this.indicators2[i].dataTypes.sort((a: any, b: any) => {
                            if (a.dataType.name_ru < b.dataType.name_ru) {
                                return -1;
                            } if (a.dataType.name_ru > b.dataType.name_ru) {
                                return 1;
                            }
                            return 0;
                        });

                        const isShow = dict1Data && dict1Data.length > 0 ? { isShow: true } : { isShow: null };

                        this.indicatorTitles.push(
                            {
                                id: this.indicators2[i].id,
                                title: { ...this.indicators2[i].indicator, ...itemObj, ...isShow, available: true },
                                dictFirst: getDirectory2(this.indicators2[i].dict1ItemsText, this.indicators2[i].dict2ItemsText, dict1Data, dict2Data, itemObj),
                                dataTypes: sortDataTypes.reverse(),
                                unit: this.indicators2[i].unit,
                                typesss: currentDataTypes
                            });
                    }

                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    getCurrentTypes();

                    await this.getCurrentData(new Date(...this.selectedDate).getTime());
                }
            }
        });

        this.$watch('selectedDirectorySecond2', async () => {
            this.allDataTypes = new Set<any>();
            this.indicatorTitles = [];
            this.indicators2 = this.selectedForm2.indicators;

            if (this.selectedDirectorySecond2 !== null && typeof this.selectedDirectorySecond2 !== 'undefined') {
                let dict1Data: any[] = [];
                let dict2Data: any[] = [];

                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for (let i = 0; i < this.indicators2.length; i++) {
                    let currentDataTypes: any = new Set();

                    // eslint-disable-next-line no-await-in-loop
                    dict1Data = await getDict1(this.indicators2[i]);
                    // eslint-disable-next-line no-await-in-loop
                    dict2Data = await getDict2(this.indicators2[i]);

                    const itemObj: any = {};

                    this.indicators2[i].dataTypes.forEach((type: any) => {
                        if (type.dataType.name_ru === 'План') {
                            itemObj.valuePlan = 0;
                        } else if (type.dataType.name_ru === 'Прогноз') {
                            itemObj.valueForecast = 0;
                        } else if (type.dataType.name_ru === 'Факт') {
                            itemObj.valueFact = 0;
                        }
                    });


                    this.indicators2[i].dataTypes.forEach((type: any) => {
                        currentDataTypes.add(type);
                        this.allDataTypes.add(type.dataType.name_ru);
                    });

                    this.currentFormDataTypes = Array.from(this.allDataTypes).sort((a: any, b: any) => {
                        if (a < b) { return -1; }
                        if (a > b) { return 1; }
                        return 0;
                    });

                    currentDataTypes = Array.from(currentDataTypes).sort((a: any, b: any) => {
                        if (a.dataType.name_ru < b.dataType.name_ru) { return -1; }
                        if (a.dataType.name_ru > b.dataType.name_ru) { return 1; }
                        return 0;
                    });


                    const sortDataTypes = this.indicators2[i].dataTypes.sort((a: any, b: any) => {
                        if (a.dataType.name_ru < b.dataType.name_ru) { return -1; }
                        if (a.dataType.name_ru > b.dataType.name_ru) { return 1; }
                        return 0;
                    });

                    const isShow = dict1Data && dict1Data.length > 0 ? { isShow: true } : { isShow: null };

                    this.indicatorTitles.push(
                        {
                            id: this.indicators2[i].id,
                            title: { ...this.indicators2[i].indicator, ...itemObj, ...isShow, available: true },
                            dictFirst: getDirectory2(this.indicators2[i].dict1ItemsText, this.indicators2[i].dict2ItemsText, dict1Data, dict2Data, itemObj),
                            dataTypes: sortDataTypes,
                            unit: this.indicators2[i].unit,
                            typesss: currentDataTypes
                        });
                }

                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                getCurrentTypes();

                await this.getCurrentData(new Date(...this.selectedDate).getTime());
            }
        });

        function getDirectory2(dict1: string, dict2: string, dictData1: any[], dictData2: any[], typeValue: object) {
            const result: any[] = [];


            if (dict1 !== '' && dict2 !== '') {
                const dictFirst = dict1.split(',');
                dictFirst.pop();

                dictFirst.forEach((currItem: any) => {
                    dictData1.forEach((item: any) => {
                        if (Number(item.id) === Number(currItem)) {
                            result.push({ ...item, ...typeValue, isShow: true, available: true });
                        }
                    });
                });

                const dictSecond = dict2.split(',');
                dictSecond.pop();
                result.forEach((el: any) => {
                    el.dict2Child = [];
                    dictSecond.forEach((currItem: any) => {
                        dictData2.forEach((item: any) => {
                            if (Number(item.id) === Number(currItem)) {
                                el.dict2Child.push({ ...item, ...typeValue, isShow: true });
                            }
                        });
                    });
                });
            } else if (dict1 !== '' && dict2 === '') {
                const dictFirst = dict1.split(',');
                dictFirst.pop();

                dictFirst.forEach((currItem: any) => {
                    dictData1.forEach((item: any) => {
                        if (Number(item.id) === Number(currItem)) {
                            result.push({ ...item, ...typeValue, available: true });
                        }
                    });
                });
            }

            return result;
        }

        async function getDict1(dict: any) {
            if (dict.dict1 !== null) {
                const result = await fetch(`/api/${dict.dict1.resource}/${dict.dict1.code}`);
                // eslint-disable-next-line no-return-await
                return await result.json();
            }
            return null;
        }
        async function getDict2(dict: any) {
            if (dict.dict2 !== null) {
                const result = await fetch(`/api/${dict.dict2.resource}/${dict.dict2.code}`);
                // eslint-disable-next-line no-return-await
                return await result.json();
            }
            return null;
        }

        const getCurrentTypes = () => {
            const arr = [...this.currentFormDataTypes];
            this.indicatorTitles.forEach((el: any) => {
                const result: any = [];

                const nullId = this.indicatorTitles.indexOf(el);
                for (let i = 0; i < arr.length; i++) {
                    result.push({ id: i + nullId });
                }

                arr.forEach((a: any) => {
                    el.typesss.forEach((t: any) => {
                        if (t.dataType.name_ru === a) {
                            result[arr.indexOf(a)] = t;
                        }
                    });
                });
                el.typess = result;
            });
        };
    }

    private async onClick() {
        this.loading = true;

        const periodicity: any = this.selectedForm2.dataPeriodicity;

        const date = new Date(...this.selectedDate).getTime();

        const dict1ItemId = this.selectedDirectoryFirst2 ? this.selectedDirectoryFirst2 : null;

        const dict2ItemId = this.selectedDirectorySecond2 ? this.selectedDirectorySecond2 : null;

        const statKato = this.katoListItems.dictKatoItemsDto.find((currentKato: any) => currentKato.id === this.selectedKato2);

        for (const item of this.indicatorTitles) {
            const indicatorItem: any = item;
            const postList: any[] = [];

            const dataTypeMap = new Map();
            indicatorItem.dataTypes.forEach((type: any) => {
                if (type.dataType.name_en === 'Forecast') {
                    dataTypeMap.set('Forecast', type.dataType);
                } else if (type.dataType.name_en === 'Fact') {
                    dataTypeMap.set('Fact', type.dataType);
                } else if (type.dataType.name_en === 'Plan') {
                    dataTypeMap.set('Plan', type.dataType);
                }
            });

            const itemPostElem: any = {};
            itemPostElem.date = date;
            itemPostElem.dataPeriodicity = { ...periodicity };
            itemPostElem.statKato = { ...statKato };
            itemPostElem.dict1ItemId = dict1ItemId;
            itemPostElem.dict2ItemId = dict2ItemId;
            itemPostElem.indicatorDict1ItemId = null;
            itemPostElem.indicatorDict2ItemId = null;

            const values = ['valueForecast', 'valueFact', 'valuePlan'];

            values.forEach((valueType: any) => {
                if (indicatorItem.title.hasOwnProperty(valueType) && !indicatorItem.title.child && indicatorItem.title.available) {
                    if (indicatorItem.title.valueId) {
                        itemPostElem.id = indicatorItem.title.valueId;
                    } else {
                        itemPostElem.id = null;
                    }
                    itemPostElem.dataType = { ...dataTypeMap.get(valueType.replace('value', '')) };
                    itemPostElem.value = indicatorItem.title[valueType];
                    postList.push({ ...itemPostElem });
                }
            });

            if (indicatorItem.dictFirst && indicatorItem.dictFirst.length > 0) {
                indicatorItem.dictFirst.forEach((dict1: any) => {
                    if (dict1.valueId) {
                        itemPostElem.id = dict1.valueId;
                    } else {
                        itemPostElem.id = null;
                    }
                    itemPostElem.indicatorDict1ItemId = null;
                    itemPostElem.indicatorDict2ItemId = null;
                    itemPostElem.indicatorDict1ItemId = dict1.id;

                    values.forEach((valueTypeChild: any) => {
                        if (dict1.hasOwnProperty(valueTypeChild) && dict1.available) {
                            itemPostElem.dataType = { ...dataTypeMap.get(valueTypeChild.replace('value', '')) };
                            if (indicatorItem.title.child && dict1.toParent && !dict1.child) {
                                itemPostElem.value = indicatorItem.title[valueTypeChild];
                                postList.push({ ...itemPostElem });
                            } else if (!dict1.child) {
                                itemPostElem.value = dict1[valueTypeChild];
                                postList.push({ ...itemPostElem });
                            }
                        }
                    });

                    if (dict1.dict2Child && dict1.dict2Child.length > 0) {
                        dict1.dict2Child.forEach((dict2: any) => {
                            if (dict2.valueId) {
                                itemPostElem.id = dict2.valueId;
                            } else {
                                itemPostElem.id = null;
                            }
                            itemPostElem.indicatorDict2ItemId = dict2.id;

                            values.forEach((valueTypeChildish: any) => {
                                if (dict2.hasOwnProperty(valueTypeChildish)) {
                                    itemPostElem.dataType = { ...dataTypeMap.get(valueTypeChildish.replace('value', '')) };
                                    if (dict1.child && dict2.toParent) {
                                        if (dict1.toParent && dict2.toParent) {
                                            itemPostElem.value = indicatorItem.title[valueTypeChildish];
                                            postList.push({ ...itemPostElem });
                                        } else {
                                            itemPostElem.value = dict1[valueTypeChildish];
                                            postList.push({ ...itemPostElem });
                                        }
                                    } else {
                                        itemPostElem.value = dict2[valueTypeChildish];
                                        postList.push({ ...itemPostElem });
                                    }
                                }
                            });
                        });
                    }
                });
            }
            try {
                const headers = {
                    'Content-Type': 'application/json'
                };
                const response = await axios.post(`/api/input-forms/data-list/${indicatorItem.id}`, postList, { headers });
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        }
        this.loading = false;
    }

    private async inputForms() {
        let lists: any = [];
        await fetch('/api/input-forms')
            .then(response => response.json())
            .then(json => (lists = json));
        lists.forEach((list: any) => {
            if (list.dataPeriodicity !== null) {
                list.name += ` (${list.dataPeriodicity.name_ru})`;
            }
        });
        lists.sort((a: any, b: any) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
        lists.forEach((list: any) => {
            list.indicators.sort((a: any, b: any) => a.index - b.index);
        });
        this.formsOption = lists;
        this.loading = false;
        return lists;
    }

    private async resetFormData() {
        await this.deleteCurrentData(new Date(...this.selectedDate).setHours(0));
    }

    private async deleteCurrentData(dateOnMillisecond: any) {
        this.loading = true;
        try {
            for (const indicatorItem of this.indicatorTitles) {
                const item: any = indicatorItem;
                let response: any[] = [];

                if (this.selectedKato2 !== null && typeof this.selectedKato2 !== 'undefined') {
                    // eslint-disable-next-line no-await-in-loop
                    response = await axios.get(`/api/input-forms/data/${item.id}`)
                        .then(res => res.data)
                        .then((resp: any) => resp.filter((r: any) => new Date(r.date).setHours(0) === dateOnMillisecond
                            && r.dataPeriodicity.name_ru === this.selectedForm2.dataPeriodicity.name_ru
                            && r.statKato.id === this.selectedKato2
                        ));
                } else if (this.selectedDirectoryFirst2 !== null && typeof this.selectedDirectoryFirst2 !== 'undefined'
                    && this.selectedKato2 !== null && typeof this.selectedKato2 !== 'undefined') {
                    // eslint-disable-next-line no-await-in-loop
                    response = await axios.get(`/api/input-forms/data/${item.id}`)
                        .then(res => res.data)
                        .then((resp: any) => resp.filter((r: any) => new Date(r.date).setHours(0) === dateOnMillisecond
                            && r.dataPeriodicity.name_ru === this.selectedForm2.dataPeriodicity.name_ru
                            && r.dict1ItemId === this.selectedDirectoryFirst2
                            && r.statKato.id === this.selectedKato2
                        ));
                } else if (this.selectedDirectoryFirst2 !== null && typeof this.selectedDirectoryFirst2 !== 'undefined'
                    && this.selectedDirectorySecond2 !== null && typeof this.selectedDirectorySecond2 !== 'undefined'
                    && this.selectedKato2 !== null && typeof this.selectedKato2 !== 'undefined') {
                    // eslint-disable-next-line no-await-in-loop
                    response = await axios.get(`/api/input-forms/data/${item.id}`)
                        .then(res => res.data)
                        .then((resp: any) => resp.filter((r: any) => new Date(r.date).setHours(0) === dateOnMillisecond
                            && r.dataPeriodicity.name_ru === this.selectedForm2.dataPeriodicity.name_ru
                            && r.dict1ItemId === this.selectedDirectoryFirst2
                            && r.dict2ItemId === this.selectedDirectorySecond2
                            && r.statKato.id === this.selectedKato2
                        ));
                }

                for (const item1 of response) {
                    // eslint-disable-next-line no-await-in-loop
                    await axios.delete(`/api/input-forms/data/${item1.id}`);
                }
            }
            this.loading = false;

            await this.getCurrentData(dateOnMillisecond);
        } catch (error) {
            this.loading = false;
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }

    private async getCurrentData(dateOnMillisecond: number) {
        this.loading = true;
        const date = new Date(dateOnMillisecond).setHours(0);
        try {
            await Promise.all(this.indicatorTitles.map(async (indicItem) => {
                const item: any = indicItem;
                let response: any[] = [];
                if (this.selectedDirectoryFirst2 !== null && typeof this.selectedDirectoryFirst2 !== 'undefined'
                    && this.selectedDirectorySecond2 !== null && typeof this.selectedDirectorySecond2 !== 'undefined'
                    && this.selectedKato2 !== null && typeof this.selectedKato2 !== 'undefined') {
                    // eslint-disable-next-line no-await-in-loop
                    response = await axios.get(`/api/input-forms/data/${item.id}`)
                        .then(res => res.data)
                        .then((resp: any) => resp.filter((r: any) => new Date(r.date).setHours(0) === date
                            && r.dataPeriodicity.name_ru === this.selectedForm2.dataPeriodicity.name_ru
                            && r.dict1ItemId === this.selectedDirectoryFirst2
                            && r.dict2ItemId === this.selectedDirectorySecond2
                            && r.statKato.id === this.selectedKato2
                        ));
                } else if (this.selectedDirectoryFirst2 !== null && typeof this.selectedDirectoryFirst2 !== 'undefined'
                    && this.selectedKato2 !== null && typeof this.selectedKato2 !== 'undefined') {
                    // eslint-disable-next-line no-await-in-loop
                    response = await axios.get(`/api/input-forms/data/${item.id}`)
                        .then(res => res.data)
                        .then((resp: any) => resp.filter((r: any) => new Date(r.date).setHours(0) === date
                            && r.dataPeriodicity.name_ru === this.selectedForm2.dataPeriodicity.name_ru
                            && r.dict1ItemId === this.selectedDirectoryFirst2
                            && r.statKato.id === this.selectedKato2
                        ));
                } else if (this.selectedKato2 !== null && typeof this.selectedKato2 !== 'undefined') {
                    // eslint-disable-next-line no-await-in-loop
                    response = await axios.get(`/api/input-forms/data/${item.id}`)
                        .then(res => res.data)
                        .then((resp: any) => resp.filter((r: any) => new Date(r.date).setHours(0) === date
                            && r.dataPeriodicity.name_ru === this.selectedForm2.dataPeriodicity.name_ru
                            && r.statKato.id === this.selectedKato2
                        ));
                }
                if (response && response.length > 0) {
                    const res1: any = response.filter((r: any) => r.indicatorDict1ItemId === null && r.indicatorDict2ItemId === null);
                    const res2: any = response.filter((r: any) => r.indicatorDict1ItemId !== null && r.indicatorDict2ItemId === null);
                    const res3: any = response.filter((r: any) => r.indicatorDict1ItemId !== null && r.indicatorDict2ItemId !== null);

                    res1.forEach((i: any) => {
                        if (i.dataType.name_en === 'Fact') {
                            item.title.valueFact = i.value;
                        } else if (i.dataType.name_en === 'Forecast') {
                            item.title.valueForecast = i.value;
                        } else if (i.dataType.name_en === 'Plan') {
                            item.title.valuePlan = i.value;
                        }
                        item.title.valueId = i.id;
                    });

                    if (item.dictFirst && item.dictFirst.length > 0) {
                        item.dictFirst.forEach((dict1Item: any) => {
                            res2.forEach((i: any) => {
                                if (i.indicatorDict1ItemId === dict1Item.id) {
                                    if (i.dataType.name_en === 'Fact') {
                                        dict1Item.valueFact = i.value;
                                    } else if (i.dataType.name_en === 'Forecast') {
                                        dict1Item.valueForecast = i.value;
                                    } else if (i.dataType.name_en === 'Plan') {
                                        dict1Item.valuePlan = i.value;
                                    }
                                    dict1Item.valueId = i.id;
                                }
                            });

                            if (dict1Item.dict2Child && dict1Item.dict2Child.length > 0) {
                                dict1Item.dict2Child.forEach((dict2Item: any) => {
                                    res3.forEach((i: any) => {
                                        if (i.indicatorDict2ItemId === dict2Item.id && i.indicatorDict1ItemId === dict1Item.id) {
                                            if (i.dataType.name_en === 'Fact') {
                                                dict2Item.valueFact = i.value;
                                            } else if (i.dataType.name_en === 'Forecast') {
                                                dict2Item.valueForecast = i.value;
                                            } else if (i.dataType.name_en === 'Plan') {
                                                dict2Item.valuePlan = i.value;
                                            }
                                            dict2Item.valueId = i.id;
                                        }
                                    });
                                });
                            }
                        });
                    }
                } else {
                    const values = ['valueForecast', 'valueFact', 'valuePlan'];
                    values.forEach((v: any) => {
                        if (item.title.hasOwnProperty(v)) {
                            item.title[v] = 0;
                            if (item.dictFirst && item.dictFirst.length > 0) {
                                item.dictFirst.forEach((dict1: any) => {
                                    if (dict1.hasOwnProperty(v)) {
                                        dict1[v] = 0;
                                        if (dict1.dict2Child && dict1.dict2Child.length > 0) {
                                            dict1.dict2Child.forEach((dict2: any) => {
                                                if (dict2.hasOwnProperty(v)) {
                                                    dict2[v] = 0;
                                                }
                                            });
                                        }
                                    }
                                });
                            }
                        }
                    });
                }
            }))
            this.loading = false;
        } catch (error) {
            this.loading = false;
            // eslint-disable-next-line no-console
            console.log(error);
        }
        const values = ['valueForecast', 'valueFact', 'valuePlan'];

        this.indicatorTitles.forEach((indicator: any) => {
            if (indicator.dictFirst.length > 0) {
                let pass1 = true;
                let count = 0;
                const code1 = indicator.dictFirst[0].layer;
                for (const dictFirst of indicator.dictFirst) {
                    if (dictFirst.parent === null) {
                        count += 1;
                    }
                    if (dictFirst.layer > code1 || dictFirst.layer < code1) {
                        pass1 = false;
                    }
                }
                if (!pass1 && count === 1) {
                    let end = false;
                    for (const dict1 of indicator.dictFirst) {
                        if (dict1.parent === null) {
                            // indicator.title.name_ru += ` (${dict1.name_ru})`;
                            indicator.title.childName_ru = ` (${dict1.name_ru})`;
                            indicator.title.child = true;
                            dict1.toParent = true;
                            values.forEach((valueType: any) => {
                                if (indicator.title.hasOwnProperty(valueType)) {
                                    indicator.title[valueType] = dict1[valueType];
                                }
                            });
                            end = true;
                            break;
                        }
                    }
                    if (!end) {
                        indicator.title.available = false;
                    }
                } else {
                    indicator.title.available = false;
                }
                indicator.dictFirst.forEach((dict1: any) => {
                    let pass2 = true;
                    let countChild = 0;
                    if (dict1.dict2Child && dict1.dict2Child.length > 0) {
                        const code2 = dict1.dict2Child[0].layer;
                        dict1.dict2Child.forEach((dict2: any) => {
                            if (dict2.layer > code2 || dict2.layer < code2) {
                                pass2 = false;
                            }
                            if (dict2.parent === null) {
                                countChild += 1;
                            }
                        });
                        if (!pass2 && countChild === 1) {
                            for (const dict2 of dict1.dict2Child) {
                                if (dict2.parent === null) {
                                    dict1.childName_ru = ` (${dict2.name_ru})`;
                                    dict1.child = true;
                                    dict2.toParent = true;
                                    dict2.hide = true;
                                    values.forEach((valueType: any) => {
                                        if (dict1.hasOwnProperty(valueType)) {
                                            dict1[valueType] = dict2[valueType];
                                            if (dict1.toParent) {
                                                indicator.title[valueType] = dict1[valueType];
                                            }
                                        }
                                    });
                                    if (dict1.toParent) {
                                        indicator.title.childName_ru += ` (${dict2.name_ru})`;
                                        dict1.hide = true;
                                    }
                                    break;
                                }
                            }
                        } else {
                            dict1.available = false;
                            if (dict1.toParent) {
                                indicator.title.available = false;
                            }
                        }
                    }
                    if (dict1.toParent && !dict1.child) {
                        dict1.hide = true;
                    }
                });
            }
        });
    }

    private formsOption: any[] = [];

    private katoList: any[] = [];

    private loading = true;

    private isCloseable = false;

    private open = true;

    private normalizer(node: any) {
        return {
            id: node.id,
            label: node.name_ru,
            children: node.children
        };
    }

    private openChilds(item: any) {
        item.isShow = !item.isShow;
    }

    private openAll() {
        this.open = !this.open;
        this.indicatorTitles.forEach((item: any) => {
            if (item.title.isShow !== null) {
                item.title.isShow = this.open;
            }
            item.dictFirst.forEach((dict1: any) => {
                if (dict1.hasOwnProperty('isShow')) {
                    dict1.isShow = this.open;
                }
            });
        });
        console.log(this.indicatorTitles)
    }
    private handleHide(bvEvent: any) {
        if (!this.isCloseable) {
            bvEvent.preventDefault();
        } else {
            const ref: any = this.$refs;
            ref.drop.hide();
        }
    }

    private closeMe() {
        this.isCloseable = true;
        const ref: any = this.$refs;
        ref.drop.hide();
    }
}