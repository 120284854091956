<template>
<b-container>
    <div class="section-title budget">Бюджетные инвестиционные проекты</div>
    <b-tabs>
        <b-tab title="Реализуемые">
            <bip-roads></bip-roads>
        </b-tab>
        <b-tab title="На рассмотрении">
            <bip-roads1></bip-roads1>
        </b-tab>
    </b-tabs>
</b-container>
</template>

<script>

import BipRoads from './BipRoads'
import BipRoads1 from './BipRoads1'

export default {
    name: 'Bip',
    components: {
        BipRoads, BipRoads1
    }
}
</script>

<style scoped>

</style>