import { Vue, Component, Prop } from 'vue-property-decorator';
import { TWidget } from '@/modules/widget-yard/store/types';
import CActual from './c-actual';
import draggable from 'vuedraggable';
export interface IWidgetData {
    checked: boolean | null;
    widget: TWidget;
}

@Component({
    components: {
        'c-widget': CActual,
        'draggable': draggable
    }
})
export default class CActualTab extends Vue {
    @Prop({
        required: true,
        default: []
    })
    private data!: IWidgetData[];
    @Prop({
        required: true,
        default: true
    })
    private active!: boolean;
    @Prop({
        required: true,
        default: 3
    })
    private colSum!: number | string;

    private get plashkaData(): IWidgetData[] {
        return this.data;
    }
}