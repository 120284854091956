import '@/modules/widget-yard/initialization';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import ChartGrouped from 'highcharts-grouped-categories';
import MultiColor from 'highcharts-multicolor-series';
import { accessor } from '@/modules/widget-yard/store';

HighchartsMore(Highcharts);
ChartGrouped(Highcharts);
MultiColor(Highcharts);

export const exportingStackColumn = {
    getIndicator(key: string) {
        const list = accessor.indicators;
        const indicator = list.find(x => x.newKey === key);
        return indicator;
    },
    result(itemes: any): any[] | null {
        const year = 0;
        const lastresult: any[] = [];
        if (itemes.indicatorConfigs.length === 0) {
            return null;
        }
        let len = 0;
        // eslint-disable-next-line consistent-return
        itemes.indicatorConfigs.forEach((config: any) => {
            const indicatorKey = config.indicator;
            let indicator = accessor.getIndicator(indicatorKey);
            if (indicator === undefined) {
                indicator = this.getIndicator(indicatorKey);
            }

            if (indicator === undefined) {
                return null;
            }
            if (indicator.items.length > len) {
                len = indicator.items.length;
            }
            let data: any = null;
            const items: any[] = [];
            let growIndicProc = null;
            let growIndic = null;
            let fact = 0;
            const periodicity = indicator.periodicity;
            const confUnit: any = config.unit;
            let unit: any;
            if ((Object.keys(confUnit).length === 0)) {
                unit = indicator.unit;
            } else if ((confUnit.name_ru === '')) {
                unit = indicator.unit;
            } else {
                if (confUnit.nationalSymbol === undefined || confUnit.nationalSymbol === null) {
                    unit = confUnit.name_ru;
                } else {
                    unit = confUnit.nationalSymbol;
                }
            }
            let unitLowerCased: string | null = null;
            if (unit !== null || unit !== undefined) {
                unitLowerCased = unit.toLowerCase();
                if (unitLowerCased === 'процент') {
                    unitLowerCased = '%';
                }
            }
            let datas = indicator.items.slice(-7 + year, indicator.items.length + year);

            if (indicator.periodicity === 'квартал' || indicator.periodicity === 'квартал с накоплением') {
                datas = indicator.items.slice(-8 + year, indicator.items.length + year);
            }

            if (indicator.periodicity === 'месяц' || indicator.periodicity === 'месяц с накоплением') {
                datas = indicator.items.slice(-12 + year, indicator.items.length + year);
            }
            const selectedItems = accessor.selectedMap.get(indicator.key);
            let measurement = 1;
            if (config.measurement !== undefined) {
                measurement = config.measurement;
            }
            if (selectedItems !== undefined) {
                datas.forEach((selectedItem) => {
                    const item: any = {
                        date: selectedItem.date,
                        value: Math.round((selectedItem.fact * measurement) * 10) / 10
                    };
                    items.push(item);
                });
                const current = selectedItems.item;
                if (current !== undefined) {
                    fact = current.fact;
                    const typeIndic = selectedItems.character;
                    const previous = selectedItems.previousItem;
                    if (previous !== undefined) {
                        growIndicProc = Math.round(((current.fact / previous.fact) * 100 - 100) * 100) / 100;
                        if (unitLowerCased === '%') {
                            growIndicProc = Math.round((current.fact - previous.fact) * 100) / 100;
                        }
                        if (typeIndic === 0) {
                            growIndic = typeIndic;
                        } else {
                            if (growIndicProc === 0) {
                                growIndic = typeIndic;
                            } else {
                                growIndic = typeIndic * growIndicProc;
                            }
                        }
                    }
                    data = {
                        name: config.title,
                        items,
                        unit: unitLowerCased,
                        growIndic,
                        growIndicProc,
                        fact,
                        periodicity
                    };
                }
            }
            const categ: any = itemes.dictCategoryEntity;
            let category = null;
            if (Object.keys(categ).length > 0) {
                category = categ.code;
            }
            lastresult.push({ title: config.title, data, indicatorKey, widgetTitle: itemes.title, category, id: itemes.id });
        });
        return lastresult;
    },

    setOptions(data: any) {
        if (data[0] === undefined) {
            return null;
        }
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const label: any[] = [];
        const widgetTitle = data[0].widgetTitle;
        let xLine: any = {};
        const periodicity = data[0].data?.periodicity;
        const id = data[0].id;
        const code = data[0].category;
        const test: Highcharts.SeriesOptionsType[] = [];
        // eslint-disable-next-line consistent-return
        data.forEach((elem: any, i: any) => {
            const datas = elem.data;
            if (datas === null) {
                return null;
            }
            const dat: any = [];
            const names: string | null = datas.name;

            const labels: object = {
                enabled: false
            };
            const title: object = {
                enabled: false
            };
            const stackLabels: object = {
                enabled: false
            };
            const maxPadding = 0.1;
            const visible = true;
            label.push({ stackLabels, labels, title, visible, maxPadding });
            if (datas.periodicity === 'год') {
                const categories: any = [];
                datas.items.forEach((item: any) => {
                    const itemDate = item.date;
                    const itemDateText = itemDate.getFullYear();
                    const count = Number(itemDateText);
                    categories.push(count);
                    dat.push([count, item.value]);
                });
                xLine = {
                    type: 'category',
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    }
                };
            }
            if (datas.periodicity === 'квартал' || datas.periodicity === 'квартал с накоплением') {
                const categories: any[] = [];
                const milliseconds: number[] = [];
                datas.items.forEach((item: any, index: any) => {
                    const itemDate = item.date;
                    const year = itemDate.getFullYear();
                    let month = itemDate.getMonth() + 1;
                    if (month <= 3) {
                        month = 'I';
                    } else if ((month > 3) && (month <= 6)) {
                        month = 'II';
                    } else if ((month > 6) && (month <= 9)) {
                        month = 'III';
                    } else if ((month > 9) && (month <= 12)) {
                        month = 'IV';
                    }
                    const d = month + '-' + (String(year)).substr(2);
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    dat.push(item.value);
                });
                xLine = {
                    type: 'category',
                    tickWidth: 0,
                    labels: {
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '14px',
                            fontWeight: 400
                        }
                    },
                    categories: categories
                };
            }
            if (datas.periodicity === 'неделя') {
                const categories: any = [];
                datas.items.forEach((item: any) => {
                    const year = item.date.getFullYear().toString().substr(-2);
                    let month = (item.date.getMonth() + 1).toString();
                    let day = item.date.getDate().toString();
                    if (month.length === 1) {
                        month = '0' + month;
                    }
                    if (day.length === 1) {
                        day = '0' + day;
                    }
                    const d = `${day}/${month}/${year}`;
                    categories.push(d);
                    dat.push(item.value);
                });
                xLine = {
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '10px',
                            fontWeight: 600
                        }
                    },
                    categories: categories,
                    tickInterval: 1
                };
            }
            test.push({ name: names, data: dat } as Highcharts.SeriesOptionsType);
        });

        const options = Highcharts.setOptions({
            colors,
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: 400,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: widgetTitle,
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px'
                },
                widthAdjust: -150
            },
            exporting: {
                enabled: false,
                menuItemDefinitions: {
                    // Custom definition
                    printChart: {
                        text: 'Печать'
                    },
                    downloadPNG: {
                        text: 'Скачать PNG'
                    },
                    downloadJPEG: {
                        text: 'Скачать JPEG'
                    },
                    downloadPDF: {
                        text: 'Скачать PDF'
                    },
                    downloadSVG: {
                        text: 'Скачать SVG'
                    }
                }
            },
            xAxis: xLine,
            yAxis: label,
            legend: {
                align: 'left',
                alignColumns: false,
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '500',
                    fontSize: '13px',
                    textOverflow: 'null'
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Всего: {point.stackTotal}'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    stacking: 'normal',
                    dataLabels: {
                        align: 'center',
                        enabled: true
                    }
                },
                series: {
                    dataLabels: {
                        // eslint-disable-next-line consistent-return
                        formatter() {
                            if (this.y !== null) {
                                return Highcharts.numberFormat(this.y, 0);
                            }
                        }
                    },
                    animation: false
                }
            },
            series: test
        });
        const element = document.createElement('div');
        element.className = `stack-column-${id}`;
        const chart = Highcharts.chart(element, options);
        const form = {
            'title': widgetTitle,
            'periodicity': periodicity,
            'url': chart.getSVG(),
            'code': code,
            'id': id
        };
        chart.destroy();
        return form;
    },
    setOptionsMonth(data: any) {
        if (data[0] === undefined) {
            return null;
        }
        const colors = ['#459ED8', '#953735', '#e46c0a', '#416084', '#449fd8', '#c0504d', '#f79646', '#5a80ad', '#195275', '#632523', '#984807', '#2c4058'];
        const label: any[] = [];
        const widgetTitle = data[0].widgetTitle;
        let xLine: any = {};
        const periodicity = data[0].data?.periodicity;
        const id = data[0].id;
        const code = data[0].category;
        const test: Highcharts.SeriesOptionsType[] = [];
        // eslint-disable-next-line consistent-return
        data.forEach((elem: any, i: any) => {
            const datas = elem.data;
            if (datas === null) {
                return null;
            }
            const dat: any = [];
            const names: string | null = datas.name;

            const labels: object = {
                enabled: false
            };
            const title: object = {
                enabled: false
            };
            const stackLabels: object = {
                enabled: false
            };
            const maxPadding = 0.1;
            const visible = true;
            label.push({ stackLabels, labels, title, visible, maxPadding });
            if (datas.periodicity === 'месяц' || datas.periodicity === 'месяц с накоплением') {
                const categories: any = [];
                const milliseconds: number[] = [];
                datas.items.forEach((item: any, index: any) => {
                    const itemDate = item.date;
                    const shortMonthNames = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];
                    const year = itemDate.getFullYear();
                    const month = itemDate.getMonth();
                    const d = shortMonthNames[month] + '/' + (String(year)).substr(2);
                    categories.push(d);
                    const milli = Date.parse(itemDate);
                    milliseconds.push(milli);
                    dat.push(item.value);
                });
                xLine = {
                    labels: {
                        useHTML: true,
                        autoRotation: [0],
                        style: {
                            color: '#3F4D67',
                            fontSize: '12px',
                            fontWeight: 600
                        },
                        // eslint-disable-next-line consistent-return
                        formatter() {
                            // eslint-disable-next-line @typescript-eslint/no-this-alias
                            const that: any = this;
                            if (that.pos % 2 !== 0) {
                                return that.value;
                            }
                        }
                    },
                    categories: categories,
                    tickInterval: 1
                };
            }
            test.push({ name: names, data: dat } as Highcharts.SeriesOptionsType);
        });

        const options = Highcharts.setOptions({
            colors,
            chart: {
                type: 'column',
                backgroundColor: 'transparent',
                spacing: [0, 0, 0, 0],
                height: 400,
                style: {
                    fontFamily: 'Manrope, sans-serif'
                }
            },
            title: {
                text: widgetTitle,
                style: {
                    fontWeight: 'bold',
                    fontSize: '15px'
                },
                widthAdjust: -150
            },
            exporting: {
                enabled: false,
                menuItemDefinitions: {
                    // Custom definition
                    printChart: {
                        text: 'Печать'
                    },
                    downloadPNG: {
                        text: 'Скачать PNG'
                    },
                    downloadJPEG: {
                        text: 'Скачать JPEG'
                    },
                    downloadPDF: {
                        text: 'Скачать PDF'
                    },
                    downloadSVG: {
                        text: 'Скачать SVG'
                    }
                }
            },
            xAxis: xLine,
            yAxis: label,
            legend: {
                align: 'left',
                alignColumns: false,
                verticalAlign: 'top',
                itemStyle: {
                    fontWeight: '500',
                    fontSize: '13px',
                    textOverflow: 'null'
                }
            },
            tooltip: {
                headerFormat: '<b>{point.x}</b><br/>',
                pointFormat: '{series.name}: {point.y}<br/>Всего: {point.stackTotal}'
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                column: {
                    borderWidth: 0,
                    stacking: 'normal',
                    dataLabels: {
                        align: 'center',
                        enabled: true
                    }
                },
                series: {
                    dataLabels: {
                        // eslint-disable-next-line consistent-return
                        formatter() {
                            if (this.y !== null) {
                                return Highcharts.numberFormat(this.y, 0);
                            }
                        }
                    },
                    animation: false
                }
            },
            series: test
        });
        const element = document.createElement('div');
        element.className = `stack-column-${id}`;
        const chart = Highcharts.chart(element, options);
        const form = {
            'title': widgetTitle,
            'periodicity': periodicity,
            'url': chart.getSVG(),
            'code': code,
            'id': id
        };
        chart.destroy();
        return form;
    }
};