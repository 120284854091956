<template>
    <b-container>
        <div class="section-title budget">Индивидуальный план финансирования</div>
        <b-card>
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm="3"
                content-cols-lg="3"
                label="План финансирования"
            >
                <b-form-select :options="options1" v-model="select1"></b-form-select>
            </b-form-group>
            <b-form-group
                label-cols-sm="4"
                label-cols-lg="3"
                content-cols-sm="3"
                content-cols-lg="3"
                label="На период"
            >
                <b-form-select :options="options" v-model="select"></b-form-select>
            </b-form-group>

        </b-card>
        <b-table :fields="tableFields" bordered no-border-collapse :items="tableData" :filter="select1" striped hover thead-class="bold_header" caption-top>
            <template #table-caption ><div class="text-right">млн. тенге</div></template>
            <template #cell(f6)="data">
                <div class="text-left">
                    <a v-if="data.item.f20" :href="data.item.f20">{{ data.value }}</a>
                    <a v-else>{{ data.value }}</a>
                </div>
            </template>
            <template #cell(f7)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
            <template #cell(f8)="data">
                <div class="text-right">
                    {{ data.value }}
                </div>
            </template>
        </b-table>
        <div class="text-right mt-3">
            <b-button class="m-1">Скачать в xls</b-button>
        </div>
    </b-container>
</template>

<script>
export default {
    name: 'BudgetCommission1',
    data() {
        return {
            options: [
                2022, 2023, 2024
            ],
            select: 2022,
            options1: [
                {
                    value: 'обязательства',
                    text: 'Обязательства'
                },
                {
                    value: 'платежи',
                    text: 'Платежи'
                }
            ],
            select1: 'обязательства',
            tableFields: [
                {
                    key: 'f2',
                    label: 'АБП'
                },
                {
                    key: 'f3',
                    label: 'БП'
                },
                {
                    key: 'f4',
                    label: 'ПП'
                },
                {
                    key: 'f5',
                    label: 'Специфика'
                },
                {
                    key: 'f6',
                    label: 'Наименование'
                },
                {
                    key: 'f7',
                    label: 'Фин. план на год'
                },
                {
                    key: 'f8',
                    label: 'Янв'
                },
                {
                    key: 'f9',
                    label: 'Фев'
                },
                {
                    key: 'f10',
                    label: 'Мар'
                },
                {
                    key: 'f11',
                    label: 'Апр'
                },
                {
                    key: 'f12',
                    label: 'Май'
                },
                {
                    key: 'f13',
                    label: 'Июн'
                },
                {
                    key: 'f14',
                    label: 'Июл'
                },
                {
                    key: 'f15',
                    label: 'Авг'
                },
                {
                    key: 'f16',
                    label: 'Сен'
                },
                {
                    key: 'f17',
                    label: 'Окт'
                },
                {
                    key: 'f18',
                    label: 'Ноя'
                },
                {
                    key: 'f19',
                    label: 'Дек'
                },
                {
                    key: 'f11',
                    label: 'Апр'
                }




            ],
            tableData: [
                {
                    'f1': 'обязательства',
                    'f2': 271,
                    'f3': null,
                    'f4': null,
                    'f5': null,
                    'f6': 'Управление строительства области',
                    'f7': 27806,
                    'f8': 3850,
                    'f9': 1011,
                    'f10': 14207,
                    'f11': 8738,
                    'f12': null,
                    'f13': null,
                    'f14': null,
                    'f15': null,
                    'f16': null,
                    'f17': null,
                    'f18': null,
                    'f19': null
                },
                {
                    'f1': 'обязательства',
                    'f2': null,
                    'f3': 86,
                    'f4': null,
                    'f5': null,
                    'f6': 'Строительство и реконструкция объектов начального, основного среднего и общего среднего образования',
                    'f7': 4862,
                    'f8': 3850,
                    'f9': 1011,
                    'f10': null,
                    'f11': null,
                    'f12': null,
                    'f13': null,
                    'f14': null,
                    'f15': null,
                    'f16': null,
                    'f17': null,
                    'f18': null,
                    'f19': null
                },
                {
                    'f1': 'обязательства',
                    'f2': null,
                    'f3': null,
                    'f4': 15,
                    'f5': null,
                    'f6': 'За счет средств местного бюджета',
                    'f7': 4302,
                    'f8': 3850,
                    'f9': 451,
                    'f10': null,
                    'f11': null,
                    'f12': null,
                    'f13': null,
                    'f14': null,
                    'f15': null,
                    'f16': null,
                    'f17': null,
                    'f18': null,
                    'f19': null
                },
                {
                    'f1': 'обязательства',
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 431,
                    'f6': 'Строительство новых объектов и реконструкция имеющихся объектов',
                    'f7': 4302,
                    'f8': 3850,
                    'f9': 451,
                    'f10': null,
                    'f11': null,
                    'f12': null,
                    'f13': null,
                    'f14': null,
                    'f15': null,
                    'f16': null,
                    'f17': null,
                    'f18': null,
                    'f19': null
                },
                {
                    'f1': 'обязательства',
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': null,
                    'f6': 'Проектирование жилого микрорайона "Достык" в г. Павлодар. Строительство СОШ на 1200 мест',
                    'f7': 2479,
                    'f8': 2479,
                    'f9': null,
                    'f10': null,
                    'f11': null,
                    'f12': null,
                    'f13': null,
                    'f14': null,
                    'f15': null,
                    'f16': null,
                    'f17': null,
                    'f18': null,
                    'f19': null
                },
                {
                    'f1': 'обязательства',
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': null,
                    'f6': 'Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар',
                    'f7': 1371,
                    'f8': 1371,
                    'f9': null,
                    'f10': null,
                    'f11': null,
                    'f12': null,
                    'f13': null,
                    'f14': null,
                    'f15': null,
                    'f16': null,
                    'f17': null,
                    'f18': null,
                    'f19': null,
                    'f20': '#/demo-budget-execution/execution/budget-gantt'
                },
                {
                    'f1': 'обязательства',
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': null,
                    'f6': 'Строительство пристройки на 348 мест в СОШГ №9 г. Павлодар',
                    'f7': 451,
                    'f8': null,
                    'f9': 451,
                    'f10': null,
                    'f11': null,
                    'f12': null,
                    'f13': null,
                    'f14': null,
                    'f15': null,
                    'f16': null,
                    'f17': null,
                    'f18': null,
                    'f19': null
                },
                {
                    'f1': 'платежи',
                    'f2': 271,
                    'f3': null,
                    'f4': null,
                    'f5': null,
                    'f6': 'Управление строительства области',
                    'f7': 27806,
                    'f8': 35,
                    'f9': 92,
                    'f10': 50,
                    'f11': 2440,
                    'f12': 986,
                    'f13': 3370,
                    'f14': 2447,
                    'f15': 1725,
                    'f16': 2807,
                    'f17': 2250,
                    'f18': 2918,
                    'f19': 8683
                },
                {
                    'f1': 'платежи',
                    'f2': null,
                    'f3': 86,
                    'f4': null,
                    'f5': null,
                    'f6': 'Строительство и реконструкция объектов начального, основного среднего и общего среднего образования',
                    'f7': 4862,
                    'f8': 0,
                    'f9': 0,
                    'f10': 18,
                    'f11': 790,
                    'f12': 86,
                    'f13': 364,
                    'f14': 615,
                    'f15': 314,
                    'f16': 164,
                    'f17': 465,
                    'f18': 413,
                    'f19': 1630
                },
                {
                    'f1': 'платежи',
                    'f2': null,
                    'f3': null,
                    'f4': 15,
                    'f5': null,
                    'f6': 'За счет средств местного бюджета',
                    'f7': 4302,
                    'f8': 0,
                    'f9': 0,
                    'f10': 15,
                    'f11': 700,
                    'f12': 76,
                    'f13': 322,
                    'f14': 544,
                    'f15': 280,
                    'f16': 144,
                    'f17': 415,
                    'f18': 365,
                    'f19': 1442
                },
                {
                    'f1': 'платежи',
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': 431,
                    'f6': 'Строительство новых объектов и реконструкция имеющихся объектов',
                    'f7': 4302,
                    'f8': 0,
                    'f9': 0,
                    'f10': 15,
                    'f11': 700,
                    'f12': 76,
                    'f13': 322,
                    'f14': 544,
                    'f15': 280,
                    'f16': 144,
                    'f17': 415,
                    'f18': 365,
                    'f19': 1442
                },
                {
                    'f1': 'платежи',
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': null,
                    'f6': 'Проектирование жилого микрорайона "Достык" в г. Павлодар. Строительство СОШ на 1200 мест',
                    'f7': 2479,
                    'f8': 0,
                    'f9': 0,
                    'f10': 9,
                    'f11': 403,
                    'f12': 45,
                    'f13': 186,
                    'f14': 313,
                    'f15': 160,
                    'f16': 83,
                    'f17': 240,
                    'f18': 210,
                    'f19': 831
                },
                {
                    'f1': 'платежи',
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': null,
                    'f6': 'Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар',
                    'f7': 1371,
                    'f8': 0,
                    'f9': 0,
                    'f10': 5,
                    'f11': 220,
                    'f12': 24,
                    'f13': 102,
                    'f14': 173,
                    'f15': 88,
                    'f16': 46,
                    'f17': 131,
                    'f18': 116,
                    'f19': 462,
                    'f20': '#/demo-budget-execution/execution/budget-gantt'
                },
                {
                    'f1': 'платежи',
                    'f2': null,
                    'f3': null,
                    'f4': null,
                    'f5': null,
                    'f6': 'Строительство пристройки на 348 мест в СОШГ №9 г. Павлодар',
                    'f7': 451,
                    'f8': 0,
                    'f9': 0,
                    'f10': 2,
                    'f11': 73,
                    'f12': 8,
                    'f13': 34,
                    'f14': 57,
                    'f15': 29,
                    'f16': 15,
                    'f17': 43,
                    'f18': 38,
                    'f19': 151
                }
            ]
        };
    }
};
</script>

<style scoped>

</style>