<template>
    <div class="inner-container snp">
        <div class="section-title sgp">
            <i class="icon icon-binocular-graphic"></i>
            <span>Стратегическое планирование</span>
        </div>
        <div class="section-subtitle snp"><i class="icon icon-grid"></i><span>Потребности СНП</span></div>

        <b-tabs>
            <b-tab title="Потребности">
        <div class="filter-container" style="position: initial; width: 100%">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close" @click="$refs.drop.hide(true)"></i>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Год">
                                <multiselect
                                    v-model="selectedPeriod"
                                    :options="periods"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :preselect-first="true"
                                    placeholder="Год"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    @input="getSelectedPeriod"
                                    label="year"
                                    track-by="year"
                                ></multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Район">
                                <multiselect
                                    v-model="selectedRegion"
                                    :options="katoRegion"
                                    :multiple="false"
                                    label="name_ru"
                                    track-by="name_ru"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :preselect-first="true"
                                    placeholder="Район"
                                    :show-labels="false"
                                    :allow-empty="false"
                                ></multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Сельский округ">
                                <multiselect
                                    :disabled="!selectedRegion.id"
                                    v-model="selectedDistrict"
                                    :options="katoDistrict"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :preselect-first="true"
                                    placeholder="Сельский округ"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    label="name_ru"
                                    track-by="name_ru"
                                ></multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Населенный пункт">
                                <multiselect
                                    v-model="selectedVillage"
                                    :options="katoVillage"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :preselect-first="true"
                                    placeholder="Выберите населенный пункт"
                                    label="village"
                                    track-by="village"
                                    :show-labels="false"
                                    :allow-empty="false"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Статус СНП">
                                <multiselect
                                    v-model="selectedStatus"
                                    :options="statuses"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :preselect-first="true"
                                    placeholder="Статус СНП"
                                    :show-labels="false"
                                    :allow-empty="false"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Потенциал СНП">
                                <multiselect
                                    v-model="selectedStatus2"
                                    :options="statuses2"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :preselect-first="true"
                                    placeholder="Потенциал СНП"
                                    :show-labels="false"
                                    :allow-empty="false"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Потребность">
                                <multiselect
                                    v-model="selectedNeed"
                                    :options="need"
                                    :multiple="false"
                                    :close-on-select="true"
                                    :clear-on-select="false"
                                    :preserve-search="true"
                                    :preselect-first="true"
                                    placeholder="Потребность"
                                    :show-labels="false"
                                    :allow-empty="false"
                                    @input="getFilteredStatus"
                                    label="text"
                                    track-by="text"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                </b-dropdown>
                <div class="table-legend ml-4">
                    <div class="grey">
                        <span></span> - не предусмотрено
                    </div>
                    <div class="red">
                        <span></span> - необходим новый объект
                    </div>
                    <div class="yellow">
                        <span></span> - необходим ремонт / реконструкция
                    </div>
                    <div class="green">
                        <span></span> - в пределах нормы
                    </div>
                </div>
            </div>
            <div class="right-content text-right">
                <div class="actions-tab">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                            <span class="left">
                                <i class="icon icon-info"></i>
                            </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="infoCostForm(13)">Режим «Потребности СНП» <i class="icon icon-folder"></i></span>
                            </div>
                            <!-- <div class="info-button">
                                <span>Видеоинструкция <i class="icon icon-video"></i></span>
                            </div> -->
                            <div class="info-text">
                                <p>Режим предназначен для выявления и мониторинга потребности сельских населенных пунктов в объектах социальной и инженерной инфраструктуры (далее – Потребность СНП).</p>
                                <p>Потребности определяются согласно методики, разработанной в рамках проекта «АК Akimat365». Определение потребности СНП производится на основании данных местных исполнительных органов из следующих документов:</p>
                                <p>1) Формы мониторинга социально-экономического развития СНП;</p>
                                <p>2) Система региональных стандартов;</p>
                                <p>3) Планы социально-экономического развития СНП.</p>
                                <p>Потребности СНП приведены в следующей классификации:</p>
                                <p><span class="grey bold">«-1»</span> - Объект не предусмотрен;</p>
                                <p><span class="green bold">«0»</span> - В пределах нормы;</p>
                                <p><span class="red bold">«1»</span> - Необходим новый объект;</p>
                                <p><span class="yellow bold">«2»</span> - Необходим ремонт/реконструкция.</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                            <span class="left">
                                <i class="icon icon-download"></i> Скачать
                            </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadFile()">Потребность сел</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>
                <div class="snp_table table-container">
                    <b-table
                        :fields="tableFields"
                        :items="filteredData"
                        :tbody-tr-class="rowClass"
                        :key="filteredData.length"
                        responsive="true"
                        bordered
                        head-variant="light"
                        sticky-header
                        no-border-collapse
                        striped
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                    >
                        <template #head(action)="">
                            <b-button @click="openAll()">
                                <i
                                    class="icon icon-keyboard icon-rotate-270"
                                    v-if="open"
                                ></i>
                                <i
                                    class="icon icon-keyboard icon-rotate-90"
                                    v-if="!open"
                                ></i>
                            </b-button>
                        </template>
                        <template #thead-top="">
                            <b-tr>
                                <b-th colspan="6"></b-th>
                                <b-th
                                    :colspan="formItems.length"
                                    class="text-left bg-blue-special"
                                >
                                    <b-form-select
                                        v-model="selectedDict"
                                        :options="dicts"
                                        @change="getIndicatorItems"
                                    >
                                        <template #first>
                                            <b-form-select-option :value="{}"
                                            >Все</b-form-select-option
                                            >
                                        </template>
                                    </b-form-select>
                                </b-th>
                            </b-tr>
                        </template>
                        <template slot="top-row">
                            <td class="text-left" colspan="5">
                                <strong v-if="code === '23'"
                                >Атырауская область</strong
                                >
                                <strong v-if="code === '47'"
                                >Мангистауская область</strong
                                >
                                <strong v-else-if="code === '11'"
                                >Акмолинская область
                                </strong>
                                <strong v-else-if="code === '55' || code === ''"
                                >Павлодарская область</strong
                                >
                            </td>
                            <td
                                v-for="item in aggregation"
                                :key="item.key"
                            >
                                <div v-if="item.key === 'demography'" style="text-align: center;">
                                    <span v-if="item.demography > 0">{{ $n(item.demography, 'number') }}</span>
                                </div>
                                <div
                                    v-else-if="item.key === '03.01' || item.key === '03.02' || item.key === '03.03'"
                                    class="d-flex justify-content-start align-items-center"
                                >
                                    <div class="label-square square-red">
                                        {{ item.red }}
                                    </div>
                                </div>
                                <div
                                    v-else-if="item.key === '03.04' || item.key === '03.05' || item.key === '03.06'"
                                    class="d-flex justify-content-start align-items-center"
                                >
                                    <div class="label-square square-yellow">
                                        {{ item.yellow }}
                                    </div>
                                </div>
                                <div v-else class="d-flex justify-content-start align-items-center">
                                    <div class="label-square square-red">{{ item.red }}</div>
                                    <div class="label-square square-yellow">{{ item.yellow }}</div>
                                </div>
                            </td>

                        </template>
                        <template #cell(action)="data">
                            <b-button
                                v-if="data.item.level === 1"
                                @click="openChilds(data.item)"
                            >
                                <i
                                    class="icon icon-keyboard icon-rotate-270"
                                    v-if="data.item.open"
                                ></i>
                                <i
                                    class="icon icon-keyboard icon-rotate-90"
                                    v-if="!data.item.open"
                                ></i>
                            </b-button>
                        </template>
                        <template #cell(status)="data">
                            <div
                                v-if="data.item.level === 3"
                            >
                                {{ data.item.status }} / {{ data.item.status2 }}
                            </div>
                        </template>
                        <template #cell(demography)="data">
                            <div v-if="data.item.demography > 0" style="text-align: center;">
                                {{ $n(data.item.demography, 'number') }}
                            </div>
                        </template>
                        <template v-slot:cell()="data">
                            <div
                                class="d-flex justify-content-start align-items-center"
                                v-html="data.value"
                            >
                                <div class="label-square square-red">{{ data.item.red }}</div>
                                <div class="label-square square-yellow">{{ data.item.yellow }}</div>

                            </div>
                        </template>
                        <template #cell(region)="data">
                            <div
                                v-if="data.item.level === 1"
                            >
                                {{ data.item.region }}
                            </div>
                        </template>
                    </b-table>
                </div>
            </b-tab>
            <b-tab v-if="code == '59'" title="Отчёты по СНП" lazy>
                <power-bi-iframe code="0006"></power-bi-iframe>
            </b-tab>
        </b-tabs>
        <loading
            :active="loading"
            is-full-screen
            spinner="bar-fade-scale"
            color="#6495ED"
        />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueElementLoading from 'vue-element-loading';
import store from '@/services/store';
import {Ax} from "../../utils";
import PowerBiIframe from "@/components/c-power-bi-iframe/Iframe.vue";

export default {
    name: 'snp',
    components: {
        'loading': VueElementLoading,
        'PowerBiIframe': PowerBiIframe
    },
    data() {
        return {
            sortBy: 'region',
            sortDesc: false,
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'region',
                    label: 'Район',
                    sortable: false
                },
                {
                    key: 'district',
                    label: 'Сельский округ',
                    sortable: false
                },
                {
                    key: 'village',
                    label: 'Населенный пункт',
                    sortable: false
                },
                {
                    key: 'status',
                    label: 'Статус СНП'
                },
                {
                    key: 'demography',
                    label: 'Численность населения'
                }
            ],
            demography: [],
            formData: [],
            form: {},
            formD: [],
            formRoad: [],
            kato: [],
            katoRegion: [],
            katoDistrict: [],
            katoVillage: [],
            selectedRegion: { name_ru: 'Все' },
            selectedDistrict: { name_ru: 'Все' },
            statuses: [],
            statuses2: [],
            selectedStatus: 'Все',
            selectedStatus2: 'Все',
            selectedVillage: { village: 'Все' },
            formItems: [],
            filteredData: [],
            selectedDict: {},
            aggregation: [],
            dicts: [],
            periods: [],
            selectedPeriod: null,
            loading: false,
            code: store.state._instanceCode,
            need: [
                {
                    text: 'Все'
                },
                {
                    text: 'Необходим новый объект',
                    value: 'red'
                },
                {
                    text: 'Необходим ремон/реконструкция',
                    value: 'yellow'
                },
                {
                    text: 'В пределах нормы',
                    value: 'green'
                }
            ],
            selectedNeed: { text: 'Все' },
            open: false
        };
    },
    methods: {
        infoCostForm(info_id) {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-info/' + info_id, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let file = '';
                    if (info_id == 13) {
                        file = 'Методика_потребности_СНП';
                    }
                    link.setAttribute('download', file + '.pdf');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        async getForm() {
            try {
                this.loading = true;
                const responseForms = await fetch('/api/input-forms?name=Потребности СНП');
                const forms = await responseForms.json();
                let snpId;
                for (const form of forms) {
                    if (form.name === 'Потребности СНП') {
                        snpId = form.id;
                    }
                }
                if (snpId) {
                    const response = await fetch('/api/input-forms/' + snpId);
                    const items = await response.json();
                    this.form = items;
                    if (this.form.indicators) {
                        this.form.indicators.sort((a, b) => a.id - b.id);
                    }
                }
                const lastRoad = await fetch('/api/input-forms/data/' + this.form.indicators[this.form.indicators.length - 1].id);
                const resp = await lastRoad.json();
                const periods = [];
                resp.forEach((data) => {
                    const date = new Date(data.date);
                    if (date.getTimezoneOffset() / 60 !== -6) {
                        const def = -6;
                        const diff = Math.abs(def - date.getTimezoneOffset() / 60);
                        date.setHours(date.getHours() + diff);
                    }
                    const year = date.getFullYear();
                    const period = periods.map(x => x.year).indexOf(year)
                    if (period === -1) {
                        periods.push({
                            year,
                            fact: data.date
                        })
                    }
                });
                this.periods = periods.sort((a, b) => b.year - a.year);
                this.selectedPeriod = this.periods[0];
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        },

        async getDemography() {
            try {
                const indic = await fetch('/api/dict/indicator');
                const res = await indic.json();
                let resId = null;
                for (const item of res) {
                    if (item.code === 'pop') {
                        resId = item.id;
                        break;
                    }
                }
                const response = await fetch(`/api/input-forms/link/snp_demography?indicatorIds=${resId}&${this.selectedPeriod.fact}`);
                const data = await response.json();
                const onlyTotal = data.filter(item => (item.indicatorDict1ItemId === 3 && item.indicatorDict2ItemId === 1) && item.date === this.selectedPeriod.fact);
                this.demography = onlyTotal;
                this.formData.forEach((item) => {
                    const demo = this.demography.find(d => d.statKato.code === item.code);
                    if (demo !== undefined) {
                        item.demography = demo.value;
                    }
                });
                const regions = this.formData.filter(item => item.level === 1);
                regions.forEach((r) => {
                    const districts = this.formData.filter(dist => dist.code.substr(0, 4) === r.code.substr(0, 4) && dist.level === 3 && dist.year === this.selectedPeriod.year);
                    let rDemography = 0;
                    districts.forEach((d) => {
                        rDemography += d.demography;
                    });
                    r.demography = rDemography;
                });
                this.getAggregation();
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        },

        async getFormData() {
            if (this.form.indicators) {
                try {
                    this.formRoad = [];
                    await Promise.all(this.form.indicators.map(async (indic, index) => {
                        if (index === 0) {
                            const response = await fetch('/api/input-forms/data/' + this.form.indicators[0].id + '?date=' + this.selectedPeriod.fact);
                            this.formD = await response.json();
                        } else {
                            // eslint-disable-next-line no-await-in-loop
                            const responseRoad = await fetch('/api/input-forms/data/' + indic.id + '?date=' + this.selectedPeriod.fact);
                            // eslint-disable-next-line no-await-in-loop
                            const roadJson = await responseRoad.json();
                            let name = '';
                            let code = '';
                            if (indic.indicator.code === 'Length_paved_roads_village') {
                                name = 'Внутри СНП, твердое покрытие, необходим новый объект, км';
                                code = '03.01';
                            } else if (indic.indicator.code === 'Length_solid_road_reg_center') {
                                name = 'До РЦ, твердое покрытие, необходим новый объект, км';
                                code = '03.02';
                            }  else if (indic.indicator.code === 'length_solid_road_RC_OC') {
                                name = 'РЦ - ОС - твердое покрытие, необходим новый объект, км';
                                code = '03.03'
                            } else if (indic.indicator.code === 'Length_roads_village') {
                                name = 'Внутри СНП, необходим ремонт/реконструкция, км';
                                code = '03.04';
                            } else if (indic.indicator.code === 'Length_road_reg_center') {
                                name = 'До РЦ, необходим ремонт/реконструкция, км';
                                code = '03.05';
                            } else if (indic.indicator.code === 'length_road_RC_OC') {
                                name = 'РЦ - ОС, необходим ремонт/реконструкция, км';
                                code = '03.06'
                            }
                            this.formRoad.push({ name, data: roadJson, code });
                        }
                    }))
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                }
            }
        },
        createFormItems() {
            this.formData = [];
            this.formRoad.forEach((road) => {
                if (road.data.length > 0) {
                    road.data.forEach((item) => {
                        item.unit = road.code;
                        this.formD.push(item);
                    });
                }
            });
            this.formD.sort((a, b) => a.id - b.id);
            const sortedData = [[this.formD[0]]];
            let j = 0;
            for (let i = 1; i < this.formD.length; i++) {
                if (sortedData[j][0].statKato.id === this.formD[i].statKato.id) {
                    sortedData[j].push(this.formD[i]);
                } else {
                    sortedData.push([this.formD[i]]);
                    j += 1;
                }
            }
            sortedData.forEach((datas) => {
                const treeKato = [];
                this.kato.forEach((itemes) => {
                    if (itemes.date_end === null && itemes.code.substr(4) === '00000' && itemes.code.substr(2) !== '0000000') {
                        treeKato.push(itemes);
                    }
                });
                let distr;
                if (datas[0].statKato) {
                    distr = this.kato.find(region => region.code.substr(2, 4) === datas[0].statKato.code.substr(2, 4) && region.code.substr(6) === '000');
                }
                if (distr === undefined) {
                    distr = { 'name_ru': ' ' };
                }
                let reg;
                if (distr.code) {
                    reg = treeKato.find(region => region.code.substr(0, 4) === distr.code.substr(0, 4) && region.code.substr(4) === '00000');
                }
                if (reg === undefined) {
                    reg = { 'name_ru': ' ' };
                }
                if (this.formData.find(dat => dat.region === reg.name_ru) === undefined) {
                    const region = {
                        region: reg.name_ru,
                        open: false,
                        level: 1,
                        _rowVariant: 'info',
                        code: reg.code
                    };
                    this.formData.push(region);
                }
                const currDate = new Date(datas[0].date).getFullYear();
                let demogr = 0;
                const demo = this.demography.find(d => d.statKato.id === datas[0].statKato.id && new Date(d.date).getFullYear() === currDate);
                if (demo !== undefined) {
                    demogr = {
                        value: demo.value,
                        year: new Date(demo.date).getFullYear()
                    };
                }
                const item = {
                    region: reg.name_ru,
                    district: distr.name_ru,
                    village: datas[0].statKato.id ? datas[0].statKato.name_ru : { 'name_ru': ' ' },
                    status: datas[0].dict1Item ? datas[0].dict1Item.name_ru : { 'name_ru': ' ' },
                    status2: datas[0].dict2Item ? datas[0].dict2Item.name_ru.charAt(0).toUpperCase() + datas[0].dict2Item.name_ru.slice(1) : { 'name_ru': ' ' },
                    open: false,
                    visible: false,
                    level: 3,
                    code: datas[0].statKato.id ? datas[0].statKato.code : { 'code': '0' },
                    demography: currDate === demogr.year ? demogr.value : 0
                };
                item._cellVariants = {};
                datas.forEach((child) => {
                    item.date = child.date;
                    item.year = new Date(child.date).getFullYear();
                    if (child.indicatorDict1Item.parent === null) {
                        item.mainValue = child.value;
                    } else {
                        item.value = child.value;
                    }
                    item.value = child.value;
                    item[`${child.indicatorDict1Item.code}`] = ' ';
                    if (child.indicatorDict2ItemId === null) {
                        if (child.value === 2) {
                            item._cellVariants[`${child.indicatorDict1Item.code}`] = 'yellow';
                        } else if (child.value === 1) {
                            item._cellVariants[`${child.indicatorDict1Item.code}`] = 'red';
                        } else if (child.value === 0) {
                            item._cellVariants[`${child.indicatorDict1Item.code}`] = 'green';
                        } else if (child.value === -1) {
                            item._cellVariants[`${child.indicatorDict1Item.code}`] = 'grey';
                        }
                    } else {
                        const code = child.indicatorDict1Item.code + '.' + child.indicatorDict2Item.code;
                        if (code.indexOf('03.') > -1) {
                            if (code === '03.01' || code === '03.02' || code === '03.03') {
                                if (child.value > 0) {
                                    item[`${child.unit}`] = `<div class="label-square square-red fact${child.value}"> ${Math.round(child.value * 10) / 10} </div>`;
                                } else if (child.value < 0) {
                                    item[`${child.unit}`] = `<div class="label-square square-grey"> </div>`;
                                } else {
                                    item[`${child.unit}`] = `<div class="label-square square-green"> ${Math.round(child.value * 10) / 10} </div>`;
                                }
                            }
                            if (code === '03.04' || code === '03.05' || code === '03.06') {
                                if (child.value * 10 > 0) {
                                    item[`${child.unit}`] = `<div class="label-square square-yellow fact${child.value}"> ${Math.round(child.value * 10) / 10} </div>`;
                                } else if (child.value < 0) {
                                    item[`${child.unit}`] = `<div class="label-square square-grey"> </div>`;
                                } else {
                                    item[`${child.unit}`] = `<div class="label-square square-green"> ${Math.round(child.value * 10) / 10} </div>`;
                                }
                            }
                        }
                    }
                });
                this.formData.push(item);
            });
            this.formData = this.filteredData = this.formData.filter(item => item.level === 1 || item.year === this.selectedPeriod.year);
            const regions = this.formData.filter(item => item.level === 1);
            regions.forEach((r) => {
                const districts = this.formData.filter(dist => dist.code.substr(0, 4) === r.code.substr(0, 4) && dist.level === 3 && dist.year === this.selectedPeriod.year);
                let rDemography = 0;
                districts.forEach((d) => {
                    rDemography += d.demography;
                });
                r.demography = rDemography;
            });
            this.katoVillage = this.formData.filter(item => item.level === 3 && item.year === this.selectedPeriod.year);
            this.katoVillage.unshift({ village: 'Все' });
            this.getAggregation();
            this.regionAggregation();
        },
        getIndicatorItems() {
            this.formItems = [];
            if (this.selectedDict.children) {
                this.selectedDict.children.forEach((child) => {
                    this.formItems.push({ value: child, text: child.name_ru, level: 2 });
                });
            } else {
                this.form.indicators[0].dict1ItemsDto.forEach((dict1) => {
                    const children = this.form.indicators[0].dict1ItemsDto.filter(child => child.parent === dict1.id);
                    if (dict1.code === '03') {
                        this.formRoad.sort((a, b) => a.code - b.code).forEach((dict2) => {
                            if (dict2.data.length > 0) {
                                const code = dict2.code
                                const name_ru = dict2.name;
                                children.push({ code, name_ru });
                            }
                        });
                        dict1.children = children;
                    }
                    if (children.length > 0) {
                        dict1.children = children;
                    }
                    if (dict1.parent === null) {
                        this.formItems.push({ value: dict1, text: dict1.name_ru, level: 1 });
                    }
                });
            }
            this.tableFields = [
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'region',
                    label: 'Район',
                    sortable: false,
                    class: 'medium'
                },
                {
                    key: 'district',
                    label: 'Сельский округ',
                    sortable: false,
                    class: 'medium'
                },
                {
                    key: 'village',
                    label: 'Населенный пункт',
                    sortable: false,
                    class: 'medium'
                },
                {
                    key: 'status',
                    label: 'Статус/Потенциал СНП',
                    class: 'medium'
                },
                {
                    key: 'demography',
                    label: 'Численность населения'
                }
            ];
            this.formItems.forEach((item) => {
                const label = {
                    'key': item.value.code,
                    'label': item.text
                };
                this.tableFields.push(label);
            });
            this.getFilteredStatus();
        },
        getAggregation() {
            this.aggregation = [];
            let demoCount = 0;
            this.formItems.forEach((item) => {
                let redCount = 0;
                let yellowCount = 0;
                this.filteredData.forEach((datas) => {
                    if (datas.level === 3) {
                        if (datas._cellVariants[item.value.code] === 'red') {
                            redCount += 1;
                        }
                        if (datas._cellVariants[item.value.code] === 'yellow') {
                            yellowCount += 1;
                        }
                        if (item.value.code === '03.01' || item.value.code === '03.02' || item.value.code === '03.03') {
                            const fact = datas[item.value.code].split('fact');
                            if (fact.length > 1) {
                                const f = fact[1].split('"');
                                redCount += parseFloat(f[0]);
                            }
                        }
                        if (item.value.code === '03.04' || item.value.code === '03.05' || item.value.code === '03.06') {
                            const fact = datas[item.value.code].split('fact');
                            if (fact.length > 1) {
                                const f = fact[1].split('"');
                                yellowCount += parseFloat(f[0]);
                            }
                        }
                    }
                });
                const list = {
                    key: item.value.code,
                    red: Math.round(redCount * 10) / 10,
                    yellow: Math.round(yellowCount * 10) / 10
                };
                this.aggregation.push(list);
            });
            const regions = this.formData.filter(item => item.level === 3 && item.year === this.selectedPeriod.year);
            regions.forEach((r) => {
                demoCount += r.demography;
            });
            const demo = {
                key: 'demography',
                demography: demoCount
            };
            this.aggregation.unshift(demo);
        },
        regionAggregation() {
            const regiones = this.formData.filter(item => item.level === 1);
            regiones.forEach((r) => {
                const districts = this.formData.filter(dist => dist.code.substr(0, 4) === r.code.substr(0, 4) && dist.level === 3 && dist.year === this.selectedPeriod.year);
                let rDemography = 0;
                districts.forEach((d) => {
                    rDemography += d.demography;
                });
                r.demography = rDemography;
            });
            const regions = this.filteredData.filter(region => region.level === 1);
            this.formItems.forEach((item) => {
                regions.forEach((reg) => {
                    let redCount = 0;
                    let yellowCount = 0;
                    const items = this.filteredData.filter(datas => datas.level === 3 && datas.region === reg.region && datas.year === this.selectedPeriod.year);
                    items.forEach((iteb) => {
                        if (iteb._cellVariants[item.value.code] === 'red') {
                            redCount += 1;
                        }
                        if (iteb._cellVariants[item.value.code] === 'yellow') {
                            yellowCount += 1;
                        }
                        if (item.value.code === '03.01' || item.value.code === '03.02' || item.value.code === '03.03') {
                            const fact = iteb[item.value.code].split('fact');
                            if (fact.length > 1) {
                                const f = fact[1].split('"');
                                redCount += parseFloat(f[0]);
                            }
                        }
                        if (item.value.code === '03.04' || item.value.code === '03.05' || item.value.code === '03.06') {
                            const fact = iteb[item.value.code].split('fact');
                            if (fact.length > 1) {
                                const f = fact[1].split('"');
                                yellowCount += parseFloat(f[0]);
                            }
                            // iteb[item.value.code].split(' ').forEach((int) => {
                            //     if (parseFloat(int) > 0) {
                            //         yellowCount += parseFloat(int);
                            //     }
                            // });
                        }
                    });
                    if (item.value.code === '03.01' || item.value.code === '03.02' || item.value.code === '03.03') {
                        reg[`${item.value.code}`] = `<div class="label-square square-red">${Math.round(redCount * 10) / 10}</div>`;
                    } else if (item.value.code === '03.04' || item.value.code === '03.05' || item.value.code === '03.06') {
                        reg[`${item.value.code}`] = `<div class="label-square square-yellow">${Math.round(yellowCount * 10) / 10}</div>`;
                    } else {
                        reg[`${item.value.code}`] = `<div style="display: flex;">
                                                    <div class="label-square square-red">${redCount}</div>
                                                    <div class="label-square square-yellow">${yellowCount}</div>
                                                </div>`;
                    }
                });
            });
        },
        getRegionList() {
            this.katoRegion = [];
            if (this.kato.length > 0) {
                const treeKato = [];
                this.kato.forEach((item) => {
                    if (item.date_end === null && item.code.substr(4) === '00000' && item.code.substr(2) !== '0000000') {
                        treeKato.push(item);
                    }
                });
                treeKato.forEach((tree) => {
                    const children = this.kato.filter(item => item.code.substr(2, 2) === tree.code.substr(2, 2) && item.code.substr(4, 2) !== '00' && item.code.substr(6) === '000');
                    if (children.length > 0) {
                        tree.children = children;
                    }
                    children.forEach((child) => {
                        const childr = this.kato.filter(item => item.code.substr(0, 6) === child.code.substr(0, 6) && item.code.substr(6) !== '000');
                        if (childr.length > 0) {
                            child.children = childr;
                        }
                    });
                    this.katoRegion.push(tree);
                });
                this.katoRegion.sort(function (a, b) {
                    if (a.name_ru < b.name_ru) { return -1; }
                    if (a.name_ru > b.name_ru) { return 1; }
                    return 0;
                });
                // eslint-disable-next-line @typescript-eslint/camelcase
                this.katoRegion.unshift({ name_ru: 'Все' });
            }
        },
        getDistrictList() {
            this.katoDistrict = [];
            this.katoVillage = [];
            if (this.selectedRegion.id) {
                if (this.selectedRegion.children) {
                    this.selectedRegion.children.forEach((child) => {
                        if (this.selectedStatus !== 'Все') {
                            if (this.selectedStatus2 !== 'Все') {
                                if (this.formData.find(item => item.district === child.name_ru && item.status === this.selectedStatus && item.status2 === this.selectedStatus2 && item.year === this.selectedPeriod.year)) {
                                    this.katoDistrict.push(child);
                                }
                            } else if (this.formData.find(item => item.district === child.name_ru && item.status === this.selectedStatus && item.year === this.selectedPeriod.year)) {
                                this.katoDistrict.push(child);
                            }
                        } else {
                            if (this.selectedStatus2 !== 'Все') {
                                if (this.formData.find(item => item.district === child.name_ru && item.status2 === this.selectedStatus2 && item.year === this.selectedPeriod.year)) {
                                    this.katoDistrict.push(child);
                                } else if (this.formData.find(item => item.district === child.name_ru && item.year === this.selectedPeriod.year)) {
                                    this.katoDistrict.push(child);
                                }
                            } else if (this.formData.find(item => item.district === child.name_ru && item.year === this.selectedPeriod.year)) {
                                this.katoDistrict.push(child);
                            }
                        }
                    });
                }
                this.katoVillage = this.formData.filter(item => item.region === this.selectedRegion.name_ru && item.level === 3 && item.year === this.selectedPeriod.year);
                this.katoVillage.sort(function (a, b) {
                    if (a.village < b.village) { return -1; }
                    if (a.village > b.village) { return 1; }
                    return 0;
                });
                this.katoVillage.unshift({ village: 'Все' });
            } else {
                this.katoVillage = this.formData.filter(item => item.level === 3 && item.year === this.selectedPeriod.year);
                this.katoVillage.sort(function (a, b) {
                    if (a.village < b.village) { return -1; }
                    if (a.village > b.village) { return 1; }
                    return 0;
                });
                this.katoVillage.unshift({ village: 'Все' });
            }
            this.selectedVillage = { village: 'Все' };
            this.katoDistrict.sort(function (a, b) {
                if (a.name_ru < b.name_ru) { return -1; }
                if (a.name_ru > b.name_ru) { return 1; }
                return 0;
            });
            this.katoDistrict.unshift({ name_ru: 'Все' });
            this.filterListByNeed();
            this.getStatusesList();
            this.regionAggregation();
        },
        getVillageList() {
            this.katoVillage = [];
            this.selectedVillage = { village: 'Все' };
            if (this.selectedDistrict.id) {
                this.katoVillage = this.formData.filter(item => item.district === this.selectedDistrict.name_ru && item.level === 3 && item.year === this.selectedPeriod.year);
                this.katoVillage.sort(function (a, b) {
                    if (a.village < b.village) { return -1; }
                    if (a.village > b.village) { return 1; }
                    return 0;
                });
                this.katoVillage.unshift({ village: 'Все' });
            } else if (this.selectedRegion.id) {
                this.katoVillage = this.formData.filter(item => item.region === this.selectedRegion.name_ru && item.level === 3 && item.year === this.selectedPeriod.year);
                this.katoVillage.sort(function (a, b) {
                    if (a.village < b.village) { return -1; }
                    if (a.village > b.village) { return 1; }
                    return 0;
                });
                this.katoVillage.unshift({ village: 'Все' });
            } else {
                this.katoVillage = this.formData.filter(item => item.level === 3 && item.year === this.selectedPeriod.year);
                this.katoVillage.sort(function (a, b) {
                    if (a.village < b.village) { return -1; }
                    if (a.village > b.village) { return 1; }
                    return 0;
                });
                this.katoVillage.unshift({ village: 'Все' });
            }
            this.getFilteredStatus();
            this.filterListByNeed();
            this.getStatusesList();
            this.getAggregation();
            this.regionAggregation();
        },
        getStatusesList() {
            const stats = [];
            const stats2 = [];
            let list;
            if (this.selectedVillage.level) {
                const filterVillage = this.formData.filter(item => item.village === this.selectedVillage.village && item.year === this.selectedPeriod.year);
                list = filterVillage;
            } else if (this.selectedDistrict.id) {
                const filterDistrict = this.formData.filter(item => (item.region === this.selectedRegion.name_ru && item.level === 1) || (item.district === this.selectedDistrict.name_ru && item.year === this.selectedPeriod.year));
                list = filterDistrict;
            } else if (this.selectedRegion.id) {
                const allVillages = this.formData.filter(item => (item.level === 1 && item.region === this.selectedRegion.name_ru) || (item.region === this.selectedRegion.name_ru && item.year === this.selectedPeriod.year));
                list = allVillages;
            } else {
                list = this.formData.filter(item => item.level === 1 || item.year === this.selectedPeriod.year);
            }
            list.forEach((data) => {
                if (data.status) {
                    const statuses = stats.indexOf(data.status);
                    if (statuses === -1) {
                        stats.push(data.status);
                    }
                }
                if (data.status2) {
                    const statuses = stats2.indexOf(data.status2);
                    if (statuses === -1) {
                        stats2.push(data.status2);
                    }
                }
            });
            this.statuses = stats.sort(function (a, b) {
                if (a < b) { return -1; }
                if (a > b) { return 1; }
                return 0;
            });
            this.statuses2 = stats2.sort(function (a, b) {
                if (a < b) { return -1; }
                if (a > b) { return 1; }
                return 0;
            });
            this.statuses.unshift('Все');
            this.statuses2.unshift('Все');
        },
        getDicts() {
            this.dicts = [];
            this.form.indicators[0].dict1ItemsDto.forEach((item) => {
                const children = this.form.indicators[0].dict1ItemsDto.filter(child => child.parent === item.id);
                if (children.length > 0) {
                    item.children = children;
                }
                if (item.parent === null && item.children) {
                    this.dicts.push({ value: item, text: item.name_ru });
                }
            });
        },
        getFilteredStatus() {
            if (this.selectedStatus !== 'Все') {
                if (this.selectedVillage.level) {
                    const filteredVillage = this.formData.filter(item => (item.region === this.selectedVillage.region && item.level === 1) || item.village === this.selectedVillage.village);
                    this.filteredData = filteredVillage;
                } else if (this.selectedDistrict.id) {
                    let filterStatus = this.formData.filter(item => (item.region === this.selectedRegion.name_ru && item.level === 1) || (item.status === this.selectedStatus && item.district === this.selectedDistrict.name_ru && item.year === this.selectedPeriod.year));
                    if (this.selectedStatus2 !== 'Все') {
                        filterStatus = filterStatus.filter(item => (item.region === this.selectedRegion.name_ru && item.level === 1) || (item.status2 === this.selectedStatus2 && item.district === this.selectedDistrict.name_ru));
                    }
                    this.filteredData = filterStatus;
                } else if (this.selectedRegion.id) {
                    let filterStatus = this.formData.filter(item => (item.region === this.selectedRegion.name_ru && item.level === 1) || (item.status === this.selectedStatus && item.region === this.selectedRegion.name_ru && item.year === this.selectedPeriod.year));
                    if (this.selectedStatus2 !== 'Все') {
                        filterStatus = filterStatus.filter(item => (item.region === this.selectedRegion.name_ru && item.level === 1) || (item.status2 === this.selectedStatus2 && item.region === this.selectedRegion.name_ru));
                    }
                    this.filteredData = filterStatus;
                } else {
                    let filterStatus = this.formData.filter(item => item.level === 1 || (item.status === this.selectedStatus && item.year === this.selectedPeriod.year));
                    if (this.selectedStatus2 !== 'Все') {
                        filterStatus = filterStatus.filter(item => item.level === 1 || item.status2 === this.selectedStatus2);
                    }
                    this.filteredData = filterStatus;
                }
            } else {
                if (this.selectedVillage.level) {
                    const filteredVillage = this.formData.filter(item => (item.region === this.selectedVillage.region && item.level === 1) || item.village === this.selectedVillage.village);
                    this.filteredData = filteredVillage;
                } else if (this.selectedDistrict.id) {
                    let filterStatus = this.formData.filter(item => (item.region === this.selectedRegion.name_ru && item.level === 1) || (item.district === this.selectedDistrict.name_ru && item.year === this.selectedPeriod.year));
                    if (this.selectedStatus2 !== 'Все') {
                        filterStatus = filterStatus.filter(item => (item.region === this.selectedRegion.name_ru && item.level === 1) || (item.district === this.selectedDistrict.name_ru && item.status2 === this.selectedStatus2));
                    }
                    this.filteredData = filterStatus;
                } else if (this.selectedRegion.id) {
                    let filterStatus = this.formData.filter(item => (item.region === this.selectedRegion.name_ru && item.level === 1) || (item.region === this.selectedRegion.name_ru && item.year === this.selectedPeriod.year));
                    if (this.selectedStatus2 !== 'Все') {
                        filterStatus = filterStatus.filter(item => (item.region === this.selectedRegion.name_ru && item.level === 1) || (item.region === this.selectedRegion.name_ru && item.status2 === this.selectedStatus2));
                    }
                    this.filteredData = filterStatus;
                } else {
                    if (this.selectedStatus2 !== 'Все') {
                        this.filteredData = this.formData.filter(item => (item.level === 1) || (item.status2 === this.selectedStatus2 && item.year === this.selectedPeriod.year));
                    } else {
                        this.filteredData = this.formData.filter(item => item.level === 1 || item.year === this.selectedPeriod.year);
                    }
                }
            }
            this.filterListByNeed();
            this.getAggregation();
            this.regionAggregation();
            this.getStatusesList();
            this.katoDistrict = [];
            if (this.selectedRegion.id) {
                if (this.selectedRegion.children) {
                    this.selectedRegion.children.forEach((child) => {
                        if (this.selectedStatus !== 'Все') {
                            if (this.selectedStatus2 !== 'Все') {
                                if (this.formData.find(item => item.district === child.name_ru && item.status === this.selectedStatus && item.status2 === this.selectedStatus2 && item.year === this.selectedPeriod.year)) {
                                    this.katoDistrict.push(child);
                                }
                            } else if (this.formData.find(item => item.district === child.name_ru && item.status === this.selectedStatus && item.year === this.selectedPeriod.year)) {
                                this.katoDistrict.push(child);
                            }
                        } else {
                            if (this.selectedStatus2 !== 'Все') {
                                if (this.formData.find(item => item.district === child.name_ru && item.status2 === this.selectedStatus2 && item.year === this.selectedPeriod.year)) {
                                    this.katoDistrict.push(child);
                                }
                            } else if (this.formData.find(item => item.district === child.name_ru && item.year === this.selectedPeriod.year)) {
                                this.katoDistrict.push(child);
                            }
                        }
                    });
                }
            }
            this.katoDistrict.unshift({ name_ru: 'Все' });
        },
        getSelectedVillage() {
            if (this.selectedVillage === null) {
                this.selectedVillage = { village: 'Все' };
            }
            this.getFilteredStatus();
        },
        downloadFile() {
            let url = `Потребность_сел_Павлодарская_область_${this.selectedPeriod.year}.xlsx`;
            if (this.code === '23') {
                url = `Потребность_сел_Атырауская_область_${this.selectedPeriod.year}.xlsx`;
            }
            if (this.code === '47') {
                url = `Потребность_сел_Мангистауская_область_${this.selectedPeriod.year}.xlsx`;
            }
            if (this.code === '11') {
                url = 'Потребность_сел__Акмолинская_область.xlsx';
            }
            if (this.code === '59') {
                url = 'Потребность_сел_СКО.xlsx';
            }
            window.open('/api-py/snp-export/' + url, '_blank');
        },
        openAll() {
            this.open = !this.open;
            this.filteredData.forEach((data) => {
                data.open = this.open;
            });
        },
        openChilds(item) {
            item.open = !item.open;
            this.filteredData.forEach((data) => {
                if (data.region === item.region) {
                    data.open = item.open;
                }
            });
        },
        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.open && item.village) {
                // eslint-disable-next-line consistent-return
                return 'is-hidden';
            }
        },
        getSelectedPeriod() {
            this.getSelectedVillage();
        },

        filterListByNeed() {
            if (this.selectedNeed.value) {
                const needList = [];
                if (this.selectedDict.children) {
                    for (const need of this.filteredData) {
                        if (!need._cellVariants) {
                            needList.push(need);
                        }
                        if (this.selectedNeed.value === 'green' && need.level === 3) {
                            if (this.selectedDict.children[0].code === '03.02' || this.selectedDict.children[1].code === '03.03') {
                                if (need['03.02'].length > 1 && need['03.03'].length > 1) {
                                    let pass = false;
                                    let first = 0;
                                    let second = 0;
                                    need['03.02'].split(' ').forEach((int) => {
                                        if (parseFloat(int) > 0) {
                                            first = parseFloat(int);
                                        }
                                    });
                                    need['03.03'].split(' ').forEach((int) => {
                                        if (parseFloat(int) > 0) {
                                            second = parseFloat(int);
                                        }
                                    });
                                    if (first === 0 && second === 0) {
                                        pass = true;
                                    }
                                    if (pass) {
                                        needList.push(need);
                                    }
                                }
                            }
                        }
                        for (const child of this.selectedDict.children) {
                            if (this.selectedNeed.value === 'green' && (child.code !== '03.02' && child.code !== '03.03')) {
                                let pass = true;
                                for (const key of this.selectedDict.children) {
                                    if ((need._cellVariants && need._cellVariants[key.code] === 'red') || (need._cellVariants && need._cellVariants[key.code] === 'yellow')) {
                                        pass = false;
                                    }
                                }
                                if (need._cellVariants && pass) {
                                    needList.push(need);
                                    break;
                                }
                            } else {
                                if (need._cellVariants && need._cellVariants[child.code] === this.selectedNeed.value && (child.code !== '03.02' && child.code !== '03.03')) {
                                    needList.push(need);
                                    break;
                                }
                                if ((need._cellVariants && child.code === '03.02') || (need._cellVariants && child.code === '03.03')) {
                                    if (this.selectedNeed.value === 'red') {
                                        need['03.02'].split(' ').forEach((int) => {
                                            if (parseFloat(int) > 0) {
                                                needList.push(need);
                                            }
                                        });
                                    }
                                    if (this.selectedNeed.value === 'yellow') {
                                        need['03.03'].split(' ').forEach((int) => {
                                            if (parseFloat(int) > 0) {
                                                needList.push(need);
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    }
                } else {
                    this.filteredData.forEach((data) => {
                        if (!data._cellVariants) {
                            needList.push(data);
                        } else {
                            for (const child of this.formItems) {
                                if (this.selectedNeed.value === 'green') {
                                    let pass = true;
                                    for (const key of this.formItems) {
                                        if ((data._cellVariants && data._cellVariants[key.value.code] === 'red') || (data._cellVariants && data._cellVariants[key.value.code] === 'yellow')) {
                                            pass = false;
                                        }
                                    }
                                    if (data._cellVariants && pass) {
                                        needList.push(data);
                                        break;
                                    }
                                } else {
                                    if (data._cellVariants && data._cellVariants[child.value.code] === this.selectedNeed.value) {
                                        needList.push(data);
                                        break;
                                    }
                                }
                            }
                        }
                    });
                }
                this.filteredData = needList;
            }
        },
        async launch() {
            this.getDemography();
            this.loading = true;
            await this.getFormData();
            this.getIndicatorItems();
            this.kato = this.form.dictKatoItemsDto;
            this.createFormItems();
            this.getRegionList();
            this.getStatusesList();
            this.getDicts();
            this.loading = false;
        }
    },
    created() {
        this.getForm()
    },
    mounted() {
        this.$watch('selectedRegion', () => {
            this.selectedDistrict = { name_ru: 'Все' };
            this.getDistrictList();
        });
        this.$watch('selectedDistrict', this.getVillageList);
        this.$watch('selectedStatus', this.getFilteredStatus);
        this.$watch('selectedStatus2', this.getFilteredStatus);
        this.$watch('selectedDict', this.getIndicatorItems);
        this.$watch('selectedVillage', this.getSelectedVillage);
        this.$watch('selectedPeriod', this.launch)
    }
};
</script>